import React from 'react';
import { Box } from '@mui/material';
import ComingSoonComponent from '../../components/misc/comingSoon';
import ColumnLayout from '../../layouts/column';
import TitleComponent from '../../components/typography/title';

function FeaturesPage() {
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <TitleComponent text="features." />
        <ColumnLayout alignItems="flex-start" overflow="flex">
          <ComingSoonComponent
            subtext="sorz not much time to write this. just play around and find out ah."
          />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default FeaturesPage;
