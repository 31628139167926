import React from 'react';
import { Box, Typography } from '@mui/material';
import ColumnLayout from '../../layouts/column';
import SectionHeader from '../../components/typography/sectionHeader';

function CareersPage() {
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <SectionHeader text="careers." />
        <ColumnLayout alignItems="center" overflow="flex">
          <Typography>No openings at the moment</Typography>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default CareersPage;
