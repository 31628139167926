import { getGroupsListApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getGroupsListService = async (offset, limit, restaurantId) => {
  const res = await gateway.get(getGroupsListApiDetails.path, {
    params: {
      offset,
      limit,
      restaurantId,
    },
  });

  return res.data;
};

export default getGroupsListService;
