import {
  Alert,
  Button,
  Card, CardContent, Divider, TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { string } from 'prop-types';
import ColumnLayout from '../../layouts/column';
import RowLayout from '../../layouts/rows';
import TitleComponent from '../typography/title';
import FormErrorTextComponent from '../typography/errorText';
import { useAuth } from '../../middlewares/hooks/auth.hook';
import {
  CompleteProfilePath, ForgetPasswordPath, HomePath, RegisterPath,
} from '../../constants/routes.contants';

function LoginCardComponent({ referrer }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [authServerError, setAuthServerError] = useState({ status: false, message: '' });

  const { login } = useAuth();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const { success, userInfo, message } = await login(data.username, data.password);

    if (!success) {
      setAuthServerError({ ...authServerError, status: true, message });
      reset();
      return;
    }

    if (!userInfo?.completedProfile) {
      navigate(CompleteProfilePath);
      return;
    }

    if (referrer === '') {
      navigate(HomePath);
    } else {
      navigate(referrer);
    }
  };

  const onRegisterClick = (e) => {
    e.preventDefault();
    navigate(RegisterPath);
  };

  const onForgetPasswordClick = (e) => {
    e.preventDefault();
    navigate(ForgetPasswordPath);
  };

  const usernameField = register('username', { required: true });

  const passwordField = register('password', { required: true });

  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: '300px', maxWidth: '500px', borderColor: '#000000', display: 'block',
      }}
    >
      <CardContent style={{ padding: '10px' }}>
        <ColumnLayout overflow="flex">
          <TitleComponent text="welcome." />
          <Divider variant="middle" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ColumnLayout overflow="flex">
              <TextField
                fullWidth
                variant="outlined"
                placeholder="username"
                type="text"
                InputProps={{
                  sx: { height: '40px' },
                }}
                ref={usernameField.ref}
                name={usernameField.name}
                onChange={usernameField.onChange}
                onBlur={usernameField.onBlur}
                autoCapitalize="nope"
                autoComplete="nope"
              />
              {errors.username && <FormErrorTextComponent text="username this is required" />}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="password"
                type="password"
                InputProps={{
                  sx: { height: '40px' },
                  autoCapitalize: 'nope',
                  autoComplete: 'nope',
                }}
                ref={passwordField.ref}
                name={passwordField.name}
                onChange={passwordField.onChange}
                onBlur={passwordField.onBlur}
              />
              {errors.password && <FormErrorTextComponent text="password this is required" />}
              {authServerError.status && (
                <Alert variant="filled" severity="error">
                  {authServerError.message || 'Invalid username or password'}
                </Alert>
              )}
              <ColumnLayout overflow="flex">
                <RowLayout overflow="flex" justifyContent="space-between" alignItems="flex-start">
                  <Button size="small" onClick={onForgetPasswordClick}>forget password?</Button>
                </RowLayout>
                <RowLayout overflow="flex" justifyContent="space-between" alignItems="flex-end">
                  <Button size="small" onClick={onRegisterClick}>create account</Button>
                  <Button variant="contained" type="submit">sign in</Button>
                </RowLayout>
              </ColumnLayout>
            </ColumnLayout>
          </form>
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

LoginCardComponent.propTypes = {
  referrer: string,
};

LoginCardComponent.defaultProps = {
  referrer: '',
};

export default LoginCardComponent;
