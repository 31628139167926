import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  bool, number, oneOfType, string,
} from 'prop-types';
import { useNavigate } from 'react-router-dom';
import RowLayout from '../../layouts/rows';
import ColumnLayout from '../../layouts/column';
import IsUpcomingEventTagComponent from '../tags/isUpcomingEvent';
import { EventDetailsPathBuilder } from '../../constants/routes.contants';

// function convertUnixTimestampToLocalDateTime(timestamp) {
//   const dateTimeString = new Date(timestamp * 1000).toLocaleString();
//   return dateTimeString;
// }

function EventListItemComponent({
  id, name, date, isUpcoming, groupId,
}) {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(EventDetailsPathBuilder(id), { state: { groupId } });
  };
  return (
    <Box
      border="1px solid black"
      borderRadius="5px"
      p="10px"
    >
      <RowLayout justifyContent="space-between" overflow="flex">
        <ColumnLayout overflow="flex" alignItems="flex-start">
          <RowLayout>
            <Typography variant="h6">{name}</Typography>
            {isUpcoming && <IsUpcomingEventTagComponent />}
          </RowLayout>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="subtitle2" color="gray">{new Date(date * 1000).toLocaleDateString()}</Typography>
          </Box>
        </ColumnLayout>
        <IconButton onClick={onClick}>
          <ChevronRightIcon />
        </IconButton>
      </RowLayout>
    </Box>
  );
}

EventListItemComponent.propTypes = {
  id: oneOfType([string, number]).isRequired,
  name: string.isRequired,
  date: number.isRequired,
  isUpcoming: bool,
  groupId: oneOfType([number, string]).isRequired,
};

EventListItemComponent.defaultProps = {
  isUpcoming: false,
};

export default EventListItemComponent;
