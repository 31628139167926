import { getJoinGroupKeyDetailsApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getJoinGroupKeyDetailsService = async (key) => {
  const response = await gateway.post(getJoinGroupKeyDetailsApiDetails.path, {
    key,
  });

  const { data, error } = response.data;

  return { data, error };
};

export default getJoinGroupKeyDetailsService;
