import axios from 'axios';

const sendFileToR2 = async (link, file) => {
  const config = {
    headers: {
      'Content-Type': file.type,
    },
  };
  const response = await axios.put(link, file, config);

  return response;
};

export default sendFileToR2;
