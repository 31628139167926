import { registerApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const registerService = async (username, email, password, newsletter) => {
  const req = {
    username, email, password, newsletter,
  };

  const response = await gateway.post(registerApiDetails.path, req);

  const { data } = response.data;
  const { error } = response.data;

  return { data, error };
};

export default registerService;
