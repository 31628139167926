import { Stack } from '@mui/material';
import {
  node, number, oneOfType, string,
} from 'prop-types';
import React from 'react';

function ColumnLayout({
  children, justifyContent, alignItems, overflow, spacing,
}) {
  return (
    <Stack
      spacing={spacing}
      justifyContent={justifyContent}
      alignItems={alignItems}
      overflow={overflow}
    >
      {children}
    </Stack>
  );
}

ColumnLayout.propTypes = {
  children: node.isRequired,
  justifyContent: string,
  alignItems: string,
  overflow: string,
  spacing: oneOfType([
    string, number,
  ]),
};

ColumnLayout.defaultProps = {
  justifyContent: '',
  alignItems: '',
  overflow: 'scroll',
  spacing: '5px',
};

export default ColumnLayout;
