import { node } from 'prop-types';
import React, { createContext, useState } from 'react';

const snackbarContext = createContext({
  open: false,
  message: 'success',
  severity: 'success',
});

const snackbarHookInit = () => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: 'Saved',
    severity: 'success',
  });

  const notify = (message) => {
    setSnackbarState({
      ...snackbarState,
      message,
      open: true,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  return { notify, handleCloseSnackbar, snackbarState };
};

function SnackbarProvider({ children }) {
  const snackbar = snackbarHookInit();
  return <snackbarContext.Provider value={snackbar}>{children}</snackbarContext.Provider>;
}

SnackbarProvider.propTypes = {
  children: node.isRequired,
};

export default SnackbarProvider;

export function useCustomSnackbar() {
  return React.useContext(snackbarContext);
}
