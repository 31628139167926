import { Box, Skeleton } from '@mui/material';
import { string } from 'prop-types';
import React, { useState } from 'react';

function escapeString(s) {
  const lookup = {
    '&': 'N',
    '"': '',
    "'": '',
    '<': '',
    '>': '',
  };
  return s.replace(/[&"'<>]/g, (c) => lookup[c]);
}

function GoogleMapsComponent({ query }) {
  const [mapLoaded, setMapLoaded] = useState(false);
  return (
    <Box
      width="100%"
      height="300px"
      position="relative"
      borderRadius="5px"
    >
      {mapLoaded ? null : (
        <Skeleton
          sx={{
            position: 'absolute',
          }}
          variant="rounded"
          width="100%"
          height="300px"
        />
      )}
      <iframe
        title="googlemaps"
        style={{ border: 0, borderRadius: '5px' }}
        width="100%"
        height="100%"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBLf2_fQKzVmg2i8wIPdVcKaECQ3upzqg0&q=${escapeString(query)}`}
        onLoad={() => { setMapLoaded(true); }}
      />
    </Box>
  );
}

GoogleMapsComponent.propTypes = {
  query: string.isRequired,
};

export default GoogleMapsComponent;
