import { getPublicProfileApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getPublicProfileService = async (username) => {
  const queryUrl = getPublicProfileApiDetails.path + username;
  const response = await gateway.get(queryUrl);

  const { data, error } = response.data;

  return { data, error };
};

export default getPublicProfileService;
