import React, { useEffect, useState } from 'react';
import {
  createSearchParams, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import {
  Box, Button, Divider, IconButton, Modal, Skeleton, Stack, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  bool, func, number, oneOfType, shape, string,
} from 'prop-types';
import { EventCreatePath, ExplorePath, RestaurantIdParam } from '../../constants/routes.contants';
import TitleComponent from '../../components/typography/title';
import ColumnLayout from '../../layouts/column';
import TagComponent from '../../components/tags/tag';
import SectionHeader from '../../components/typography/sectionHeader';
import RowLayout from '../../layouts/rows';
import OpenCloseTagComponent from '../../components/tags/openCloseTag';
import OpeningHoursComponent from '../../components/openingHours/openingHours';
import getRestaurantDetailsService from '../../services/restaurants/details';
import recommendRestaurantService from '../../services/restaurants/recommend';
import RestaurantCardComponent from '../../components/restaurantCard/restaurantCard';
import { devlog } from '../../utils/console';
import ReviewCardComponent from '../../components/review/reviewCard';
import RestaurantSkeletonComponent from './restaurantSkeleton';
import getRestaurantReviewsService from '../../services/review/getRestaurantReviews';
import ShareLinkComponent from '../../components/shareLink/shareLink';
import CompletedButtonComponent from '../../components/completedButton/completedButton';
import GoogleMapsComponent from '../../components/googleMapsFrame/googleMaps';
import getGroupsListService from '../../services/groups/getGroupsList';
import SaveRestaurantNewComponent from '../../components/save/saveRestaurantNew';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  py: 1,
  px: 3,
  maxWidth: '500px',
};

function GroupModalListItem({ groupInfo, restaurantId }) {
  const navigate = useNavigate();
  const onClickSelect = () => {
    const p = createSearchParams({ r: restaurantId }).toString();
    devlog('route to create event page with query: ', p.toString());
    navigate(`${EventCreatePath}?${p}`, { state: { groupId: groupInfo.id } });
  };
  return (
    <Box display="flex" p="5px" flexDirection="row" columnGap="10px" alignItems="center">
      <Box display="flex" flexDirection="column" textAlign="left">
        <Typography fontWeight="bold">{groupInfo.name}</Typography>
        <Typography variant="caption" color="gray">
          num members:
          {groupInfo.numMembers}
        </Typography>
      </Box>
      <Box marginRight={0} marginLeft="auto">
        <Button variant="contained" size="small" onClick={onClickSelect}>jio?</Button>
      </Box>
    </Box>
  );
}

GroupModalListItem.propTypes = {
  groupInfo: shape({ id: number, name: string, numMembers: number }).isRequired,
  restaurantId: oneOfType([number, string]).isRequired,
};

function GroupListModal({ isOpen, handleClose, restaurantId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [groupInfos, setGroupInfos] = useState([]);
  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);

      const { data } = await getGroupsListService(0, 10);

      setGroupInfos(data?.groupInfos || []);

      setIsLoading(false);
    };

    helper();
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <Box sx={style}>
        <RowLayout justifyContent="space-between">
          <SectionHeader text="groups." />
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </RowLayout>
        <Box
          height="50"
          display="flex"
          justifyContent="center"
          textAlign="left"
          flexDirection="column"
        >
          <Typography>ask your friends</Typography>
        </Box>
        {isLoading ? <Typography>isloading</Typography> : (
          <Box
            minHeight="100px"
            maxHeight="300px"
            display="flex"
            justifyContent="center"
            textAlign="left"
            flexDirection="column"
            border="1px black solid"
            borderRadius="5px"
          >
            {groupInfos.map((val) => (
              <Box key={val.id}>
                <GroupModalListItem key={val.id} groupInfo={val} restaurantId={restaurantId} />
                <Divider />
              </Box>
            ))}
          </Box>
        )}
        <RowLayout justifyContent="space-between">
          <Button size="small" onClick={handleClose}>cancel</Button>
        </RowLayout>
      </Box>
    </Modal>
  );
}

GroupListModal.propTypes = {
  isOpen: bool,
  handleClose: func.isRequired,
  restaurantId: oneOfType([number, string]).isRequired,
};

GroupListModal.defaultProps = {
  isOpen: false,
};

function RestaurantPage() {
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('u');

  const [isLoading, setIsLoading] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState({});
  const [recommendInfo, setRecommendInfo] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [referredReview, setReferredReview] = useState();

  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
  const handleCloseGroupModal = () => {
    setIsOpenGroupModal(false);
  };
  const handleOpenGroupModal = () => {
    setIsOpenGroupModal(true);
  };
  // const reviews = [{
  //   username: 'helloworld',
  //   description: 'this is the description',
  //   rating: 5,
  // }, {
  //   username: 'helloworld234',
  //   description: 'this is the description',
  //   rating: 2,
  // }];

  const params = useParams();
  const restaurantId = params[RestaurantIdParam];
  const navigate = useNavigate();

  const [headerImageLoaded, setHeaderImageLoaded] = useState(false);

  const onClickSeeMore = (e) => {
    e.preventDefault();
    navigate(ExplorePath);
  };

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const { data } = await getRestaurantDetailsService(restaurantId);
      devlog(data);
      setRestaurantDetails(data.restaurantInfo);

      const recommendResponse = await recommendRestaurantService(5, { restaurantId, page: 'restaurant' });
      setRecommendInfo(recommendResponse.data.restaurantInfos);

      const restaurantReviews = await getRestaurantReviewsService(restaurantId, 0, 5, 0);
      const reviewData = restaurantReviews?.data;
      if (reviewData) {
        const { profileInfos } = reviewData;
        const formattedReview = reviewData.reviews.map((val) => ({
          id: val.id,
          username: profileInfos[val.userId]?.username,
          description: val.description,
          rating: val.rating,
          images: val.images,
        }));
        setReviews(formattedReview);
      }

      if (referrer) {
        const referredReviewRes = await getRestaurantReviewsService(restaurantId, referrer, 5, 0);
        const referredReviewData = referredReviewRes?.data;
        if (referredReviewData && referredReviewData.reviews.length !== 0) {
          const { profileInfos } = referredReviewData;
          const individualReview = referredReviewData.reviews[0];
          const formattedReview = {
            id: individualReview.id,
            username: profileInfos[individualReview.userId]?.username,
            description: individualReview.description,
            rating: individualReview.rating,
          };
          setReferredReview(formattedReview);
        }
      }

      setIsLoading(false);
    };

    helper();
  }, [restaurantId]);

  if (isLoading || Object.keys(restaurantDetails).length === 0) {
    return <RestaurantSkeletonComponent />;
  }

  // const text = `This is the best chicken rice place in the whole of Singapore.
  // You have to try it! Now this is getting longgggg. How long should it get?
  // Do we need a show more button?`;

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout alignItems="flex-start" overflow="flex">
          <Box width="100%" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
            <TitleComponent text={restaurantDetails.name} textAlign="left" />
          </Box>
          <RowLayout overflow="flex" justifyContent="flex-end" spacing="3px">
            <SaveRestaurantNewComponent
              isSaved={restaurantDetails.isSaved}
              restaurantId={restaurantDetails.id}
            />
            <CompletedButtonComponent
              restaurantId={restaurantDetails.id}
              hasPersonalReview={!!restaurantDetails?.personalReview}
            />
            <ShareLinkComponent url={window.location.href} type="restaurant" name={restaurantDetails.name} />
          </RowLayout>
          {
            restaurantDetails.headerImage?.length > 0
            && (
              <Box position="relative" width="100%" minHeight={{ xs: '230px', md: '350px' }}>
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '5px',
                    objectFit: 'cover',
                  }}
                  alt="restaurant image"
                  src={restaurantDetails.headerImage}
                  onLoad={() => setHeaderImageLoaded(true)}
                />

                {!headerImageLoaded && (
                  <Skeleton
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                    variant="rounded"
                    width="100%"
                    height="100%"
                  />
                )}

              </Box>
            )
          }
          <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
            <TagComponent label={restaurantDetails.country} type="secondary" />
            {restaurantDetails?.tags?.length
              && restaurantDetails.tags.map((val) => (
                <TagComponent
                  key={val.id}
                  label={val.name}
                />
              ))}
          </Stack>

          {referredReview
            && (
              <ReviewCardComponent
                name={referredReview.username}
                reviewText={referredReview.description}
                rating={referredReview.rating}
              />
            )}

          {restaurantDetails?.personalReview
            && (
              <ReviewCardComponent
                reviewText={restaurantDetails?.personalReview.description}
                rating={restaurantDetails?.personalReview.rating}
                isPersonal
              />
            )}

          <SectionHeader text="description." />

          <Typography textAlign="left">
            {restaurantDetails.description}
          </Typography>

          <RowLayout alignItems="center" justifyContent="space-between" overflow="flex">
            <SectionHeader text="opening hours." />
            <OpenCloseTagComponent isOpen={restaurantDetails.isOpen} />
          </RowLayout>
          <Box width="100%" display="flex" justifyContent="center">
            <OpeningHoursComponent hours={restaurantDetails.operatingHours} />
          </Box>

          <SectionHeader text="location." />

          <RowLayout overflow="flex">
            <Typography>
              Address:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}
              fontWeight="bold"
              textAlign="left"
            >
              {restaurantDetails.address}
            </Typography>
          </RowLayout>
          <GoogleMapsComponent query={`${restaurantDetails.name}${restaurantDetails.address}`} />

          <SectionHeader text="others." />

          <Typography>
            {`Recommended Group Size: ${restaurantDetails.groupMin} - ${restaurantDetails.groupMax}`}
          </Typography>

          <Typography>
            {`Price: ${'$'.repeat(restaurantDetails.price)}`}
          </Typography>

          <Button variant="contained" onClick={handleOpenGroupModal}>lonely?</Button>

          <SectionHeader text="deals." />

          <Box
            width="100%"
            minHeight="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            border={1}
            borderRadius="5px"
          >
            <Typography variant="h5">coming soon</Typography>
          </Box>

          <Typography>
            To update information, please email:
            johndoe@crayoneats.com
          </Typography>

          <SectionHeader text="reviews." />
          {reviews.length !== 0 ? reviews.map((val) => (
            <ReviewCardComponent
              key={val.id}
              name={val.username}
              reviewText={val.description}
              rating={val.rating}
              images={val.images}
            />
          )) : (
            <Box
              width="100%"
              minHeight="100px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              border={1}
              borderRadius="5px"
            >
              <Typography variant="h5">no reviews</Typography>
            </Box>
          )}

          <SectionHeader text="you may also like." />
          {recommendInfo.length === 0 ? [0, 1, 2].map((val) => (
            <Skeleton
              key={val}
              variant="rounded"
              width="100%"
              height="222px"
            />
          ))
            : recommendInfo.map((val) => (
              <RestaurantCardComponent
                key={val.id}
                restaurantName={val.name}
                restaurantId={val.id}
                restaurantAddress={val.address}
                isSaved={val.isSaved}
                isOpen={val.isOpen}
                tags={val.tags}
                country={val.country}
                images={val.images}
              />
            ))}

          <Box width="100%">
            <Button variant="contained" onClick={onClickSeeMore}>See More</Button>
          </Box>
        </ColumnLayout>
        {isOpenGroupModal && (
        <GroupListModal
          isOpen={isOpenGroupModal}
          handleClose={handleCloseGroupModal}
          restaurantId={restaurantId}
        />
        )}
      </Box>
    </Box>
  );
}

export default RestaurantPage;
