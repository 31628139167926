import { createTheme } from '@mui/material';

export const defaultTheme = createTheme({
  typography: {
    fontFamily: ['Montserrat', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
      ',',
    ),
    button: {
      textTransform: 'lowercase',
      fontWeight: 'bold',
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
    warning: {
      main: '#f44336',
    },
  },
});

export default defaultTheme;
