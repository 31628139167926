import { Box, Typography } from '@mui/material';
import React from 'react';

import ColumnLayout from '../../layouts/column';

// where food meets technology
// a list but better
// by foodie for foodie
// eat Eat EAT repeat
// typing animation

// transit between texts
function HeroComponent() {
  return (
    <Box
      width="100%"
      minHeight="200px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      borderRadius="5px"
    >
      <Box width="80%" py="30px">
        <ColumnLayout alignItems="center" overflow="flex" spacing="20px">
          <Typography variant="h3">redefining food discovery</Typography>
          <Typography variant="subtitle" color="gray">finding good food does not have to be hard. we are here to help</Typography>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default HeroComponent;
