import React, { useEffect, useState } from 'react';
import {
  createSearchParams, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import {
  Box, Button, Divider, Grid, IconButton, Modal, Skeleton, Tab, Tabs, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  arrayOf,
  func,
  node,
  number, oneOfType, shape, string,
} from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import ColumnLayout from '../../layouts/column';
import {
  EventCreatePath, ExplorePath, GroupIdParam, GroupListPath,
  JoinGroupConfirmationPathBuilder,
  RestaurantPathBuilder,
} from '../../constants/routes.contants';
import SectionHeader from '../../components/typography/sectionHeader';
import WorkerProfileCard from '../../components/workerProfileCard/workerProfileCard';
import RowLayout from '../../layouts/rows';
import EventListItemComponent from '../../components/events/eventListItem';
import VibesBarComponent from '../../components/groupDisplay/vibesBar';
import getGroupDetailService from '../../services/groups/getGroupDetail';
import generateJoinGroupKeyService from '../../services/groups/generateJoinGroupKey';
import { getFeBaseUrl } from '../../utils/env';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';
import leaveGroupService from '../../services/groups/leaveGroup';
import { GetUserId } from '../../utils/localStorage';
import getEventsListService from '../../services/events/getEventsList';
import getListInfoService from '../../services/lists/details';
import { devlog } from '../../utils/console';
import ComingSoonComponent from '../../components/misc/comingSoon';
import recommendRestaurantService from '../../services/restaurants/recommend';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  py: 1,
  px: 3,
  maxWidth: '500px',
};

function EventListComponent({
  eventsInfo, groupId, eventCount, onClickSeeMore,
}) {
  const navigate = useNavigate();
  const onClickCreateEvent = () => {
    navigate(EventCreatePath, { state: { groupId } });
  };

  if (!eventsInfo || eventsInfo?.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="80%" mb="20px">
          <Typography>what are you waiting for</Typography>
          <Typography>create your first event and meet your friends today!</Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onClickCreateEvent}
        >
          create
        </Button>
      </Box>
    );
  }

  return (
    <>
      {eventsInfo.map((val) => (
        <EventListItemComponent
          key={val.id}
          id={val.id}
          name={val.name}
          date={val.eventDate}
          isUpcoming={val.isUpcoming}
          groupId={groupId}
        />
      ))}
      {eventsInfo.length < eventCount && <Button variant="contained" onClick={onClickSeeMore}>show more</Button>}
    </>
  );
}

EventListComponent.propTypes = {
  eventsInfo: arrayOf(shape()).isRequired,
  groupId: oneOfType([string, number]).isRequired,
  eventCount: number.isRequired,
  onClickSeeMore: func.isRequired,
};

function CustomTabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: number.isRequired,
};

function TabSection({ groupId }) {
  const [searchParams] = useSearchParams();
  const tab = parseInt(searchParams.get('t'), 10);
  let defaultTab = 0;
  if (tab && tab < 3) {
    defaultTab = tab;
  }
  const [value, setValue] = useState(defaultTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', minHeight: '350px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="events" />
          <Tab label="list" />
          <Tab label="feed" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <EventsTabPanel
          groupId={groupId}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box
          border={1}
          borderRadius="5px"
          mb="10px"
          p="10px"
          textAlign="left"
        >
          collaborate with your friends to make a list of places you all are interested in
        </Box>
        <GroupListTabPanel groupId={groupId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ComingSoonComponent subtext="working on it" />
      </CustomTabPanel>
    </Box>
  );
}

TabSection.propTypes = {
  groupId: string.isRequired,
};

function GroupListTabPanel({
  groupId,
}) {
  const navigate = useNavigate();
  const [listItems, setListItems] = useState([]);
  const [restaurants, setRestaurants] = useState({});
  const [users, setUsers] = useState({});
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hotpick, setHotPick] = useState(null);
  const [prevIds, setPrevIds] = useState([]);

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const { data } = await getListInfoService(0, 0, 10, groupId);

      setListItems(data.listItemInfos);
      setRestaurants(data.restaurantInfos);
      setUsers(data.userInfos);
      setCount(data.count);
      setIsLoading(false);
    };

    helper();
  }, []);

  const onClickRecommend = async () => {
    // navigate(RecommendPath, { state: { list: 'interested' } });
    const params = { previousIds: prevIds.join(',') };
    const recommend = await recommendRestaurantService(1, { page: 'group', groupId }, params);
    const seen = recommend.data?.restaurantInfos?.map((val) => val.id);
    setPrevIds([...prevIds, ...seen]);
    setHotPick(recommend.data?.restaurantInfos[0]);
  };

  const onClickExplore = () => {
    navigate(ExplorePath);
  };

  const onClickLoadMore = async () => {
    const { data } = await getListInfoService(0, listItems.length, 10, groupId);

    setListItems([...listItems, ...data.listItemInfos]);
    setRestaurants({ ...restaurants, ...data.restaurantInfos });
  };

  const onClickSelect = (restaurantId) => () => {
    const p = createSearchParams({ r: restaurantId }).toString();
    devlog('route to create event page with query: ', p.toString());
    navigate(`${EventCreatePath}?${p}`, { state: { groupId } });
  };
  return (
    <>
      {hotpick && (
        <>
          <Typography textAlign="left" variant="h6" fontWeight="bold">go here?</Typography>
          <Box
            border="2px solid green"
            borderRadius="5px"
            p="10px"
            mb="10px"
          >
            <RowLayout overflow="flex" alignItems="center" justifyContent="space-between">
              <ColumnLayout overflow="flex" alignItems="flex-start">
                <RowLayout>
                  <Typography
                    textAlign="left"
                    variant="h6"
                    onClick={() => {
                      navigate(RestaurantPathBuilder(hotpick.id));
                    }}
                  >
                    {hotpick.name}
                  </Typography>
                </RowLayout>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="subtitle2" color="gray">
                    gogogogo
                  </Typography>
                </Box>
              </ColumnLayout>
              <Box>
                <Button variant="contained" onClick={onClickSelect(hotpick.id)}>create</Button>
              </Box>
            </RowLayout>
          </Box>
        </>
      )}
      <Box
        border={1}
        borderRadius="5px"
        display="flex"
        flexDirection="column"
        rowGap="10px"
        alignItems="center"
        p={1}
        overflow="scroll"
        maxHeight="330px"
      >
        {
          isLoading && [0, 1].map((val) => (
            <Skeleton
              key={`interestedSkel-${val}`}
              variant="rounded"
              width="100%"
              height="100px"
            />
          ))
        }
        {listItems.length !== 0 ? (
          <>
            <Box width="100%">
              <RowLayout justifyContent="flex-start">
                <Button variant="contained" onClick={onClickRecommend}>Recommend</Button>
              </RowLayout>
            </Box>
            <Box width="100%">
              <ColumnLayout overflow="flex" alignItems="center">
                {listItems.map((val) => {
                  const restId = val.restaurantId;
                  const restaurantInfo = restaurants[restId];
                  const username = users[val.userId]?.username;
                  return (
                    restaurantInfo && (
                    <Box
                      border="1px solid black"
                      borderRadius="5px"
                      p="10px"
                      width="90%"
                    >
                      <RowLayout overflow="flex" alignItems="center" justifyContent="space-between">
                        <ColumnLayout overflow="flex" alignItems="flex-start">
                          <RowLayout>
                            <Typography
                              textAlign="left"
                              variant="h6"
                              onClick={() => {
                                navigate(RestaurantPathBuilder(restaurantInfo.id));
                              }}
                            >
                              {restaurantInfo.name}
                            </Typography>
                          </RowLayout>
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="subtitle2" color="gray">
                              {`added by: ${username}`}
                            </Typography>
                          </Box>
                        </ColumnLayout>
                        <Box>
                          <Button variant="contained" onClick={onClickSelect(restaurantInfo.id)}>create</Button>
                        </Box>
                      </RowLayout>
                    </Box>
                    )
                  );
                })}
              </ColumnLayout>
            </Box>
          </>
        ) : (
          <Box height="200px" display="flex" textAlign="center" alignItems="center">
            <Box flexDirection="column" gap="5px" display="flex">
              <Typography variant="h5">get exploring!</Typography>
              <Button size="large" variant="contained" onClick={onClickExplore}>Explore</Button>
            </Box>
          </Box>
        )}

        {count > listItems.length && <Button variant="contained" onClick={onClickLoadMore}>load more</Button>}
      </Box>
    </>
  );
}

GroupListTabPanel.propTypes = {
  groupId: string.isRequired,
};

function EventsTabPanel({
  groupId,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [eventsInfo, setEventsInfo] = useState([]);
  const [eventCount, setEventCount] = useState(0);
  const [pageState, setPageState] = useState({
    offset: 0,
    limit: 3,
  });

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const eventListRes = await getEventsListService(pageState.offset, pageState.limit, groupId);

      setEventsInfo(eventListRes.data.eventInfos);
      setEventCount(eventListRes?.data?.count || 5);
      setPageState({
        ...pageState,
        offset: pageState.offset + pageState.limit,
      });
      setIsLoading(false);
    };

    helper();
  }, []);

  const onClickSeeMore = async () => {
    const eventListRes = await getEventsListService(pageState.offset, pageState.limit, groupId);

    setPageState({
      ...pageState,
      offset: pageState.offset + pageState.limit,
    });

    const { eventInfos } = eventListRes.data;

    setEventsInfo([...eventsInfo, ...eventInfos]);
    setEventCount(eventListRes?.data?.count || 5);
  };

  const onClickCreateEvent = () => {
    navigate(EventCreatePath, { state: { groupId } });
  };

  return (
    <Box width="100%">
      <ColumnLayout overflow="flex">
        <RowLayout overflow="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold" textAlign="left">events.</Typography>
          {eventsInfo.length !== 0 && <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={onClickCreateEvent}>create</Button>}
        </RowLayout>
        {isLoading ? (
          <>
            <Skeleton variant="rounded" width="100%" height="100px" />
            <Skeleton variant="rounded" width="100%" height="100px" />
          </>
        ) : (
          <EventListComponent
            eventsInfo={eventsInfo}
            groupId={groupId}
            eventCount={eventCount}
            onClickSeeMore={onClickSeeMore}
          />
        )}
      </ColumnLayout>
    </Box>
  );
}

EventsTabPanel.propTypes = {
  groupId: string.isRequired,
};

/*
Add members (if admin)
compatibility?
members
Create Event
Group name
Group Created
Past events
*/
function GroupDetailsPage() {
  const userID = GetUserId();
  const { notify } = useCustomSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const groupId = params[GroupIdParam];

  const [openLeaveConfirmation, setOpenLeaveConfirmation] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [groupInfo, setGroupInfo] = useState({});
  const [membersInfo, setMembersInfo] = useState([]);
  const [compatibilityInfo, setCompatibilityInfo] = useState({});
  const [shameMap, setShameMap] = useState({});

  const [isOpenJoinWindow, setIsOpenJoinWindow] = useState(false);
  const [joinLink, setJoinLink] = useState('');

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);

      const { data } = await getGroupDetailService(groupId);

      if (!data) {
        navigate(GroupListPath);
        return;
      }
      setGroupInfo(data?.groupInfo);
      setMembersInfo(data?.membersInfo);
      setCompatibilityInfo(data?.compatibility);

      const noProfileList = data.noProfile;
      if (noProfileList && noProfileList.length !== 0) {
        const noProfileMap = {};
        noProfileList.map((val) => {
          noProfileMap[val] = true;
          return val;
        });

        setShameMap(noProfileMap);
      }
      setIsLoading(false);
    };

    helper();
  }, []);

  // const eventsInfo = [{
  //   id: 1,
  //   name: 'meetup',
  //   date: 12345678,
  //   isUpcoming: true,
  // }, {
  //   id: 2,
  //   name: 'birthday',
  //   date: 123456789,
  //   isUpcoming: false,
  // }];
  // const eventsInfo = [];
  // const tagsInfo = [{ id: 1, name: 'japanese', value: 100 },
  //   { id: 2, name: 'thai', value: 80 }, { id: 3, name: 'chinese', value: 70 }];
  // const locationsInfo = [{ id: 1, name: 'west', value: 80 },
  //   { id: 2, name: 'central', value: 50 }, { id: 3, name: 'east', value: 20 }];
  // const groupInfo = {
  //   name: 'xdn',
  //   members: membersInfo,
  //   events: eventsInfo,
  //   tags: tagsInfo,
  //   locations: locationsInfo,
  // };

  const onClickBack = () => {
    navigate(GroupListPath);
  };

  const onClickLeaveGroup = async () => {
    // const gidNum = Number(groupId);
    // const isInvalid = Number.isNaN(gidNum);
    // if (isInvalid) {
    //   return;
    // }
    // const { data } = await leaveGroupService(gidNum);
    // if (data.success) {
    //   notify('successfully left. byeeee');
    // }
    // navigate(GroupListPath);
    setOpenLeaveConfirmation(true);
  };

  const onClickAddMember = async () => {
    const gidNum = Number(groupId);
    const isInvalid = Number.isNaN(gidNum);
    if (isInvalid) {
      return;
    }
    if (joinLink.length === 0) {
      const { data } = await generateJoinGroupKeyService(gidNum);
      const url = getFeBaseUrl() + JoinGroupConfirmationPathBuilder(data.key);
      setJoinLink(url);
    }

    setIsOpenJoinWindow(true);
  };

  const onClickCopy = () => {
    if (joinLink.length === 0) {
      notify('there is no join link generated');
      return;
    }
    navigator.clipboard.writeText(joinLink);
    notify('copied join link to clipboard');
  };

  const handleLeaveConfirmationClose = () => {
    setOpenLeaveConfirmation(false);
  };

  const handleConfirmLeaveGroup = async () => {
    const gidNum = Number(groupId);
    const isInvalid = Number.isNaN(gidNum);
    if (isInvalid) {
      return;
    }
    const { data } = await leaveGroupService(gidNum);
    if (data.success) {
      notify('successfully left. byeeee');
    }
    navigate(GroupListPath);
  };

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout alignItems="center" overflow="flex">
          <Box width="100%" position="relative" alignItems="center" justifyContent="center" display="flex">
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                textAlign: 'left',
              }}
            >
              <IconButton onClick={onClickBack}>
                <ChevronLeftIcon />
              </IconButton>
            </Box>
            {isLoading ? <Skeleton variant="text" sx={{ fontSize: '64px' }} width="70%" />
              : <SectionHeader text={groupInfo.name} />}
          </Box>
          <Box width="90%">
            <Typography variant="h5" fontWeight="bold" textAlign="left">vibes.</Typography>
            {isLoading ? <Skeleton variant="rounded" width="100%" height="100px" />
              : (
                <>
                  {shameMap[userID] && (
                  <Box
                    textAlign="left"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <ColumnLayout overflow="flex" alignItems="center">
                      <Typography color="red">
                        you are not part of the vibe check as you have not completed your profile.
                        don&apos;t be left out and complete it now!
                      </Typography>
                      <div>
                        <Button variant="contained">complete now</Button>
                      </div>
                    </ColumnLayout>
                  </Box>
                  )}
                  <VibesBarComponent
                    value={compatibilityInfo.overall}
                    tags={compatibilityInfo.tagsInfo}
                    locations={compatibilityInfo.locationTagsInfo}
                  />
                </>
              )}
          </Box>
          <TabSection groupId={groupId} />
          <Box width="90%">
            <RowLayout overflow="flex" justifyContent="space-between">
              <Typography variant="h5" fontWeight="bold" textAlign="left">members.</Typography>
              <Button onClick={onClickAddMember} variant="contained" size="small" startIcon={<AddIcon />}>add</Button>
            </RowLayout>
          </Box>
          {isOpenJoinWindow && (
          <Box
            width="90%"
            display="flex"
            border="1px solid black"
            borderRadius="5px"
            padding="10px"
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: '#EEEEEE' }}
            flexDirection="column"
            textAlign="left"
          >
            <Box width="100%">
              <Typography textAlign="left" fontWeight="bold" style={{ wordWrap: 'break-word' }}>join link:</Typography>
            </Box>
            <Divider variant="full-width" sx={{ bgcolor: '#000000', width: '100%' }} />
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ backgroundColor: '#EEEEEE' }}
              flexDirection="row"
            >
              <Box width="80%">
                <Typography textAlign="left" style={{ wordWrap: 'break-word' }}>{joinLink}</Typography>
              </Box>
              <IconButton onClick={onClickCopy}><ContentCopyIcon /></IconButton>
            </Box>
          </Box>
          )}
          {isLoading ? (
            <Box width="90%" display="flex" alignItems="center" justifyContent="space-between">
              <Skeleton variant="rounded" width="48%" height="200px" />
              <Skeleton variant="rounded" width="48%" height="200px" />
            </Box>
          ) : (
            <>
              <Box width="100%" display="flex" justifyContent="center">
                <Box width="90%">
                  <Grid
                    container
                    direction="row"
                    rowSpacing={1}
                    columnSpacing={1}
                    justifyContent="center"
                  >
                    {membersInfo.map((val) => {
                      const memberUserId = val.id;
                      const noProfile = shameMap[memberUserId];
                      return (
                        <Grid
                          key={val.username}
                          item
                          display="flex"
                          justifyContent="center"
                        >
                          <Box
                            border={noProfile ? '5px solid red'
                              : '5px solid #4caf50'}
                            borderRadius="10px"
                          >
                            <WorkerProfileCard
                              name={val.username}
                              imgSrc={val.image}
                              profileLink={`/profile/${val.username}`}
                              variant="mini"
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
              <Button
                variant="outlined"
                color="warning"
                onClick={onClickLeaveGroup}
              >
                leave group
              </Button>
            </>
          )}
          <Modal
            open={openLeaveConfirmation}
            onClose={handleLeaveConfirmationClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <RowLayout justifyContent="space-between">
                <SectionHeader text="leaving." />
                <IconButton onClick={handleLeaveConfirmationClose}>
                  <CloseIcon />
                </IconButton>
              </RowLayout>
              <Box height="100px" display="flex" justifyContent="center" textAlign="left" flexDirection="column" pl="10px">
                <Typography>sad to see you go</Typography>
                <Typography color="red">are you sure you want to leave?</Typography>
              </Box>
              <RowLayout justifyContent="space-between">
                <Button size="small" onClick={handleLeaveConfirmationClose}>cancel</Button>
                <Button size="small" onClick={handleConfirmLeaveGroup}>confirm</Button>
              </RowLayout>
            </Box>
          </Modal>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default GroupDetailsPage;
