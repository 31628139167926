import { createContributeLinkApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const createContributeLinkService = async (link) => {
  const response = await gateway.post(createContributeLinkApiDetails.path, {
    link,
  });

  const { data, error } = response.data;

  return { data, error };
};

export default createContributeLinkService;
