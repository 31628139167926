import {
  Avatar, Button, Card, CardContent, Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ColumnLayout from '../../layouts/column';
import { CareersPath } from '../../constants/routes.contants';

function WorkerJoinCard() {
  const navigate = useNavigate();

  return (
    <Card style={{
      maxWidth: '180px',
      backgroundColor: '#000000',
      borderColor: '#FFFFFF',
      borderRadius: '5px',
      height: '305px',
      overflow: 'hidden',
    }}
    >
      <CardContent>
        <ColumnLayout alignItems="center" overflow="flex">
          <Avatar alt="name" src="" sx={{ width: '100px', height: '100px' }} />
          <Typography
            href=""
            padding="5px 0px "
            variant="h5"
            component="a"
            fontWeight="bold"
            color="secondary"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textDecoration: 'none',
            }}
          >
            Add Your Name Here
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            align="center"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
            }}
          >
            &quot;we probably wont change the world&quot;
          </Typography>
          <Button variant="contained" size="medium" onClick={() => { navigate(CareersPath); }} color="secondary">join us</Button>
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

WorkerJoinCard.propTypes = {};

WorkerJoinCard.defaultProps = {};

export default WorkerJoinCard;
