import React from 'react';
import Star from '@mui/icons-material/Star';
import { number } from 'prop-types';
import RowLayout from '../../layouts/rows';
import { GenerateRandomKey } from '../../utils/randomKey';

function RatingStarBarComponent({ numStar }) {
  return (
    <RowLayout overflow="flex" spacing="3px">
      {[...Array(numStar)].map(() => (
        <Star
          key={`colored-star-${GenerateRandomKey()}`}
          style={{ color: '#EDBF41', fontSize: '25px' }}
        />
      ))}
      {[...Array(5 - numStar)].map(() => <Star key={`uncolored-star-${GenerateRandomKey()}`} style={{ opacity: 0.55, fontSize: '25px' }} />)}
    </RowLayout>
  );
}

RatingStarBarComponent.propTypes = {
  numStar: number.isRequired,
};

export default RatingStarBarComponent;
