import {
  Box, Button,
  FormControl,
  InputLabel,
  MenuItem, Select, Tab, Tabs, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { node, number, string } from 'prop-types';
import { useLocation } from 'react-router-dom';
import ColumnLayout from '../../layouts/column';
import recommendRestaurantService from '../../services/restaurants/recommend';
import RestaurantCardComponent from '../../components/restaurantCard/restaurantCard';
import SectionHeader from '../../components/typography/sectionHeader';
import NumberInputBasic from '../../components/forms/numberInput';
import loading from '../../assets/loading.gif';
import ComingSoonComponent from '../../components/misc/comingSoon';

// bfast, lunch, dinner or supper
// group size
// budget
// types

// track saving from here to
// determine if recommendation is good

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: number.isRequired,
};

const loadingText = [
  'let me cook', 'cooking in progress', 'good food comes to those who wait',
  'patience is important', 'slow and steady wins the...',
];

function RecommendPage() {
  const advancedToggle = false;
  const { state } = useLocation();

  const [recommended, setRecommended] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [mealType, setMealType] = useState('');
  const [groupSize, setGroupSize] = useState(1);
  const [budget, setBudget] = useState('0');
  const [prevIds, setPrevIds] = useState([]);

  // loading screen to delay the press of the button again and again
  const [isLoading, setIsLoading] = useState(false);
  const [loadText, setLoadText] = useState('');

  useEffect(() => {
    const random = Math.floor(Math.random() * loadingText.length);
    setLoadText(loadingText[random]);
    if (!state?.list && !state?.userId) {
      return;
    }

    const referrer = {
      page: 'recommend',
      list: state.list,
    };
    const helper = async () => {
      setIsLoading(true);
      const recommendation = await recommendRestaurantService(1, referrer);
      const seen = recommendation.data.restaurantInfos.map((val) => val.id);
      setPrevIds([...prevIds, ...seen]);
      setRecommended(recommendation.data.restaurantInfos);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    };

    helper();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeMealType = (event) => {
    setMealType(event.target.value);
  };

  const handleChangeBudget = (event) => {
    setBudget(event.target.value);
  };

  const handleChangeGroupSize = (event, newVal) => {
    setGroupSize(newVal);
  };

  const onClickRecommend = async () => {
    const random = Math.floor(Math.random() * loadingText.length);
    setLoadText(loadingText[random]);
    setIsLoading(true);
    const referrer = { page: 'recommend' };
    const params = { previousIds: prevIds.join(',') };
    if (state?.list) {
      referrer.list = state.list;
    }
    const recommendation = await recommendRestaurantService(1, referrer, params);
    const seen = recommendation.data.restaurantInfos.map((val) => val.id);
    setPrevIds([...prevIds, ...seen]);
    setRecommended(recommendation.data.restaurantInfos);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const onClickRecommendAdvanced = async () => {
    setIsLoading(true);
    const referrer = {
      page: 'recommend',
    };

    const params = {
      previousIds: '',
      mealType,
      groupSize,
      budget,
    };

    if (state?.list) {
      referrer.list = state.list;
    }
    const recommendation = await recommendRestaurantService(1, referrer, params);
    const seen = recommendation.data.restaurantInfos.map((val) => val.id);
    setPrevIds([...prevIds, ...seen]);
    setRecommended(recommendation.data.restaurantInfos);
    setIsLoading(false);
  };

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout overflow="flex" alignItems="center">
          <Box
            width="100%"
            minHeight="150px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            border={1}
            borderRadius="5px"
          >
            <ColumnLayout>
              <Typography variant="h5">Not sure what to eat? We got you</Typography>
            </ColumnLayout>
          </Box>
          <Box width="100%" display="flex" alignItems="left" flexDirection="column">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs">
                <Tab label="simple" />
                <Tab label="advanced" />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                rowGap="20px"
              >
                { !isLoading && recommended.length !== 0 && (
                <>
                  <SectionHeader text="try this." />
                  <ColumnLayout overflow="flex">
                    {recommended.map((val) => (
                      <RestaurantCardComponent
                        key={val.id}
                        restaurantId={val.id}
                        restaurantName={val.name}
                        isSaved={val.isSaved}
                        restaurantAddress={val.address}
                        isOpen={val.isOpen}
                        country={val.country}
                        tags={val.tags}
                        images={val.images}
                      />
                    ))}
                  </ColumnLayout>
                </>
                )}
                {isLoading
                  ? (
                    <LoadingSVGComponent loadText={loadText} />
                  )
                  : <Button variant="contained" onClick={onClickRecommend}>recommend me</Button>}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              {advancedToggle ? (
                <ColumnLayout overflow="flex" spacing="10px" justifyContent="center">
                  <InputLabel sx={{ textAlign: 'left' }}>group size</InputLabel>
                  <FormControl>
                    <NumberInputBasic
                      aria-label="input group size"
                      placeholder="Input group size"
                      value={groupSize}
                      onChange={handleChangeGroupSize}
                    />
                  </FormControl>
                  <InputLabel sx={{ textAlign: 'left' }}>meal type</InputLabel>
                  <FormControl>
                    <Select
                      labelId="meal-type"
                      id="meal-type"
                      value={mealType}
                      onChange={handleChangeMealType}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="breakfast">breakfast</MenuItem>
                      <MenuItem value="lunch">lunch</MenuItem>
                      <MenuItem value="dinner">dinner</MenuItem>
                    </Select>
                  </FormControl>
                  <InputLabel sx={{ textAlign: 'left' }}>budget</InputLabel>
                  <FormControl>
                    <Select
                      labelId="budget"
                      id="budget"
                      value={budget}
                      onChange={handleChangeBudget}
                    >
                      <MenuItem value="0">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="1">$</MenuItem>
                      <MenuItem value="2">$$</MenuItem>
                      <MenuItem value="3">$$$</MenuItem>
                      <MenuItem value="4">$$$$</MenuItem>
                    </Select>
                  </FormControl>
                  <Button variant="contained" onClick={onClickRecommendAdvanced}>recommend me</Button>
                </ColumnLayout>
              ) : <ComingSoonComponent />}
            </CustomTabPanel>
          </Box>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

function LoadingSVGComponent({ loadText }) {
  return (
    <>
      <Typography variant="h5">{loadText}</Typography>
      <img width="80%" src={loading} alt="loading..." />
    </>
  );
}

LoadingSVGComponent.propTypes = {
  loadText: string.isRequired,
};

export default RecommendPage;
