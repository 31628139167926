export const LoginPath = '/login';
export const RegisterPath = '/register';
export const ForgetPasswordPath = '/forget-password';
export const CompleteProfilePath = '/complete-profile';

export const SearchPath = '/search';
export const ExplorePath = '/explore';
export const ProfilePath = '/personal/details';
export const UpdateProfilePath = '/personal/update';
export const ListPath = '/list';
export const HomePath = '/';
export const AboutPath = '/about';
export const FaqPath = '/faq';

export const CareersPath = '/careers';
export const FeaturesPath = '/features';
export const RecommendPath = '/recommend';
export const NotiPath = '/noti';

export const GroupCreatePath = '/group/create';
export const GroupListPath = '/group/list';
export const GroupKeyParam = 'groupKey';
export const JoinGroupConfirmationPath = `/group/join/:${GroupKeyParam}`;
export const JoinGroupConfirmationPathBuilder = (groupKey) => {
  if (!groupKey) {
    return '/notfound';
  }
  return `/group/join/${groupKey}`;
};

export const GroupIdParam = 'groupId';
export const GroupDetailsPath = `/group/details/:${GroupIdParam}`;
export const GroupDetailsPathBuilder = (groupId) => {
  if (!groupId) {
    return '/notfound';
  }
  return `/group/details/${groupId}`;
};

export const EventCreatePath = '/event/create';
export const EventIdParam = 'eventId';
export const EventDetailsPath = `/event/details/:${EventIdParam}`;
export const EventDetailsPathBuilder = (eventId) => {
  if (!eventId) {
    return '/notfound';
  }
  return `/event/details/${eventId}`;
};

export const RestaurantIdParam = 'restaurantId';
export const RestaurantPagePath = `/restaurant/:${RestaurantIdParam}`;
export const RestaurantPathBuilder = (restaurantId) => {
  if (!restaurantId) {
    return '/notfound';
  }
  return `/restaurant/${restaurantId}`;
};

export const PublicProfileUsernameParam = 'username';
export const PublicProfilePagePath = `/profile/:${PublicProfileUsernameParam}`;
export const PublicProfilePathBuilder = (username) => {
  if (!username) {
    return '/notfound';
  }
  return `/profile/${username}`;
};

export const ContributePath = '/contribute';
