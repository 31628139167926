import React from 'react';
import {
  arrayOf, bool, shape, string,
} from 'prop-types';
import { Box, Button, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RestaurantCardComponent from '../../components/restaurantCard/restaurantCard';
import { ExplorePath } from '../../constants/routes.contants';
import EmptyRestaurantCardComponent from '../../components/restaurantCard/emptyRestaurantCard';

function RecentSection({ recent, isLoading }) {
  const navigate = useNavigate();

  const onClickSeeMore = (e) => {
    e.preventDefault();
    navigate(ExplorePath);
  };

  if (isLoading) {
    return [0, 1, 2, 3, 4].map((val) => <Skeleton key={val} variant="rounded" width="100%" height="222px" />);
  }

  return recent.length ? (
    <>
      {recent.map((val) => (
        <RestaurantCardComponent
          key={val.id}
          restaurantName={val.name}
          restaurantId={val.id}
          restaurantAddress={val.address}
          isSaved={val.isSaved}
          isOpen={val.isOpen}
          tags={val.tags}
          country={val.country}
          images={val.images}
        />
      ))}
      <Box width="100%">
        <Button variant="contained" onClick={onClickSeeMore}>See More</Button>
      </Box>
    </>
  ) : <EmptyRestaurantCardComponent />;
}

RecentSection.propTypes = {
  recent: arrayOf(shape({
    restaurantId: string,
    restaurantName: string,
    restaurantAddress: string,
    isSaved: bool,
    isOpen: bool,
    tags: arrayOf(string),
    country: string,
    images: arrayOf(string),
  })),
  isLoading: bool,
};

RecentSection.defaultProps = {
  recent: [],
  isLoading: false,
};

export default RecentSection;
