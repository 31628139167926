import { Box, Button, Typography } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';
import RowLayout from '../../layouts/rows';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';

function LinkToRequirementBlogButton({ reqLink }) {
  return (
    <Button variant="contained" href={reqLink}>
      details
    </Button>
  );
}

LinkToRequirementBlogButton.propTypes = {
  reqLink: string.isRequired,
};

function SupportButton({ featureName }) {
  const { notify } = useCustomSnackbar();
  const onClick = () => {
    // console.log('supporting: ', featureName);
    notify(`thank you for supporting ${featureName}. our team is working hard to get this feature to you.`);
  };
  return (
    <Button variant="contained" onClick={onClick}>
      support
    </Button>
  );
}

SupportButton.propTypes = {
  featureName: string.isRequired,
};

function ComingSoonComponent({ subtext, reqLink, featureName }) {
  return (
    <Box
      minHeight="100px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      border={1}
      borderRadius="5px"
      p="10px"
      minWidth="90%"
      rowGap="10px"
    >
      <Typography variant="h5">Coming Soon</Typography>
      {subtext.length > 0 && <Typography>{subtext}</Typography>}
      <RowLayout>
        {reqLink.length > 0 && <LinkToRequirementBlogButton reqLink={reqLink} />}
        {featureName.length > 0 && <SupportButton featureName={featureName} />}
      </RowLayout>
    </Box>
  );
}

ComingSoonComponent.propTypes = {
  subtext: string,
  reqLink: string,
  featureName: string,
};

ComingSoonComponent.defaultProps = {
  subtext: '',
  reqLink: '',
  featureName: '',
};

export default ComingSoonComponent;
