import { Typography } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';

function SectionHeader({ text, textAlign }) {
  return (
    <Typography
      padding="5px 0px"
      variant="h4"
      component="div"
      fontWeight="bold"
      sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
      }}
      textAlign={textAlign}
    >
      {text}
    </Typography>
  );
}

SectionHeader.propTypes = {
  text: string.isRequired,
  textAlign: string,
};

SectionHeader.defaultProps = {
  textAlign: '',
};

export default SectionHeader;
