import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import RowLayout from '../../layouts/rows';

function EmptyRestaurantCardComponent() {
  return (
    <Card
      variant="outlined"
      style={{
        width: '100%', height: '202px', minWidth: '353px', borderColor: '#000000',
      }}
    >
      <CardContent style={{ padding: '10px', height: '80%' }}>
        <RowLayout overflow="flex" justifyContent="center" alignItems="center">
          <Typography
            padding="5px 0px "
            variant="h5"
            component="div"
            fontWeight="bold"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
            textAlign="left"
          >
            nothing
          </Typography>
        </RowLayout>
      </CardContent>
    </Card>
  );
}

export default EmptyRestaurantCardComponent;
