import React from 'react';

import {
  Stack,
} from '@mui/material';
import {
  node, number, oneOfType, string,
} from 'prop-types';

function RowLayout({
  children, justifyContent, alignItems, overflow, spacing,
}) {
  return <Stack direction="row" height="100%" spacing={spacing} justifyContent={justifyContent} alignItems={alignItems} overflow={overflow}>{children}</Stack>;
}

RowLayout.propTypes = {
  children: node.isRequired,
  justifyContent: string,
  alignItems: string,
  overflow: string,
  spacing: oneOfType([
    string, number,
  ]),
};

RowLayout.defaultProps = {
  justifyContent: '',
  alignItems: '',
  overflow: 'scroll',
  spacing: '10px',
};

export default RowLayout;
