import React, { useState } from 'react';

import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  arrayOf, bool, func, number, string,
} from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import TagComponent from '../tags/tag';
import OpenCloseTagComponent from '../tags/openCloseTag';
import RowLayout from '../../layouts/rows';
import ColumnLayout from '../../layouts/column';
import { RestaurantPathBuilder } from '../../constants/routes.contants';
import SaveRestaurantComponent from '../save/saveRestaurant';
import ReviewFormComponent from '../review/reviewForm';
import SectionHeader from '../typography/sectionHeader';
import moveListService from '../../services/lists/moveList';
import { devlog } from '../../utils/console';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';
import ShareLinkComponent from '../shareLink/shareLink';
import { getFeBaseUrl } from '../../utils/env';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  py: 1,
  px: 3,
  maxWidth: '500px',
};

function InterestedCardComponent({
  id,
  restaurantId,
  restaurantName,
  isSaved,
  restaurantAddress,
  isOpen,
  country,
  tags,
  images,
  review,
  onDone,
}) {
  const navigate = useNavigate();

  const { notify } = useCustomSnackbar();

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const handleOpen = () => setOpenReviewModal(true);
  const handleClose = () => {
    setOpenReviewModal(false);
  };

  const moreInfo = () => {
    const restaurantPath = RestaurantPathBuilder(restaurantId);
    navigate(restaurantPath);
  };

  const handleComplete = async () => {
    handleClose();
    const moveRes = await moveListService(id);
    devlog(moveRes);
    notify('restaurant moved to done');
    onDone(id);
  };

  const onClickDone = () => {
    if (!review?.length) {
      handleOpen();
      return;
    }
    handleComplete();
  };

  return (
    <Card
      variant="outlined"
      style={{ width: '100%', minWidth: '343px', borderColor: '#000000' }}
    >
      <CardContent style={{ padding: '10px' }}>
        <ColumnLayout overflow="flex">
          <RowLayout
            justifyContent="space-between"
            alignItems="flex-start"
            overflow="flex"
          >
            <Typography
              padding="5px 0px "
              variant="h5"
              component="div"
              fontWeight="bold"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
              textAlign="left"
            >
              {restaurantName}
            </Typography>
            <SaveRestaurantComponent
              isSaved={isSaved}
              restaurantId={restaurantId}
            />
          </RowLayout>
          <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
            <TagComponent label={country} type="secondary" />
            {tags.length !== 0
              && tags.map((val) => <TagComponent key={val.id} label={val.name} />)}
          </Stack>
          <RowLayout overflow="flex">
            <Typography>
              Address:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}
              fontWeight="bold"
              textAlign="left"
            >
              {restaurantAddress}
            </Typography>
          </RowLayout>
          <RowLayout overflow="flex">
            <OpenCloseTagComponent isOpen={isOpen} />
          </RowLayout>
          <RowLayout overflow="auto">
            {images
              && images.map((item) => (
                <img
                  key={item.img}
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                  style={{ borderRadius: '5px' }}
                />
              ))}
          </RowLayout>
          <RowLayout>
            <Button variant="contained" onClick={moreInfo} size="small">
              More Info
            </Button>
            <ShareLinkComponent
              url={`${getFeBaseUrl()}${RestaurantPathBuilder(restaurantId)}`}
              type="restaurant"
              name={restaurantName}
              variant="text"
              size="small"
            />
            <Button
              variant="contained"
              href={`http://maps.google.com/?q=${restaurantName}`}
              target="_blank"
              size="small"
            >
              navigate
            </Button>
            <Button variant="contained" onClick={onClickDone} size="small">
              done
            </Button>
          </RowLayout>
          <Modal
            open={openReviewModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <RowLayout justifyContent="space-between">
                <SectionHeader text="review." />
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </RowLayout>
              <ReviewFormComponent
                submitReviewHandler={handleComplete}
                restaurantId={restaurantId}
              />
              <RowLayout justifyContent="space-between">
                <Button size="small" onClick={handleClose}>cancel</Button>
                <Button size="small" onClick={handleComplete}>skip</Button>
              </RowLayout>
            </Box>
          </Modal>
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

InterestedCardComponent.propTypes = {
  id: number.isRequired,
  restaurantId: number.isRequired,
  restaurantName: string.isRequired,
  isSaved: bool.isRequired,
  restaurantAddress: string.isRequired,
  isOpen: bool.isRequired,
  country: string.isRequired,
  tags: arrayOf(string),
  images: arrayOf(string),
  onDone: func.isRequired,
  review: string,
};

InterestedCardComponent.defaultProps = {
  tags: [],
  images: [],
  review: '',
};

export default InterestedCardComponent;
