import {
  Box, Button, Skeleton, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { number } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ColumnLayout from '../../layouts/column';
import RowLayout from '../../layouts/rows';
import InterestedCardComponent from '../../components/listCard/interestedCard';
import { ExplorePath, RecommendPath } from '../../constants/routes.contants';
import getListInfoService from '../../services/lists/details';

// const listName = 'interested';
// need to redo this. we need to incorporate the list item id into the card
const listOrdering = 0;

function InterestedSection({ tabValue }) {
  const navigate = useNavigate();
  const [listItems, setListItems] = useState([]);
  const [restaurants, setRestaurants] = useState({});
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const { data } = await getListInfoService(tabValue, listItems.length, 10);

      setListItems(data.listItemInfos);
      setRestaurants(data.restaurantInfos);
      setCount(data.count);
      setIsLoading(false);
    };

    helper();
  }, [tabValue]);

  const onClickRecommend = () => {
    navigate(RecommendPath, { state: { list: 'interested' } });
  };

  const onClickExplore = () => {
    navigate(ExplorePath);
  };

  const onDone = (listItemId) => {
    setListItems(listItems.filter((val) => val.id !== listItemId));
  };

  const onClickLoadMore = async () => {
    const { data } = await getListInfoService(listOrdering, listItems.length, 10);

    setListItems([...listItems, ...data.listItemInfos]);
    setRestaurants({ ...restaurants, ...data.restaurantInfos });
  };

  return (
    <Box border={1} borderRadius="5px" minHeight="400px" p={1}>
      <ColumnLayout overflow="flex" alignItems="center">
        {
          isLoading && [0, 1].map((val) => <Skeleton key={`interestedSkel-${val}`} variant="rounded" width="100%" height="222px" />)
        }
        {listItems.length !== 0 ? (
          <>
            <Box width="100%">
              <RowLayout justifyContent="flex-start">
                <Button variant="contained" onClick={onClickRecommend}>Recommend</Button>
              </RowLayout>
            </Box>
            {listItems.map((val) => {
              const restId = val.restaurantId;
              const restaurantInfo = restaurants[restId];
              return (
                restaurantInfo && (
                  <InterestedCardComponent
                    key={val.id}
                    id={val.id}
                    restaurantName={restaurantInfo.name}
                    restaurantId={restaurantInfo.id}
                    restaurantAddress={restaurantInfo.address}
                    isSaved={restaurantInfo.isSaved}
                    isOpen={restaurantInfo.isOpen}
                    tags={restaurantInfo.tags}
                    country={restaurantInfo.country}
                    images={restaurantInfo.images}
                    review={restaurantInfo.personalReview?.description}
                    onDone={onDone}
                  />
                )
              );
            })}
          </>
        ) : (
          <Box height="100%" display="flex" textAlign="center">
            <Box mt="100%" flexDirection="column" gap="5px" display="flex">
              <Typography variant="h5">get exploring!</Typography>
              <Button size="large" variant="contained" onClick={onClickExplore}>Explore</Button>
            </Box>
          </Box>
        )}

        {count > listItems.length && <Button variant="contained" onClick={onClickLoadMore}>load more</Button>}
      </ColumnLayout>
    </Box>
  );
}

InterestedSection.propTypes = {
  tabValue: number.isRequired,
};

export default InterestedSection;
