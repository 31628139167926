import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import LoginCardComponent from '../../components/loginCard/loginCard';
import ColumnLayout from '../../layouts/column';
import { useAuth } from '../../middlewares/hooks/auth.hook';
import { HomePath } from '../../constants/routes.contants';

function LoginPage() {
  const { authed } = useAuth();
  const { state } = useLocation();

  if (authed) {
    return <Navigate to={HomePath} />;
  }
  return (
    <ColumnLayout overflow="flex" alignItems="center">
      <LoginCardComponent referrer={state?.path ? state.path : '/'} />
    </ColumnLayout>
  );
}

export default LoginPage;
