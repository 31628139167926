import React, { useEffect, useState } from 'react';
import {
  Box, Button, CircularProgress, Tab, Tabs,
} from '@mui/material';
import {
  node, number, shape, string,
} from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ProfileHeroComponent from '../../components/profileHero/profileHero';
import ColumnLayout from '../../layouts/column';
import getProfileInfoService from '../../services/profile/details';
import { devlog } from '../../utils/console';
import { CompleteProfilePath, PublicProfilePathBuilder, UpdateProfilePath } from '../../constants/routes.contants';
import RowLayout from '../../layouts/rows';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';
import { getFeBaseUrl } from '../../utils/env';
import ComingSoonComponent from '../../components/misc/comingSoon';
import ReviewsTabPanel from './reviewTabPanel';
import DetailsTabPanel from './detailsTabPanel';

function CustomTabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: number.isRequired,
};

function TabSection({ profileDetails }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return profileDetails ? (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="reviews" />
          <Tab label="details" />
          <Tab label="rankings" />
          <Tab label="recommendations" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ReviewsTabPanel userId={profileDetails.id} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DetailsTabPanel profileDetails={profileDetails} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ComingSoonComponent />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ComingSoonComponent />
        {/* <Button variant="contained" onClick={onClickRecommend}>recommend me</Button> */}
      </CustomTabPanel>
    </Box>
  ) : null;
}

TabSection.propTypes = {
  profileDetails: shape({
    id: number,
    bio: string,
  }).isRequired,
};

// 17 char max usernames

function ProfileSection({ children }) {
  return (
    <Box
      border={1}
      borderRadius="5px"
      minHeight="100px"
      flexDirection="column"
      width="100%"
      sx={{
        MozBoxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        'box-sizing': 'border-box',
      }}
      display="flex"
      p={1}
    >
      {children}
    </Box>
  );
}

ProfileSection.propTypes = {
  children: node.isRequired,
};

function ProfilePage() {
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [recommended, setRecommended] = useState();
  // const userId = GetUserId();
  // const [reviews, setReviews] = useState([]);

  const { notify } = useCustomSnackbar();

  const navigate = useNavigate();

  const onNotComplete = () => {
    navigate(CompleteProfilePath);
  };

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      try {
        const { data } = await getProfileInfoService();

        if (!data.completedProfile) {
          onNotComplete();
          return;
        }

        setProfile(data?.profileInfo);
        // setRecommended(data?.recommend);
      } catch (err) {
        devlog(err);
      }
      setIsLoading(false);
    };
    helper();
  }, []);

  const onClickShare = async () => {
    const url = `${getFeBaseUrl()}${PublicProfilePathBuilder(profile.username)}`;
    if (!navigator.share) {
      navigator.clipboard.writeText(url);
      notify('copied share link to clipboard');
      return;
    }

    const shareData = {
      title: 'crayoneats profile',
      text: 'come see my profile!',
      url,
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      devlog(err);
    }
  };

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="100%" minWidth="350px" maxWidth="550px">
        {isLoading ? <CircularProgress /> : (
          <ColumnLayout overflow="flex" alignItems="center">
            <ProfileHeroComponent
              username={profile?.username}
              avatarImage={profile?.profileImage}
              joinDate={profile?.createdAt}
              country={profile?.country}
            />
            <Box width="100%">
              <RowLayout>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(UpdateProfilePath, {
                      state: {
                        name: profile.name,
                        bio: profile.bio,
                        country: profile.country,
                        tags: profile.tags,
                        profileImage: profile.profileImage,
                        profileImageKey: profile.profileImageKey,
                      },
                    });
                  }}
                  sx={{ width: '50%' }}
                >
                  edit profile
                </Button>
                <Button
                  variant="outlined"
                  sx={{ width: '50%' }}
                  onClick={onClickShare}
                >
                  share
                </Button>
              </RowLayout>
            </Box>
            <TabSection profileDetails={profile} />
          </ColumnLayout>
        )}
      </Box>
    </Box>
  );
}

export default ProfilePage;
