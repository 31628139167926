import {
  Box,
  Button,
  Card, CardContent, IconButton, Modal, Stack, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  arrayOf, bool, number, shape, string,
} from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Close from '@mui/icons-material/Close';
import ColumnLayout from '../../../layouts/column';
import RowLayout from '../../../layouts/rows';
import TagComponent from '../../tags/tag';
import OpenCloseTagComponent from '../../tags/openCloseTag';
import { RestaurantPathBuilder } from '../../../constants/routes.contants';
import ShareLinkComponent from '../../shareLink/shareLink';
import { getFeBaseUrl } from '../../../utils/env';
import ReviewFormComponent from '../../review/reviewForm';
import SectionHeader from '../../typography/sectionHeader';
import { devlog } from '../../../utils/console';
import RatingStarBarComponent from '../../ratingStarBar/ratingStarBar';
import SaveRestaurantNewComponent from '../../save/saveRestaurantNew';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  py: 1,
  px: 3,
  maxWidth: '500px',
};

function EventRestaurantCardComponent({
  restaurantId,
  restaurantName,
  isSaved,
  restaurantAddress,
  isOpen,
  country,
  tags,
  images,
  eventPassed,
  review,
}) {
  const navigate = useNavigate();

  const [showReviewButton, setShowReviewButton] = useState(!review && !review?.message);

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const handleOpen = () => setOpenReviewModal(true);

  const onClickReview = () => {
    handleOpen();
  };

  const handleClose = () => {
    setOpenReviewModal(false);
  };

  const handleSubmitReview = () => {
    devlog('remove review button');
    handleClose();
    setShowReviewButton(false);
  };

  const moreInfo = () => {
    const restaurantPath = RestaurantPathBuilder(restaurantId);
    navigate(restaurantPath);
  };

  return (
    <Card
      variant="outlined"
      style={{ width: '100%', minWidth: '353px', borderColor: '#000000' }}
    >
      <CardContent style={{ padding: '10px' }}>
        <ColumnLayout overflow="flex">
          <RowLayout
            justifyContent="space-between"
            alignItems="flex-start"
            overflow="flex"
          >
            <Typography
              padding="5px 0px "
              variant="h5"
              component="div"
              fontWeight="bold"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
              textAlign="left"
            >
              {restaurantName}
            </Typography>
            <SaveRestaurantNewComponent
              isSaved={isSaved}
              restaurantId={restaurantId}
            />
          </RowLayout>
          <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
            <TagComponent label={country} type="secondary" />
            {(tags || []).length !== 0
              && tags.map((val) => <TagComponent key={val.id} label={val.name} />)}
          </Stack>
          <RowLayout overflow="flex">
            <Typography>
              Address:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}
              fontWeight="bold"
              textAlign="left"
            >
              {restaurantAddress}
            </Typography>
          </RowLayout>
          <RowLayout overflow="flex">
            <OpenCloseTagComponent isOpen={isOpen} />
          </RowLayout>
          {review && (
            <>
              <RatingStarBarComponent numStar={review.rating} />
              <RowLayout overflow="flex">
                <Typography textAlign="left">
                  your thoughts:
                </Typography>
                <ColumnLayout overflow="flex">
                  <Typography
                    variant="body1"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                    }}
                    fontWeight="bold"
                    textAlign="left"
                  >
                    {review.description}
                  </Typography>
                </ColumnLayout>
              </RowLayout>
            </>
          )}
          <RowLayout overflow="auto">
            {images
              && images.map((item) => (
                <img
                  key={item.img}
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                  style={{ borderRadius: '5px' }}
                />
              ))}
          </RowLayout>
          <RowLayout>
            {eventPassed ? (
              <>
                <Button variant="contained" onClick={moreInfo} size="small">
                  More Info
                </Button>
                <ShareLinkComponent
                  url={`${getFeBaseUrl()}${RestaurantPathBuilder(restaurantId)}`}
                  type="restaurant"
                  name={restaurantName}
                  variant="text"
                  size="small"
                />
                {showReviewButton && (
                <Button variant="contained" onClick={onClickReview} size="small">
                  review
                </Button>
                )}
              </>
            ) : (
              <>
                <Button variant="contained" onClick={moreInfo} size="small">
                  More Info
                </Button>
                <ShareLinkComponent
                  url={`${getFeBaseUrl()}${RestaurantPathBuilder(restaurantId)}`}
                  type="restaurant"
                  name={restaurantName}
                  variant="text"
                  size="small"
                />
                <Button
                  variant="contained"
                  href={`http://maps.google.com/?q=${restaurantName}`}
                  target="_blank"
                  size="small"
                >
                  navigate
                </Button>
              </>
            ) }
          </RowLayout>
          <Modal
            open={openReviewModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <RowLayout justifyContent="space-between">
                <SectionHeader text="review." />
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </RowLayout>
              <ReviewFormComponent
                submitReviewHandler={handleSubmitReview}
                restaurantId={restaurantId}
              />
              <RowLayout justifyContent="space-between">
                <Button size="small" onClick={handleClose}>cancel</Button>
              </RowLayout>
            </Box>
          </Modal>
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

EventRestaurantCardComponent.propTypes = {
  restaurantId: string.isRequired,
  restaurantName: string.isRequired,
  isSaved: bool.isRequired,
  restaurantAddress: string.isRequired,
  isOpen: bool.isRequired,
  country: string.isRequired,
  tags: arrayOf(string),
  images: arrayOf(string),
  eventPassed: bool,
  review: shape({
    id: number,
    restaurantId: number,
    userId: number,
    description: string,
    rating: number,
  }),
};

EventRestaurantCardComponent.defaultProps = {
  tags: [],
  images: [],
  eventPassed: false,
  review: null,
};

function MeetingLocationComponent({ isPassed, restaurantInfo }) {
  const title = isPassed ? 'met here' : 'meeting here';
  return (
    <>
      <Typography variant="h5" fontWeight="bold">{title}</Typography>
      <EventRestaurantCardComponent
        restaurantId={restaurantInfo.id}
        restaurantName={restaurantInfo.name}
        restaurantAddress={restaurantInfo.address}
        isOpen={restaurantInfo.isOpen}
        tags={restaurantInfo.tags}
        eventPassed={isPassed}
        isSaved={restaurantInfo.isSaved}
        country={restaurantInfo.country}
        images={restaurantInfo.images}
        review={restaurantInfo.personalReview}
      />
    </>
  );
}

MeetingLocationComponent.propTypes = {
  isPassed: bool.isRequired,
  restaurantInfo: shape().isRequired,
};

export default MeetingLocationComponent;
