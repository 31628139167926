import { getEventsListApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getEventsListService = async (offset, limit, groupId) => {
  const res = await gateway.get(getEventsListApiDetails.path, {
    params: {
      offset,
      limit,
      groupId,
    },
  });

  return res.data;
};

export default getEventsListService;
