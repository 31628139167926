import {
  Alert, Box, Button, IconButton, Modal, Snackbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { bool, number, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import saveRestaurantService from '../../services/restaurants/save';
import { useAuth } from '../../middlewares/hooks/auth.hook';
import { LoginPath } from '../../constants/routes.contants';
import { devlog } from '../../utils/console';
import unsaveRestaurantService from '../../services/restaurants/unsave';
import RowLayout from '../../layouts/rows';
import SectionHeader from '../typography/sectionHeader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  py: 1,
  px: 3,
  maxWidth: '500px',
};

function SaveRestaurantComponent({
  isSaved, restaurantId, size, color,
}) {
  const { authed } = useAuth();
  const navigate = useNavigate();
  const [saveState, setSaveState] = useState(isSaved);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: 'Saved',
    severity: 'success',
  });

  const [openWarningModal, setOpenWarningModal] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };
  const onSave = async () => {
    if (!authed) {
      navigate(LoginPath);
    }

    if (saveState) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'already saved',
      });
      return;
    }

    let res;
    try {
      res = await saveRestaurantService(restaurantId);
    } catch (err) {
      devlog(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'failed to save',
        severity: 'error',
      });
      return;
    }

    setSnackbarState({
      ...snackbarState,
      open: true,
      message: res?.message || 'saved',
    });

    setSaveState(true);
  };

  const onUnsave = async () => {
    if (!authed) {
      navigate(LoginPath);
    }

    if (!saveState) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'not saved',
      });
      return;
    }

    setOpenWarningModal(true);
  };

  const handleCloseWarningModal = () => {
    setOpenWarningModal(false);
  };

  const confirmUnsave = async () => {
    let res;
    try {
      res = await unsaveRestaurantService(restaurantId);
    } catch (err) {
      devlog(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'failed to unsave',
        severity: 'error',
      });
      return;
    }

    setSnackbarState({
      ...snackbarState,
      open: true,
      message: res?.message || 'unsaved',
    });

    setSaveState(false);
    setOpenWarningModal(false);
  };

  return (
    <>
      <IconButton
        aria-label="save"
        size={size}
        style={{ padding: '5px', margin: 0, borderRadius: '5px' }}
        onClick={saveState ? onUnsave : onSave}
        color={color}
      >
        {saveState ? (
          <BookmarkIcon
            fontSize={size}
            color={color}
          />
        ) : (
          <BookmarkBorderIcon
            fontSize={size}
            color={color}
          />
        )}
      </IconButton>
      <Modal
        open={openWarningModal}
        onClose={handleCloseWarningModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <RowLayout justifyContent="space-between">
            <SectionHeader text="warning." />
            <IconButton onClick={handleCloseWarningModal}>
              <CloseIcon />
            </IconButton>
          </RowLayout>
          <Box my="20px">
            <Typography>You are about to unsave. Are you sure?</Typography>
          </Box>
          <RowLayout justifyContent="space-between">
            <Button size="small" onClick={handleCloseWarningModal}>cancel</Button>
            <Button size="small" onClick={confirmUnsave} variant="contained">confirm</Button>
          </RowLayout>
        </Box>
      </Modal>
      <Snackbar open={snackbarState.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbarState.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </>
  );
}

SaveRestaurantComponent.propTypes = {
  isSaved: bool.isRequired,
  restaurantId: number.isRequired,
  size: string,
  color: string,
};

SaveRestaurantComponent.defaultProps = {
  size: 'large',
  color: 'primary',
};

export default SaveRestaurantComponent;
