import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';
import ColumnLayout from '../../layouts/column';

function RestaurantSkeletonComponent() {
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout alignItems="flex-start" overflow="flex">
          <Stack width="100%">
            <Skeleton variant="text" sx={{ fontSize: '64px' }} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '64px' }} width="60%" />
          </Stack>
          <Skeleton variant="rectangular" width="100%" height="25vh" />
          <Stack width="100%" gap={0}>
            <Skeleton variant="text" sx={{ fontSize: '30px' }} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} width="60%" />
          </Stack>
          <Skeleton variant="text" sx={{ fontSize: '64px' }} width="60%" />
          <Skeleton variant="rectangular" width="100%" height="35vh" />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default RestaurantSkeletonComponent;
