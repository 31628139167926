import { subscribeNewsletterApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const subscribeNewsletterService = async (email) => {
  const response = await gateway.post(subscribeNewsletterApiDetails.path, {
    email,
  });

  return response.data;
};

export default subscribeNewsletterService;
