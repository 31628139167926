import { Box, Typography } from '@mui/material';
import React from 'react';
import { number, string } from 'prop-types';
import SectionHeader from '../../typography/sectionHeader';

function EventHeaderComponent({ name, date }) {
  return (
    <Box width="100%" textAlign="left" flexDirection="column" display="flex">
      <SectionHeader text={name} />
      <Typography color="gray">
        {`${new Date(date * 1000).toLocaleString([], {
          weekday: 'long',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })}`}
      </Typography>
    </Box>
  );
}

EventHeaderComponent.propTypes = {
  name: string.isRequired,
  date: number.isRequired,
};

export default EventHeaderComponent;
