import { node } from 'prop-types';
import React, { createContext, useState } from 'react';
import registerService from '../../services/register/register';
import {
  GetToken,
  RemoveDisplayImage,
  RemoveToken,
  RemoveUserId,
  RemoveUsername,
  SetDisplayImage,
  SetToken,
  SetUserId,
  SetUsername,
} from '../../utils/localStorage';
import loginService from '../../services/login/login';
import { devlog } from '../../utils/console';

const authContext = createContext({
  authed: false,
  login: () => ({ success: false, message: '' }),
  register: () => ({ success: false, message: '' }),
  logout: () => true,
  getToken: () => '',
});

const authHookInit = () => {
  const [authed, setAuthed] = useState(!!GetToken());

  const login = async (identifier, password) => {
    let res;
    let serverError;
    try {
      const { data, error } = await loginService(identifier, password);
      res = data;
      serverError = error;
    } catch (err) {
      devlog(err);
      return {
        success: false,
        message: 'server error',
      };
    }

    if (serverError) {
      return {
        success: false,
        message: serverError.message,
      };
    }

    const { userInfo } = res;

    if (!userInfo) {
      return {
        success: false,
        message: 'user info not found',
      };
    }

    SetToken(userInfo?.token);
    SetUsername(userInfo?.username);
    SetDisplayImage(userInfo?.profileImage);
    SetUserId(userInfo?.id);

    setAuthed(true);
    return {
      success: true,
      userInfo,
    };
  };

  const register = async (username, password, email, newsletterSub) => {
    let res;
    let serverError;
    try {
      const { data, error } = await registerService(username, email, password, newsletterSub);
      res = data;
      serverError = error;
    } catch (err) {
      return { success: false, message: 'server error' };
    }

    if (serverError) {
      return {
        success: false,
        message: serverError.message,
      };
    }

    const { userInfo } = res;

    if (!userInfo) {
      return { success: false };
    }

    SetToken(userInfo?.token);
    SetUsername(userInfo?.username);
    SetDisplayImage(userInfo?.profileImage);
    SetUserId(userInfo?.id);

    setAuthed(true);
    return { success: true, userInfo };
  };

  const logout = () => {
    RemoveUsername();
    RemoveToken();
    RemoveDisplayImage();
    RemoveUserId();
    devlog('iam working');
    setAuthed(false);
    return true;
  };

  return {
    authed, login, register, logout,
  };
};

function AuthProvider({ children }) {
  const auth = authHookInit();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

AuthProvider.propTypes = {
  children: node.isRequired,
};

export default AuthProvider;

export function useAuth() {
  return React.useContext(authContext);
}
