import { getListInfoApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getListInfoService = async (listOrdering, offset, limit, groupId) => {
  const res = await gateway.get(getListInfoApiDetails.path, {
    params: {
      listOrdering,
      offset,
      limit,
      groupId,
    },
  });

  return res.data;
};

export default getListInfoService;
