import React, { useState } from 'react';
import {
  Avatar,
  Box, Button, Divider, IconButton, InputAdornment, OutlinedInput, TextField, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import {
  arrayOf, func, number, shape, string,
} from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import SectionHeader from '../../components/typography/sectionHeader';
import FormErrorTextComponent from '../../components/typography/errorText';
import { GroupDetailsPathBuilder } from '../../constants/routes.contants';
import ColumnLayout from '../../layouts/column';
import { devlog } from '../../utils/console';
import createGroupService from '../../services/groups/createGroup';

function UserSearchItemComponent({
  id, username, email, profileImage, onAddFriend,
  isFriendInList, onRemoveFriend,
}) {
  const onClickAdd = () => {
    onAddFriend({
      id, username, email, profileImage,
    });
  };

  const onClickRemove = () => {
    onRemoveFriend(id);
  };

  return (
    <Box display="flex" p="5px" flexDirection="row" columnGap="10px">
      <Avatar src={profileImage} />
      <Box display="flex" flexDirection="column" textAlign="left">
        <Typography fontWeight="bold">{username}</Typography>
        <Typography variant="caption" color="gray">{email}</Typography>
      </Box>
      <Box marginRight={0} marginLeft="auto">
        {isFriendInList(id)
          ? (
            <IconButton onClick={onClickRemove}>
              <DoneIcon />
            </IconButton>
          )
          : (
            <IconButton onClick={onClickAdd}>
              <AddIcon />
            </IconButton>
          )}
      </Box>
    </Box>
  );
}

UserSearchItemComponent.propTypes = {
  id: number.isRequired,
  username: string.isRequired,
  email: string,
  profileImage: string,
  onAddFriend: func.isRequired,
  isFriendInList: func.isRequired,
  onRemoveFriend: func.isRequired,
};

UserSearchItemComponent.defaultProps = {
  email: '',
  profileImage: '',
};

function UserSearchResultWindowComponent({
  result, onAddFriend,
  isFriendInList, onRemoveFriend,
}) {
  return (
    <Box
      maxHeight="200px"
      minHeight="100px"
      border="1px solid black"
      borderRadius="5px"
      overflow="scroll"
      display="flex"
      flexDirection="column"
    >
      {result?.length !== 0 ? result.map((val, i) => {
        if (result.length - 1 === i) {
          return (
            <UserSearchItemComponent
              key={val.id}
              id={val.id}
              username={val.username}
              email={val.email}
              onAddFriend={onAddFriend}
              isFriendInList={isFriendInList}
              onRemoveFriend={onRemoveFriend}
            />
          );
        }

        return (
          <>
            <UserSearchItemComponent
              key={val.id}
              id={val.id}
              username={val.username}
              email={val.email}
              onAddFriend={onAddFriend}
              isFriendInList={isFriendInList}
              onRemoveFriend={onRemoveFriend}
            />
            <Divider />
          </>
        );
      }) : (
        <Typography
          justifySelf="center"
          alignSelf="center"
          margin="auto"
        >
          notfound
        </Typography>
      )}
      <Divider variant="middle" />
    </Box>
  );
}

UserSearchResultWindowComponent.propTypes = {
  result: arrayOf(shape({
    id: number,
    username: string,
    email: string,
    profileImage: string,
  })).isRequired,
  onAddFriend: func.isRequired,
  isFriendInList: func.isRequired,
  onRemoveFriend: func.isRequired,
};

function FriendListItemComponent({
  id, username, email, profileImage,
  onRemoveFriend,
}) {
  const onClickRemove = () => {
    onRemoveFriend(id);
  };

  return (
    <Box display="flex" p="5px" flexDirection="row" columnGap="10px">
      <Avatar src={profileImage} />
      <Box display="flex" flexDirection="column" textAlign="left">
        <Typography fontWeight="bold">{username}</Typography>
        <Typography variant="caption" color="gray">{email}</Typography>
      </Box>
      <Box marginRight={0} marginLeft="auto">
        <IconButton onClick={onClickRemove}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

FriendListItemComponent.propTypes = {
  id: number.isRequired,
  username: string.isRequired,
  email: string,
  profileImage: string,
  onRemoveFriend: func.isRequired,
};

FriendListItemComponent.defaultProps = {
  email: '',
  profileImage: '',
};

function FriendListWindowComponent({ result, onRemoveFriend }) {
  return (
    <Box
      border="1px solid black"
      minHeight="150px"
      borderRadius="5px"
      display="flex"
      flexDirection="column"
    >
      {result?.length !== 0 ? result.map((val, i) => {
        if (result.length - 1 === i) {
          return (
            <FriendListItemComponent
              key={val.id}
              id={val.id}
              username={val.username}
              email={val.email}
              onRemoveFriend={onRemoveFriend}
            />
          );
        }

        return (
          <>
            <FriendListItemComponent
              key={val.id}
              id={val.id}
              username={val.username}
              email={val.email}
              onRemoveFriend={onRemoveFriend}
            />
            <Divider />
          </>
        );
      }) : (
        <Typography
          justifySelf="center"
          alignSelf="center"
          margin="auto"
        >
          start adding!
        </Typography>
      )}
      <Divider variant="middle" />
    </Box>
  );
}

FriendListWindowComponent.propTypes = {
  result: arrayOf(shape({
    id: number,
    username: string,
    email: string,
    profileImage: string,
  })).isRequired,
  onRemoveFriend: func.isRequired,
};
/*
group name
members
- drop down with plus button at the side.
- adds to the list
- have a minus list at the bottom

search bar
add will add the person to the list
*/
function CreateGroupPage() {
  const canAddFriendFeature = false;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [searchFriendValue, setSearchFriendValue] = useState('');
  const [friendList, setFriendList] = useState([]);

  const onAddFriend = (val) => {
    setFriendList([...friendList, val]);
  };

  const isFriendInList = (id) => friendList.filter((val) => val.id === id).length !== 0;

  const onRemoveFriend = (id) => {
    setFriendList(friendList.filter((val) => val.id !== id));
  };

  const onSubmit = async (formData) => {
    const { data } = await createGroupService(formData.name);
    // console.log(data, friendList.map((val) => val.id));

    const newGroupId = data.groupInfo.id;
    // navigate to new details page
    navigate(GroupDetailsPathBuilder(newGroupId));
  };

  const nameField = register('name', { required: true });

  const onClickCancel = () => {
    navigate(-1);
  };

  const onClickSearchFriends = () => {
    devlog(searchFriendValue);
  };

  const userInfo = [{
    id: 1,
    username: 'bignoodles',
    email: 'testing123@testmail.com',
    profileImage: '',
  }, {
    id: 2,
    username: 'smallnoodles',
    email: 'testing123@testmail.com',
    profileImage: '',
  }, {
    id: 3,
    username: 'bananapie',
    email: 'testing123@testmail.com',
    profileImage: '',
  }, {
    id: 4,
    username: 'bignoodles',
    email: 'testing123@testmail.com',
    profileImage: '',
  }];

  const searchResult = {
    userInfo,
  };
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <SectionHeader text="create group." />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ColumnLayout>
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">name</Typography>
              <Typography variant="caption">name of your group?</Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="name"
              type="text"
              InputProps={{
                sx: { height: '40px' },
              }}
              ref={nameField.ref}
              name={nameField.name}
              onChange={nameField.onChange}
              onBlur={nameField.onBlur}
            />
            {errors.name && <FormErrorTextComponent text="name this is required" />}
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">friends</Typography>
              <Typography variant="caption">who do you want to add? find by username or email. you can invite them later too</Typography>
              {!canAddFriendFeature && (
              <Typography>
                due to spam concerns,
                you can only send invite links to your friends
                after the group is created. we will add this functionality
                once we are able to do the &quot;accept request&quot; feature
              </Typography>
              )}
            </Box>
            {canAddFriendFeature && (
              <>
                <OutlinedInput
                  id="search"
                  type="text"
                  value={searchFriendValue}
                  onChange={(e) => {
                    setSearchFriendValue(e.target.value);
                  }}
                  sx={{ height: '40px' }}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="search friends"
                        onClick={onClickSearchFriends}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
            )}
                />
                <UserSearchResultWindowComponent
                  result={searchResult.userInfo}
                  onAddFriend={onAddFriend}
                  isFriendInList={isFriendInList}
                  onRemoveFriend={onRemoveFriend}
                />
                <FriendListWindowComponent
                  result={friendList}
                  onRemoveFriend={onRemoveFriend}
                />
              </>
            )}
            <Box display="flex" justifyContent="right" width="100%" columnGap="5px">
              <Button size="medium" variant="outlined" onClick={onClickCancel}>cancel</Button>
              <Button size="medium" variant="contained" type="submit">create</Button>
            </Box>
          </ColumnLayout>
        </form>
      </Box>
    </Box>
  );
}

export default CreateGroupPage;
