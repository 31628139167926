import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import ColumnLayout from '../../layouts/column';
import RegisterCardComponent from '../../components/registerCard/registerCard';
import { useAuth } from '../../middlewares/hooks/auth.hook';
import { HomePath } from '../../constants/routes.contants';

function RegisterPage() {
  const { authed } = useAuth();
  const { state } = useLocation();

  if (authed) {
    return <Navigate to={HomePath} />;
  }
  return (
    <ColumnLayout overflow="flex" alignItems="center">
      <RegisterCardComponent referrer={state?.path ? state.path : '/'} />
    </ColumnLayout>

  );
}

export default RegisterPage;
