import React from 'react';
import { Box } from '@mui/material';
import ComingSoonComponent from '../../components/misc/comingSoon';
import ColumnLayout from '../../layouts/column';
import SectionHeader from '../../components/typography/sectionHeader';

function ForgetPasswordPage() {
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <SectionHeader text="forget password." />
        <ColumnLayout alignItems="flex-start" overflow="flex">
          <ComingSoonComponent subtext="who ask you forget password" />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default ForgetPasswordPage;
