import { searchApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const searchService = async (query) => {
  const response = await gateway.post(searchApiDetails.path, {
    query,
  });

  const { data, error } = response.data;

  return { data, error };
};

export default searchService;
