import { Box, Skeleton } from '@mui/material';
import React from 'react';
import ColumnLayout from '../../layouts/column';

function ListGroupSkeleton() {
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout alignItems="flex-start" overflow="flex">
          <Skeleton variant="rounded" width="100%" height="100px" />
          <Skeleton variant="rounded" width="100%" height="100px" />
          <Skeleton variant="rounded" width="100%" height="100px" />
          <Skeleton variant="rounded" width="100%" height="100px" />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default ListGroupSkeleton;
