import {
  Box, Button, Card, CardContent, CircularProgress, Divider, IconButton,
  InputAdornment, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Stack, Switch, Tab, Tabs,
  TextField, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  node, number, oneOfType, shape, string,
} from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SectionHeader from '../../components/typography/sectionHeader';
import ColumnLayout from '../../layouts/column';
import RowLayout from '../../layouts/rows';
import TagComponent from '../../components/tags/tag';
// import OpenCloseTagComponent from '../../components/tags/openCloseTag';
import { EventDetailsPathBuilder, GroupDetailsPathBuilder, GroupListPath } from '../../constants/routes.contants';
import ComingSoonComponent from '../../components/misc/comingSoon';
import createBasicEventService from '../../services/events/createEvent';
import searchService from '../../services/search/search';
import recommendRestaurantService from '../../services/restaurants/recommend';
import getRestaurantDetailsService from '../../services/restaurants/details';

// name optional. if no name, we will just name it group name + even id
// date required
// basic - randomly pick a retaurant
// basic+ - start a survey with a few options

function CustomTabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: number.isRequired,
};

/*
restaurant name
address
+ is open on that day
+ tags displayed
*/
function EventSearchRestaurantResult({ restaurantInfo, setSelectedRestaurant }) {
  const onClickSelect = () => {
    setSelectedRestaurant(restaurantInfo);
  };
  return (
    <Box display="flex" p="5px" flexDirection="row" columnGap="10px" alignItems="center">
      <Box display="flex" flexDirection="column" textAlign="left">
        <Typography fontWeight="bold">{restaurantInfo.name}</Typography>
        <Typography variant="caption" color="gray">{restaurantInfo.address}</Typography>
      </Box>
      <Box marginRight={0} marginLeft="auto">
        <Button variant="contained" size="small" onClick={onClickSelect}>select</Button>
      </Box>
    </Box>
  );
}

EventSearchRestaurantResult.propTypes = {
  restaurantInfo: shape({
    id: oneOfType([string, number]),
    name: string,
    address: string,
  }).isRequired,
  setSelectedRestaurant: func.isRequired,
};

function SelectedRestaurantCard({ restaurantInfo, unselectRestaurant }) {
  const onClickCross = () => {
    unselectRestaurant(restaurantInfo);
  };
  return (
    <Card
      variant="outlined"
      style={{ borderColor: '#000000' }}
    >
      <CardContent style={{ padding: '10px' }}>
        <ColumnLayout overflow="flex">
          <RowLayout
            justifyContent="space-between"
            alignItems="flex-start"
            overflow="flex"
          >
            <Typography
              padding="5px 0px "
              variant="h5"
              component="div"
              fontWeight="bold"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
              textAlign="left"
            >
              {restaurantInfo.name}
            </Typography>
            <IconButton onClick={onClickCross}>
              <CloseIcon />
            </IconButton>
          </RowLayout>
          <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
            <TagComponent label="country" type="secondary" />
            {(restaurantInfo?.tags || []).length !== 0
          && restaurantInfo?.tags.map((val) => <TagComponent key={val.id} label={val.name} />)}
          </Stack>
          <RowLayout overflow="flex">
            <Typography>
              Address:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}
              fontWeight="bold"
              textAlign="left"
            >
              {restaurantInfo?.address}
            </Typography>
          </RowLayout>
          {/* <RowLayout overflow="flex">
            <OpenCloseTagComponent isOpen={restaurantInfo?.isOpen} />
            <Typography>
              on meet up day
            </Typography>
          </RowLayout> */}
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

SelectedRestaurantCard.propTypes = {
  restaurantInfo: shape({
    id: oneOfType([string, number]),
    name: string,
    address: string,
  }).isRequired,
  unselectRestaurant: func.isRequired,
};

/*
search or recommend
both will use the same scroll window
*/
function BasicTabPanel({ setBasicFormData }) {
  const [searchRestaurantValue, setSearchRestaurantValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevIds, setPrevIds] = useState([]);
  const [isLoadingPreSelect, setIsLoadingPreSelect] = useState(false);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('r');

  const [selectedRestaurant, setSelectedRestaurant] = useState();

  const setSelectedRestaurantWrapper = (restInfo) => {
    // set restaurant id in form
    setBasicFormData({ restaurantId: restInfo.id });
    setSelectedRestaurant(restInfo);
  };

  const unSelectRestaurantWrapper = () => {
    // unset restaurant id in form
    // console.log(restId);
    setBasicFormData({ restaurantId: null });
    setSelectedRestaurant(null);
  };

  useEffect(() => {
    if (!query || query.length === 0) {
      return;
    }
    const helper = async () => {
      setIsLoadingPreSelect(true);
      const { data } = await getRestaurantDetailsService(query);
      if (!data) {
        setIsLoadingPreSelect(false);
        return;
      }

      const { restaurantInfo } = data;

      if (!restaurantInfo) {
        setIsLoadingPreSelect(false);
        return;
      }
      setSelectedRestaurantWrapper(restaurantInfo);
      setIsLoadingPreSelect(false);
    };

    helper();
  }, [query]);

  const onClickSearchRestaurants = async () => {
    setIsLoading(true);
    const { data } = await searchService(searchRestaurantValue);
    const { restaurantInfos } = data;

    setSearchResult(restaurantInfos);

    setIsLoading(false);
  };

  const onClickRecommend = async () => {
    setIsLoading(true);

    const referrer = { page: 'recommend' };
    const params = { previousIds: prevIds.join(',') };

    const recommendation = await recommendRestaurantService(3, referrer, params);
    const seen = recommendation.data.restaurantInfos.map((val) => val.id);
    setPrevIds([...prevIds, ...seen]);
    setSearchResult(recommendation.data.restaurantInfos);

    setIsLoading(false);
  };

  if (isLoadingPreSelect) {
    return <Typography>iamloding</Typography>;
  }
  return (
    <ColumnLayout overflow="flex">
      <Box width="100%" textAlign="left">
        <Typography variant="h6">basic mode</Typography>
        <Typography>
          Basic mode is best if everyone is okay with anything you choose.
          Just click on the recommend button and choose what you like and
          create the event.
        </Typography>
      </Box>
      <ColumnLayout>
        <OutlinedInput
          placeholder="restaurant name"
          id="search"
          type="text"
          value={searchRestaurantValue}
          onChange={(e) => {
            setSearchRestaurantValue(e.target.value);
          }}
          sx={{ height: '40px' }}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="search restaurant"
                onClick={onClickSearchRestaurants}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
            )}
        />
        <Divider>or</Divider>
        <Button variant="contained" onClick={onClickRecommend}>recommend</Button>
        <Box
          maxHeight="200px"
          minHeight="100px"
          border="1px solid black"
          borderRadius="5px"
          overflow="scroll"
          display="flex"
          flexDirection="column"
        >
          {isLoading ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              my="20px"
            >
              <CircularProgress />
            </Box>
          ) : searchResult.map((val) => (
            <>
              <EventSearchRestaurantResult
                key={val.id}
                restaurantInfo={val}
                setSelectedRestaurant={setSelectedRestaurantWrapper}
              />
              <Divider />
            </>
          ))}
        </Box>
      </ColumnLayout>
      {selectedRestaurant
      && (
      <Box width="100%" textAlign="left">
        <Typography variant="h6" fontWeight="bold">selected restaurant</Typography>
        <SelectedRestaurantCard
          restaurantInfo={selectedRestaurant}
          unselectRestaurant={unSelectRestaurantWrapper}
        />
      </Box>
      )}
    </ColumnLayout>
  );
}

BasicTabPanel.propTypes = {
  setBasicFormData: func.isRequired,
};

function RestaurantOptionsDisplay({ name }) {
  return (
    <Box
      border="1px solid black"
      borderRadius="5px"
      textAlign="left"
      display="flex"
      padding="5px"
      flexDirection="row"
      alignItems="center"
    >
      <Radio disabled value="yes" />
      <Typography>{name}</Typography>
    </Box>
  );
}

RestaurantOptionsDisplay.propTypes = {
  name: string.isRequired,
};

function BasicPlusTabPanel({ basicPlusFormData, setBasicPlusFormData, plannedDate }) {
  const workInProgress = true;
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [anythingOption, setAnythingOption] = useState(false);
  const [configDisabled, setConfigDisabled] = useState(true);
  const [generateLabel, setGenerateLabel] = useState('generate');
  const [expireDate, setExpireDate] = useState();
  const [mealType, setMealType] = useState('dinner');
  const startingVal = expireDate || plannedDate.add(-1, 'day');

  const handleChangeAnythingOption = (event) => {
    setAnythingOption(event.target.checked);
    setBasicPlusFormData({
      ...basicPlusFormData,
      anything: event.target.checked,
    });
  };

  const demoGeneratedRestaurant = [{ id: 1, name: 'one fattened calf' },
    { id: 2, name: 'tai er suan cai yu tang' }, { id: 3, name: 'long ass name that should not even be allowed in this world' }];

  const setRestaurantIdOptionsWrapper = (restInfo) => {
    // set restaurant id in form
    const restOpt = restInfo.map((val) => val.id);
    // console.log(restOpt);
    setBasicPlusFormData({
      restaurantOptions: restOpt,
      expireDate: startingVal?.unix(),
      anything: false,
      mealType,
    });
  };

  const onChangeExpire = (newValue) => {
    setExpireDate(newValue);
    setBasicPlusFormData({
      ...basicPlusFormData,
      expireDate: newValue?.unix(),
    });
  };

  const onChangeMealType = (e) => {
    setMealType(e.target.value);
    setBasicPlusFormData({
      ...basicPlusFormData,
      mealType: e.target.value,
    });
  };

  const onClickGenerate = () => {
    setRestaurantIdOptionsWrapper(demoGeneratedRestaurant);
    setSurveyOptions(demoGeneratedRestaurant);
    if (generateLabel === 'generate') {
      setGenerateLabel('regenerate');
    }
    setConfigDisabled(false);
  };

  return (
    <ColumnLayout overflow="flex">
      <Box width="100%" textAlign="left">
        <Typography variant="h6">basic+</Typography>
        <Typography>
          Basic+ mode gives a little more flexibility.
          we will generate a survey for you and your friends to vote.
          By default, votes close a day before the event. However, that
          is customizable.
        </Typography>
      </Box>
      {workInProgress ? <ComingSoonComponent subtext="working on it" /> : (
        <>
          <Box>
            <Button variant="contained" onClick={onClickGenerate}>{generateLabel}</Button>
          </Box>
          <Typography variant="h6" fontWeight="bold" textAlign="left">config</Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            textAlign="left"
          >
            <Typography>anything?</Typography>
            <Box width="70%" textAlign="left">
              <Switch
                checked={anythingOption}
                onChange={handleChangeAnythingOption}
                disabled={configDisabled}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography>expires</Typography>
            <Box width="70%">
              <DateTimePicker
                maxDate={plannedDate.add(-1, 'day')}
                minDate={dayjs()}
                value={startingVal}
                onChange={onChangeExpire}
                disabled={configDisabled}
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography>meal type</Typography>
            <Box width="70%">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mealType}
                onChange={onChangeMealType}
                fullWidth
                size="small"
              >
                <MenuItem value="breakfast">breakfast</MenuItem>
                <MenuItem value="lunch">lunch</MenuItem>
                <MenuItem value="dinner">dinner</MenuItem>
                <MenuItem value="supper">supper</MenuItem>
              </Select>
            </Box>
          </Box>
          <Typography variant="h6" fontWeight="bold" textAlign="left">survey options</Typography>
          <RadioGroup defaultValue="yes">
            <ColumnLayout overflow="flex">
              {surveyOptions.map((val) => (
                <RestaurantOptionsDisplay
                  key={val.id}
                  name={val.name}
                />
              ))}
              {anythingOption && <RestaurantOptionsDisplay name="anything" />}
            </ColumnLayout>
          </RadioGroup>
        </>
      )}
    </ColumnLayout>
  );
}

BasicPlusTabPanel.propTypes = {
  basicPlusFormData: shape({
    restaurantOptions: arrayOf(oneOfType([string, number])),
    expireDate: instanceOf(dayjs),
    anything: bool,
    mealType: string,
  }).isRequired,
  setBasicPlusFormData: func.isRequired,
  plannedDate: instanceOf(dayjs).isRequired,
};

function CreateEventPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formErrorText, setFormErrorText] = useState();
  const [eventDate, setEventDate] = useState(dayjs().add(1, 'day'));
  const [tabValue, setTabValue] = React.useState(0);
  const {
    register,
    handleSubmit,
  } = useForm();

  const [basicFormData, setBasicFormData] = useState({});
  const [basicPlusFormData, setBasicPlusFormData] = useState({});

  const onClickCancel = () => {
    navigate(-1);
  };

  const onSubmit = async (formData) => {
    if (!state) {
      navigate(-1);
      return;
    }

    const { groupId } = state;
    if (!groupId) {
      navigate(GroupListPath);
      return;
    }
    // console.log(data, eventDate.unix());
    // based on tab value, we will populate the different fields
    if (tabValue === 0) {
      // if not restaurantId then block
      if (!basicFormData.restaurantId) {
        setFormErrorText('no restaurant was selected. eat air?');
        return;
      }

      // const formData = {
      //   name: data.name,
      //   eventDate: eventDate.unix(),
      //   restaurantId: basicFormData.restaurantId,
      //   type: 1,
      //   groupId,
      // };

      // console.log(formData);

      const gidNum = Number(groupId);
      const isInvalid = Number.isNaN(gidNum);
      if (isInvalid) {
        navigate(GroupListPath);
        return;
      }

      const { data } = await createBasicEventService(
        formData.name,
        basicFormData.restaurantId,
        eventDate.unix(),
        gidNum,
      );

      if (!data) {
        navigate(GroupDetailsPathBuilder(gidNum));
      }

      navigate(EventDetailsPathBuilder(data.eventInfo.id), { state: { groupId: gidNum } });

      return;
    }

    if (tabValue === 1) {
      setFormErrorText('basic plus is not done...');
      return;
      // if (!basicPlusFormData.restaurantOptions) {
      //   console.log('there is no restaurant options!!');
      //   setFormErrorText('no survey generated. create what event...');
      //   return;
      // }
      // console.log('set basic + data', basicPlusFormData);
    }
    // call the create API
    // from the response, we will get the event id and redirect
    const res = { id: 1, groupId: 1 };
    navigate(EventDetailsPathBuilder(res.id), { state: { groupId: res.groupId } });
  };

  const nameField = register('name');

  const handleChangeTab = (event, newValue) => {
    setFormErrorText();
    setTabValue(newValue);
  };

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <SectionHeader text="create event." />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ColumnLayout>
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">name</Typography>
              <Typography variant="caption">any name for the event?</Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="name"
              type="text"
              InputProps={{
                sx: { height: '40px' },
              }}
              ref={nameField.ref}
              name={nameField.name}
              onChange={nameField.onChange}
              onBlur={nameField.onBlur}
            />

            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">event date</Typography>
              <Typography variant="caption">when yall meeting?</Typography>
            </Box>
            <DateTimePicker
              minDate={dayjs().add(1, 'day')}
              value={eventDate}
              onChange={(newValue) => setEventDate(newValue)}
            />

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} aria-label="create event sections">
                <Tab label="basic" />
                <Tab label="basic +" />
              </Tabs>
              <CustomTabPanel value={tabValue} index={0}>
                <BasicTabPanel setBasicFormData={setBasicFormData} />
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <BasicPlusTabPanel
                  basicPlusFormData={basicPlusFormData}
                  setBasicPlusFormData={setBasicPlusFormData}
                  plannedDate={eventDate}
                />
              </CustomTabPanel>
            </Box>
            {formErrorText?.length > 0 && <Typography textAlign="left" color="error">{formErrorText}</Typography>}
            {tabValue === 0 && (
            <Box display="flex" justifyContent="right" width="100%" columnGap="5px">
              <Button size="medium" variant="outlined" onClick={onClickCancel}>cancel</Button>
              <Button size="medium" variant="contained" type="submit">create</Button>
            </Box>
            )}
          </ColumnLayout>
        </form>
      </Box>
    </Box>
  );
}

export default CreateEventPage;
