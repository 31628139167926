import { getRestaurantDetailsApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getRestaurantDetailsService = async (restaurantId) => {
  const queryUrl = getRestaurantDetailsApiDetails.path + restaurantId;
  const response = await gateway.get(queryUrl);

  const { data, error } = response.data;

  return { data, error };
};

export default getRestaurantDetailsService;
