import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { number, string } from 'prop-types';
import ColumnLayout from '../../layouts/column';
import TagComponent from '../tags/tag';
import RowLayout from '../../layouts/rows';

function convertUnixTimestampToLocalDate(timestamp) {
  const dateTimeString = new Date(timestamp * 1000).toLocaleDateString(undefined, { dateStyle: 'full' });
  return dateTimeString;
}

function ProfileHeroComponent({
  avatarImage, username, joinDate, country,
}) {
  return (
    <Box
      width="100%"
      border={1}
      borderRadius="5px"
    >
      <Box width="100%" height="180px" position="relative">
        <Box bgcolor="pink" height="80%" borderRadius="4px 4px 0px 0px" />
        <Avatar
          sx={{
            width: 100,
            height: 100,
            position: 'absolute',
            left: { xs: 10, md: 20 },
            bottom: 5,
            border: 2,
            borderColor: 'white',
          }}
          src={avatarImage}
        />
      </Box>
      <Box textAlign="left" px={{ xs: '10px', md: '20px' }} pb="10px">
        <ColumnLayout overflow="flex">
          <Typography variant="h5" fontWeight="bold">{username}</Typography>
          <Typography variant="body2">
            {`joined on ${convertUnixTimestampToLocalDate(joinDate)}`}
          </Typography>
          <RowLayout>
            <TagComponent label={country} type="secondary" />
          </RowLayout>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

ProfileHeroComponent.propTypes = {
  avatarImage: string,
  username: string,
  joinDate: number,
  country: string,
};

ProfileHeroComponent.defaultProps = {
  avatarImage: '',
  username: '',
  joinDate: 877795200,
  country: 'singapore',
};

export default ProfileHeroComponent;
