import React from 'react';
import { Box } from '@mui/material';
import ComingSoonComponent from '../../components/misc/comingSoon';
import ColumnLayout from '../../layouts/column';
import TitleComponent from '../../components/typography/title';

function NotificationsPage() {
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <TitleComponent text="notifications." />
        <ColumnLayout alignItems="center" overflow="flex">
          <ComingSoonComponent />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default NotificationsPage;
