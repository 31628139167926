import { Chip } from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react';

function IsAdminTagComponent() {
  return (
    <Chip
      label="admin"
      variant="filled"
      style={{ fontWeight: 'bold', backgroundColor: blue[200] }}
      size="small"
    />
  );
}

export default IsAdminTagComponent;
