import { Alert, Box } from '@mui/material';
import React, { useState } from 'react';
import { GetTopAlertCloseTime, SetTopAlertCloseTime } from '../../utils/localStorage';
import { devlog } from '../../utils/console';

function TopAlertBannerComponent() {
  const closeTime = GetTopAlertCloseTime();
  const timeNoti = 1718627313190;
  const [isOpen, setIsOpen] = useState(timeNoti > closeTime);

  const onCloseBanner = () => {
    const timeNow = new Date().getTime();
    SetTopAlertCloseTime(timeNow);
    setIsOpen(false);
  };

  devlog(closeTime, timeNoti);

  // get notification
  // structure
  /*
    {
      message
      link
      type
      hyperlink_word
    }
  */

  return isOpen && (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px" mb="16px">
        <Alert
          severity="info"
          sx={{ textAlign: 'left', alignItems: 'center' }}
          onClose={() => { onCloseBanner(); }}
        >
          New Release Alert.
          Click here to find out more!
        </Alert>
      </Box>
    </Box>
  );
}

export default TopAlertBannerComponent;
