import React, { useState } from 'react';
import {
  Box, Button, TextField, Typography,
} from '@mui/material';
import ColumnLayout from '../../layouts/column';
import TitleComponent from '../../components/typography/title';
import SectionHeader from '../../components/typography/sectionHeader';
import ComingSoonComponent from '../../components/misc/comingSoon';
import createContributeLinkService from '../../services/contributeLink/createContributeLink';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';

function isValidHttpUrl(val) {
  const tooLong = val.length >= 2000;
  let url;

  try {
    url = new URL(val);
  } catch (_) {
    return { isValid: false };
  }

  return {
    isValid: url.protocol === 'http:' || url.protocol === 'https:',
    raw: url,
    hostname: url.hostname,
    isTooLong: tooLong,
  };
}

function ContributePage() {
  const [link, setLink] = useState('');
  const [linkDetails, setLinkDetails] = useState({ isValid: true, hostname: '', isTooLong: false });
  const { notify } = useCustomSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const submitLink = async () => {
    setIsLoading(true);
    const extractedInfo = isValidHttpUrl(link);
    const linkValid = extractedInfo.isValid;
    if (!linkValid) {
      setLinkDetails({ ...linkDetails, ...extractedInfo });
      return;
    }

    const { error } = await createContributeLinkService(link);

    if (!error) {
      notify('thank you for helping!');
      setLinkDetails({
        ...linkDetails, isValid: true, hostname: '', isTooLong: false,
      });
      setLink('');
    }

    setIsLoading(false);
  };

  return (
    <ColumnLayout overflow="flex" alignItems="center" justifyContent="center" spacing="30px">
      <Box
        maxWidth="300px"
        minHeight="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <TitleComponent text="contribute." />
        <Typography variant="subtitle1">
          add to the 1000s of restaurants on our site
        </Typography>
      </Box>
      <Box
        width="100%"
        maxWidth="300px"
        minHeight="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        rowGap="10px"
      >
        <Box width="100%">
          <SectionHeader text="submit link" />
          <Typography color="gray">
            submit a link and we will help you index it and add it to your list once it is processed
          </Typography>
        </Box>
        <TextField
          fullWidth
          disabled={isLoading}
          variant="outlined"
          placeholder="restaurant link"
          value={link}
          onChange={(event) => {
            setLink(event.target.value);
          }}
          size="small"
          onBlur={() => {
            const extractedInfo = isValidHttpUrl(link);
            setLinkDetails({ ...linkDetails, ...extractedInfo });
          }}
        />
        {!linkDetails?.isValid && (
        <Typography color="red">
          invalid link:
          {linkDetails.isTooLong && 'link too long'}
        </Typography>
        )}
        {linkDetails?.isValid && linkDetails?.hostname?.length !== 0 && (
        <Typography>
          {`source: ${linkDetails.hostname}`}
        </Typography>
        )}

        <Button
          variant="contained"
          disabled={!linkDetails?.isValid || isLoading}
          sx={{ width: '40%' }}
          onClick={submitLink}
        >
          submit
        </Button>
      </Box>
      <Box
        width="100%"
        maxWidth="300px"
        minHeight="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <SectionHeader text="telegram bot" />
        <Typography color="gray">
          to provide convenience for you to share links,
          you can also link your account to our telegram bot
        </Typography>
        <Box mt="10px" width="100%">
          <ComingSoonComponent
            subtext="we are working on this actively. below is the feature document we are working off. feel free to have a look"
            reqLink="https://blog.codingcrayons.com/posts/community-involvement-tool/"
            featureName="telegram-support"
          />
        </Box>
      </Box>
    </ColumnLayout>
  );
}

export default ContributePage;
