import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  arrayOf, bool, number, shape, string,
} from 'prop-types';
import RowLayout from '../../layouts/rows';
import ColumnLayout from '../../layouts/column';

// hours are an array of objects with start time and end time
// ordered by day

const dayOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

function getDayOfWeekIndex() {
  const d = new Date();
  const day = d.getDay();
  return (day + 6) % 7;
}

function convertUnixTimestampToLocalTime(timestamp) {
  const dateTimeString = new Date(timestamp * 1000).toLocaleTimeString(undefined, { timeStyle: 'short' });
  return dateTimeString;
}

function OpeningHourRow({ day, details, isBold }) {
  return (
    <RowLayout overflow="flex" justifyContent="space-between">
      <Box width="100px">
        <Typography fontWeight={isBold ? 'bold' : ''} textAlign="left">
          {day}
        </Typography>
      </Box>
      <Box>
        {details.length
          ? details.map((val, i) => {
            const uni = val.start + val.end + i;
            return (
              <Typography key={uni} fontWeight={isBold ? 'bold' : ''}>
                {`${convertUnixTimestampToLocalTime(val.start)} - ${convertUnixTimestampToLocalTime(val.end)}`}
              </Typography>
            );
          })
          : (
            <Typography fontWeight={isBold ? 'bold' : ''}>
              closed
            </Typography>
          )}
      </Box>
    </RowLayout>
  );
}

OpeningHourRow.propTypes = {
  day: string.isRequired,
  details: arrayOf(shape({
    open: number,
    close: number,
  })),
  isBold: bool,
};

OpeningHourRow.defaultProps = {
  details: [],
  isBold: false,
};

function OpeningHoursComponent({ hours, specialNote }) {
  // [{day: 3, start: 123456, end: 123456}]
  // map by day

  const dayToHoursMap = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };

  if (hours) {
    hours.forEach((val) => {
      const { day } = val;
      if (!dayToHoursMap[day]) {
        dayToHoursMap[day] = [];
      }
      dayToHoursMap[day].push(val);
    });
  }

  return (
    <Box width="100%" maxWidth="400px">
      <ColumnLayout overflow="flex">
        {dayOfWeek.map((val, i) => (
          <OpeningHourRow
            key={val}
            day={val}
            details={dayToHoursMap[i]}
            isBold={getDayOfWeekIndex() === i}
          />
        ))}
        <Typography>
          {specialNote.length !== 0 && `Note: ${specialNote}`}
        </Typography>
      </ColumnLayout>
    </Box>

  );
}

OpeningHoursComponent.propTypes = {
  hours: arrayOf(arrayOf(shape({
    open: number, close: number,
  }))).isRequired,
  specialNote: string,
};

OpeningHoursComponent.defaultProps = {
  specialNote: '',
};

export default OpeningHoursComponent;
