import React, { useEffect, useState } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { arrayOf, shape } from 'prop-types';
import TitleComponent from '../../components/typography/title';
import GroupListItemComponent from '../../components/groupDisplay/groupListItem';
import ColumnLayout from '../../layouts/column';
import { GroupCreatePath } from '../../constants/routes.contants';
import getGroupsListService from '../../services/groups/getGroupsList';
import ListGroupSkeleton from './listGroupsSkeleton';

function GroupListView({ groupList }) {
  return groupList.length === 0
    ? (
      <>
        <Typography>pointup</Typography>
        <Typography variant="h5">create a new group now!</Typography>
      </>
    )
    : groupList.map((val) => (
      <GroupListItemComponent
        key={val.id}
        id={val.id}
        name={val.name}
        numMembers={val.numMembers}
        isAdmin={false}
      />
    ));
}

GroupListView.propTypes = {
  groupList: arrayOf(shape()).isRequired,
};

function ListGroupsPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const { data } = await getGroupsListService(0, 100);

      setGroupList(data?.groupInfos || []);
      setIsLoading(false);
    };

    helper();
  }, []);

  const onClickCreate = () => {
    navigate(GroupCreatePath);
  };
  return (
    <ColumnLayout alignItems="center">
      <TitleComponent text="groups." />
      <Button onClick={onClickCreate} variant="contained" endIcon={<AddIcon />}>create</Button>
      <Divider />
      {isLoading ? <ListGroupSkeleton />
        : <GroupListView groupList={groupList} />}
    </ColumnLayout>
  );
}

export default ListGroupsPage;
