export function getEnv() {
  return process.env.REACT_APP_ENV;
}

export function getBeBaseUrl() {
  return process.env.REACT_APP_BE_URL;
}

export function getFeBaseUrl() {
  return process.env.REACT_APP_FE_URL;
}
