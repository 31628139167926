import { getEventDetailsApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getEventDetailsService = async (eventId) => {
  const queryUrl = getEventDetailsApiDetails.path + eventId;
  const response = await gateway.get(queryUrl);

  const { data, error } = response.data;

  return { data, error };
};

export default getEventDetailsService;
