import { getGroupDetailApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getGroupDetailService = async (groupId) => {
  const queryUrl = getGroupDetailApiDetails.path + groupId;
  const response = await gateway.get(queryUrl);

  const { data, error } = response.data;

  return { data, error };
};

export default getGroupDetailService;
