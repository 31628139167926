import { Chip } from '@mui/material';
import { orange } from '@mui/material/colors';
import React from 'react';

function IsUpcomingEventTagComponent() {
  return (
    <Chip
      label="upcoming"
      variant="filled"
      style={{ fontWeight: 'bold', backgroundColor: orange[300] }}
      size="small"
    />
  );
}

export default IsUpcomingEventTagComponent;
