import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';

import 'dayjs/locale/en-gb';
import { Box, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import theme from './themes/default.theme';
import NavBarComponent from './components/navBar/navBar';
import FooterComponent from './components/footer/footer';
import LoginPage from './pages/login/login';
import RegisterPage from './pages/register/register';
import {
  AboutPath,
  CareersPath,
  CompleteProfilePath,
  ContributePath,
  EventCreatePath,
  EventDetailsPath,
  ExplorePath,
  FaqPath,
  FeaturesPath,
  ForgetPasswordPath, GroupCreatePath, GroupDetailsPath, GroupListPath, HomePath,
  JoinGroupConfirmationPath, ListPath,
  LoginPath, NotiPath, ProfilePath, PublicProfilePagePath, RecommendPath, RegisterPath,
  RestaurantPagePath, SearchPath,
  UpdateProfilePath,
} from './constants/routes.contants';
import AuthProvider from './middlewares/hooks/auth.hook';
import AboutPage from './pages/about/about';
import ScrollToTop from './middlewares/wrappers/scrollToTop';
import RestaurantPage from './pages/restaurant/restaurant';
import SearchPage from './pages/search/search';
import RequireAuth from './middlewares/wrappers/requireAuth';
import RecommendPage from './pages/recommend/recommend';
import ListPage from './pages/list/list';
import ProfilePage from './pages/profile/profile';
import CompleteProfile from './pages/completeProfile/completeProfile';
import ExplorePage from './pages/explore/explore';
import NotificationsPage from './pages/notifications/notifications';
import FeaturesPage from './pages/features/features';
import ForgetPasswordPage from './pages/forgetPassword/forgetPassword';
import CareersPage from './pages/careers/careers';
import FaqPage from './pages/faq/faq';
import NotFoundPage from './pages/notFound/notFound';
import SnackbarProvider from './middlewares/hooks/snackbar.hook';
import GlobalSnackbar from './middlewares/wrappers/globalSnackbar';
import { AxiosHook } from './middlewares/hooks/axios.hook';
import NotifyErrorProvider from './middlewares/hooks/notifyError.hook';
import NotifyError from './middlewares/wrappers/notifyError';
import TopAlertBannerComponent from './components/topAlertBanner/topAlertBanner';
import PublicProfilePage from './pages/publicProfile/publicProfile';
import UpdateProfilePage from './pages/update/profile';
import ListGroupsPage from './pages/groups/listGroups';
import GroupDetailsPage from './pages/groups/details';
import EventDetailsPage from './pages/events/details';
import CreateEventPage from './pages/events/create';
import CreateGroupPage from './pages/groups/create';
import JoinGroupConfirmationPage from './pages/groups/join';
import NewHomePage from './pages/home/homeNew';
import ContributePage from './pages/contribute/contribute';

function App() {
  return (
    <ScrollToTop>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <NotifyErrorProvider>
          <NotifyError>
            <SnackbarProvider>
              <GlobalSnackbar>
                <AuthProvider>
                  <AxiosHook>
                    <ThemeProvider theme={theme}>
                      <div className="App">
                        <NavBarComponent />
                        <Box mt={{ xs: '90px', md: '100px' }} minHeight="480px">
                          <TopAlertBannerComponent />
                          <Routes>
                            <Route path={HomePath} element={<NewHomePage />} />
                            <Route path={LoginPath} element={<LoginPage />} />
                            <Route path={RegisterPath} element={<RegisterPage />} />
                            <Route path={ForgetPasswordPath} element={<ForgetPasswordPage />} />
                            <Route path={SearchPath} element={<SearchPage />} />
                            <Route path={ExplorePath} element={<ExplorePage />} />
                            <Route path={AboutPath} element={<AboutPage />} />
                            <Route path={FaqPath} element={<FaqPage />} />
                            <Route path={CareersPath} element={<CareersPage />} />
                            <Route path={FeaturesPath} element={<FeaturesPage />} />
                            <Route path={RestaurantPagePath} element={<RestaurantPage />} />
                            <Route path={RecommendPath} element={<RecommendPage />} />
                            <Route path={PublicProfilePagePath} element={<PublicProfilePage />} />
                            <Route
                              path={ProfilePath}
                              element={(
                                <RequireAuth>
                                  <ProfilePage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={UpdateProfilePath}
                              element={(
                                <RequireAuth>
                                  <UpdateProfilePage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={NotiPath}
                              element={(
                                <RequireAuth>
                                  <NotificationsPage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={ListPath}
                              element={(
                                <RequireAuth>
                                  <ListPage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={CompleteProfilePath}
                              element={(
                                <RequireAuth>
                                  <CompleteProfile />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={GroupListPath}
                              element={(
                                <RequireAuth>
                                  <ListGroupsPage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={JoinGroupConfirmationPath}
                              element={(
                                <RequireAuth>
                                  <JoinGroupConfirmationPage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={GroupDetailsPath}
                              element={(
                                <RequireAuth>
                                  <GroupDetailsPage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={GroupCreatePath}
                              element={(
                                <RequireAuth>
                                  <CreateGroupPage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={EventDetailsPath}
                              element={(
                                <RequireAuth>
                                  <EventDetailsPage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={EventCreatePath}
                              element={(
                                <RequireAuth>
                                  <CreateEventPage />
                                </RequireAuth>
                            )}
                            />
                            <Route
                              path={ContributePath}
                              element={(
                                <RequireAuth>
                                  <ContributePage />
                                </RequireAuth>
                            )}
                            />
                            <Route path="*" element={<NotFoundPage />} />
                          </Routes>
                        </Box>
                        <FooterComponent />
                      </div>
                    </ThemeProvider>
                  </AxiosHook>
                </AuthProvider>
              </GlobalSnackbar>
            </SnackbarProvider>
          </NotifyError>
        </NotifyErrorProvider>
      </LocalizationProvider>
    </ScrollToTop>

  );
}

export default App;
