import React from 'react';
import { shape, string } from 'prop-types';
import { Stack, Typography } from '@mui/material';
import ColumnLayout from '../../layouts/column';
import SectionHeader from '../../components/typography/sectionHeader';
import TagComponent from '../../components/tags/tag';

function DetailsTabPanel({ profileDetails }) {
  const { tags } = profileDetails;

  return (
    <ColumnLayout overflow="flex" justifyContent="flex-start">
      <SectionHeader text="bio." textAlign="left" />
      {profileDetails?.bio?.length
          && profileDetails.bio.split('\n').map((val) => (
            <Typography textAlign="left" key={val}>
              {val}
            </Typography>
          ))}
      <SectionHeader text="preferences." textAlign="left" />
      <Typography variant="h6" fontWeight="bold" textAlign="left">tags.</Typography>
      <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
        {tags.length !== 0
            && tags.filter((val) => val.type === 1)
              .map((val) => <TagComponent key={val.id} label={val.name} />)}
      </Stack>
      <Typography variant="h6" fontWeight="bold" textAlign="left">meal type.</Typography>
      <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
        {tags.length !== 0
            && tags.filter((val) => val.type === 3)
              .map((val) => <TagComponent key={val.id} label={val.name} />)}
      </Stack>
      <Typography variant="h6" fontWeight="bold" textAlign="left">location.</Typography>
      <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
        {tags.length !== 0
            && tags.filter((val) => val.type === 4)
              .map((val) => <TagComponent key={val.id} label={val.name} />)}
      </Stack>
    </ColumnLayout>
  );
}

DetailsTabPanel.propTypes = {
  profileDetails: shape({ bio: string }).isRequired,
};

export default DetailsTabPanel;
