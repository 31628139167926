import { loginApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const loginService = async (identifier, password) => {
  const response = await gateway.post(loginApiDetails.path, {
    identifier, password,
  });

  const { data } = response.data;
  const { error } = response.data;

  return { data, error };
};

export default loginService;
