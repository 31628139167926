import {
  Box,
  Button, InputAdornment, TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { string } from 'prop-types';
import { createSearchParams, useNavigate } from 'react-router-dom';
import ColumnLayout from '../../layouts/column';
import RowLayout from '../../layouts/rows';
import { SearchPath } from '../../constants/routes.contants';
import { devlog } from '../../utils/console';

function SearchBarComponent({ value, variant }) {
  const navigate = useNavigate();
  const [inputVal, setInputVal] = useState(value);

  const onSearch = (e) => {
    e.preventDefault();
    if (inputVal.length === 0) {
      return;
    }

    const p = createSearchParams({ q: inputVal }).toString();
    devlog('route to search page with query: ', p.toString());
    navigate(`${SearchPath}?${p}`);
  };
  if (variant === 'single-line') {
    return (
      <Box width="100%">
        <RowLayout spacing="5px" overflow="flex">
          <TextField
            value={inputVal}
            variant="outlined"
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={onSearch}
                    sx={{ borderRadius: '30px' }}
                  >
                    search
                  </Button>
                </InputAdornment>
              ),
              sx: { borderRadius: '30px', height: '50px' },
            }}
            fullWidth
            onChange={(e) => {
              setInputVal(e.target.value);
            }}
          />
        </RowLayout>
      </Box>
    );
  }
  return (
    <Box width="100%">
      <ColumnLayout alignItems="center" justifyContent="center">
        <TextField
          value={inputVal}
          variant="outlined"
          placeholder="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
            sx: { borderRadius: '30px', height: '50px' },
          }}
          fullWidth
          onChange={(e) => {
            setInputVal(e.target.value);
          }}
        />
        <RowLayout>
          <Button variant="contained" onClick={onSearch}>search</Button>
          <Button variant="contained">i feel lucky</Button>
        </RowLayout>
      </ColumnLayout>
    </Box>
  );
}

SearchBarComponent.propTypes = {
  value: string,
  variant: string,
};

SearchBarComponent.defaultProps = {
  value: '',
  variant: 'basic',
};

export default SearchBarComponent;
