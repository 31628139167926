import {
  Alert,
  Button,
  Card, CardContent, Checkbox, Divider, FormControlLabel, TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { string } from 'prop-types';
import ColumnLayout from '../../layouts/column';
import TitleComponent from '../typography/title';
import RowLayout from '../../layouts/rows';
import FormErrorTextComponent from '../typography/errorText';
import {
  CompleteProfilePath, ForgetPasswordPath, HomePath, LoginPath,
} from '../../constants/routes.contants';
import { useAuth } from '../../middlewares/hooks/auth.hook';

function RegisterCardComponent({ referrer }) {
  const navigate = useNavigate();
  const auth = useAuth();

  const [authServerError, setAuthServerError] = useState({ status: false, message: '' });
  const [newsletterSub, setNewsletterSub] = useState(false);

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm();

  const usernameField = register('username', { required: true });
  const emailField = register('email', { required: true });
  const passwordField = register('password', { required: true });

  const onSubmit = async (data) => {
    const {
      success,
      userInfo, message,
    } = await auth.register(
      data.username,
      data.password,
      data.email,
      newsletterSub,
    );

    if (!success) {
      setAuthServerError({ ...authServerError, status: true, message });
      reset();
      return;
    }

    if (!userInfo?.completedProfile) {
      navigate(CompleteProfilePath);
      return;
    }

    if (referrer === '') {
      navigate(HomePath);
    } else {
      navigate(referrer);
    }
  };

  const onLoginClick = (e) => {
    e.preventDefault();
    navigate(LoginPath);
  };

  const onForgetPasswordClick = (e) => {
    e.preventDefault();
    navigate(ForgetPasswordPath);
  };
  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: '300px', maxWidth: '373px', borderColor: '#000000', display: 'block',
      }}
    >
      <CardContent style={{ padding: '10px' }}>
        <ColumnLayout overflow="flex">

          <TitleComponent text="join us." />
          <Divider variant="middle" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <ColumnLayout overflow="flex">
              <TextField
                fullWidth
                variant="outlined"
                placeholder="username"
                type="text"
                InputProps={{
                  sx: { height: '40px' },
                  autoCapitalize: 'nope',
                  autoComplete: 'nope',
                }}
                ref={usernameField.ref}
                name={usernameField.name}
                onChange={usernameField.onChange}
                onBlur={usernameField.onBlur}
              />
              {errors.username && <FormErrorTextComponent text="username this is required" />}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="email"
                type="text"
                InputProps={{
                  sx: { height: '40px' },
                  autoCapitalize: 'nope',
                  autoComplete: 'nope',
                }}
                ref={emailField.ref}
                name={emailField.name}
                onChange={emailField.onChange}
                onBlur={emailField.onBlur}
              />
              {errors.email && <FormErrorTextComponent text="email this is required" />}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="password"
                type="password"
                InputProps={{
                  sx: { height: '40px' },
                  autoCapitalize: 'nope',
                  autoComplete: 'nope',
                }}
                ref={passwordField.ref}
                name={passwordField.name}
                onChange={passwordField.onChange}
                onBlur={passwordField.onBlur}
              />
              {errors.password && <FormErrorTextComponent text="password this is required" />}
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={newsletterSub}
                    onChange={() => { setNewsletterSub(!newsletterSub); }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label="subscribe to newsletter"
              />

              {authServerError.status && (
                <Alert variant="filled" severity="error">
                  {authServerError.message || 'Invalid username or password'}
                </Alert>
              )}
              <ColumnLayout overflow="flex">
                <RowLayout overflow="flex" justifyContent="space-between" alignItems="flex-start">
                  <Button size="small" onClick={onForgetPasswordClick}>forget password?</Button>
                </RowLayout>
                <RowLayout overflow="flex" justifyContent="space-between" alignItems="flex-end">
                  <Button size="small" onClick={onLoginClick}>login</Button>
                  <Button variant="contained" type="submit">register</Button>
                </RowLayout>
              </ColumnLayout>
            </ColumnLayout>

          </form>
        </ColumnLayout>

      </CardContent>
    </Card>
  );
}

RegisterCardComponent.propTypes = {
  referrer: string,
};

RegisterCardComponent.defaultProps = {
  referrer: '',
};

export default RegisterCardComponent;
