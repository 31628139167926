import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Alert,
  Autocomplete,
  Box, Button, MenuItem, TextField, Typography,
} from '@mui/material';
import {
  arrayOf, func, shape, string,
} from 'prop-types';
import { iso31661 } from 'iso-3166';
import { useNavigate } from 'react-router-dom';
import SectionHeader from '../../components/typography/sectionHeader';
import FormErrorTextComponent from '../../components/typography/errorText';
import ColumnLayout from '../../layouts/column';
import createProfileService from '../../services/profile/create';
import { ProfilePath } from '../../constants/routes.contants';
import { devlog } from '../../utils/console';
import searchTagsService from '../../services/search/tags';

function CustomSelect({
  innerRef, name, onChange, onBlur, label, options,
}) {
  return (
    <TextField
      sx={{ width: '100%', textAlign: 'left' }}
      select
      id={label}
      defaultValue="none"
      ref={innerRef}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      InputLabelProps={{ shrink: false }}
    >
      <MenuItem value="none">
        <em>None</em>
      </MenuItem>
      {options.map((val) => (
        <MenuItem key={val.value} value={val.value}>
          {val.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

CustomSelect.propTypes = {
  innerRef: func.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  label: string.isRequired,
  options: arrayOf(shape({
    name: string, value: string,
  })).isRequired,
};

function FormInputLabel({ label, helperText }) {
  return (
    <Box display="flex" textAlign="left" flexDirection="column" width="100%">
      <Typography variant="h6" fontWeight="bold">{label}</Typography>
      {helperText.length >= 0 && (
        <Typography variant="caption">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

FormInputLabel.propTypes = {
  label: string.isRequired,
  helperText: string,
};

FormInputLabel.defaultProps = {
  helperText: '',
};

function CompleteProfile() {
  const navigate = useNavigate();
  const [tagsOption, setTagsOption] = useState([]);
  const [tagInputVal, setTagInputVal] = useState('');
  const [submitError, setSubmitError] = useState({ status: false, message: '' });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = async (data) => {
    devlog(data);
    // TODO: when succeed, proceed to profile page or home page
    // If fail how?
    const { error } = await createProfileService(
      data.name,
      data.bio,
      data.country,
      data.profileImage,
      data.tags,
    );

    if (error) {
      devlog(error);
      setSubmitError({
        ...submitError,
        status: true,
        message: error.message,
      });
      return;
    }

    navigate(ProfilePath);
  };

  const nameField = register('name', { required: true });
  const bioField = register('bio', { required: true });

  const inputChangeHandler = async (event, value) => {
    setTagInputVal(value);
  };

  useEffect(() => {
    const helper = async () => {
      const { data } = await searchTagsService('');
      setTagsOption(data.tags);
    };

    helper();
  }, [setTagsOption]);

  useEffect(() => {
    const throttleTimer = setTimeout(async () => {
      const { data } = await searchTagsService(tagInputVal);
      setTagsOption(data.tags);
      devlog(data);
    }, 500);

    return () => {
      clearTimeout(throttleTimer);
    };
  }, [tagInputVal]);

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ColumnLayout overflow="flex" alignItems="flex-start">
            <SectionHeader text="personal." textAlign="left" />
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">name</Typography>
              <Typography variant="caption">how do i address you?</Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="name"
              type="text"
              InputProps={{
                sx: { height: '40px' },
              }}
              ref={nameField.ref}
              name={nameField.name}
              onChange={nameField.onChange}
              onBlur={nameField.onBlur}
            />
            {errors.username && <FormErrorTextComponent text="name this is required" />}
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">country</Typography>
              <Typography variant="caption">where are you from?</Typography>
            </Box>
            <Controller
              name="country"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    sx={{ width: '100%', textAlign: 'left' }}
                    value={value ? iso31661.find((option) => value === Number(option.numeric))
                      : null}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newVal) => {
                      onChange(newVal ? Number(newVal.numeric) : null);
                    }}
                    options={iso31661}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="country"
                      />
                    )}
                  />
                );
              }}
            />
            {errors.country && <FormErrorTextComponent text="country is required" />}
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">bio</Typography>
              <Typography variant="caption">e.g. what is your favourite bubble tea order?</Typography>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="bio"
              type="text"
              ref={bioField.ref}
              name={bioField.name}
              onChange={bioField.onChange}
              onBlur={bioField.onBlur}
            />
            {errors.bio && <FormErrorTextComponent text="bio is required" />}
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">preferences</Typography>
              <Typography variant="caption">choose your favourite tags</Typography>
            </Box>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <Autocomplete
                    multiple
                    sx={{ width: '100%', textAlign: 'left' }}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newVal) => {
                      devlog(newVal);
                      onChange(newVal.map((val) => val.id));
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={(x) => x}
                    onInputChange={inputChangeHandler}
                    options={tagsOption}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="preferences"
                      />
                    )}
                  />
                );
              }}
            />
            <Box width="100%">
              {submitError.status && (
                <Alert variant="filled" severity="error">
                  {submitError.message || 'Submission Error'}
                </Alert>
              )}
            </Box>
            <Box display="flex" justifyContent="right" width="100%">
              <Button size="large" variant="contained" type="submit">save</Button>
            </Box>
          </ColumnLayout>
        </form>
      </Box>
    </Box>
  );
}

export default CompleteProfile;
