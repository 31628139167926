import React from 'react';
import { Box, Typography } from '@mui/material';
import ColumnLayout from '../../layouts/column';
import SectionHeader from '../../components/typography/sectionHeader';

function FaqPage() {
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <SectionHeader text="FAQ." />
        <ColumnLayout alignItems="center" overflow="flex">
          <Typography>Not much q to a now</Typography>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default FaqPage;
