import {
  Box,
  Button, Card, CardContent, Link, Modal, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  arrayOf, bool, number, string,
} from 'prop-types';
import ColumnLayout from '../../layouts/column';
import RowLayout from '../../layouts/rows';
import RatingStarBarComponent from './ratingStarBar';
import { PublicProfilePathBuilder, RestaurantPathBuilder } from '../../constants/routes.contants';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 10,
};

function ReviewCardComponent({
  name, reviewText, rating, isPersonal,
  restaurantName, restaurantId, images,
}) {
  const [showMore, setShowMore] = useState(false);
  const [openImageModal, setOpenImageModal] = useState({ open: false, link: '' });
  if (reviewText?.length === 0) {
    return null;
  }
  return (
    <Card
      variant="outlined"
      style={{
        width: '100%',
        minWidth: '323px',
        borderColor: '#000000',
      }}
    >
      <CardContent style={{ padding: '5px 10px' }}>
        <ColumnLayout overflow="flex" alignItems="flex-start" justifyContent="flex-start">
          <Typography textAlign="left" fontWeight="bold">
            {isPersonal ? 'your thoughts...'
              : (
                <>
                  <Link href={PublicProfilePathBuilder(name)}>
                    {name}
                  </Link>
                  {' '}
                  says...
                </>
              )}
          </Typography>
          {restaurantName && restaurantId && (
            <Link href={RestaurantPathBuilder(restaurantId)}>
              {restaurantName}
            </Link>
          )}
          <RatingStarBarComponent numStar={rating} />
          <RowLayout overflow="flex">
            <Typography textAlign="left" fontSize="50px" fontWeight="bold" lineHeight="35px">&quot;</Typography>
            <Typography
              textAlign="left"
              sx={{
                overflow: showMore ? '' : 'hidden',
                textOverflow: showMore ? '' : 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: showMore ? '' : '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {reviewText}
            </Typography>
          </RowLayout>
          {reviewText.length > 85 && (
            <Button onClick={() => setShowMore(!showMore)}>
              {showMore ? 'show less' : 'show more'}
            </Button>
          )}
          {images?.length > 0 && (
            <Box
              height="150px"
              width="100%"
              display="flex"
              alignItems="center"
              overflow="scroll"
              columnGap="5px"
            >
                {images.map((val) => (
                  <Box
                    height="100%"
                    display="flex"
                    sx={{ aspectRatio: '1 / 1' }}
                    borderRadius="5px"
                  >
                    <Button
                      sx={{ aspectRatio: '1 / 1', margin: 0, padding: 0 }}
                      width="100%"
                      height="100%"
                      onClick={() => {
                        setOpenImageModal({ open: true, link: val });
                      }}
                    >
                      <img
                        style={{ borderRadius: 'inherit', objectFit: 'cover' }}
                        key={val}
                        src={val}
                        alt="food images"
                        loading="lazy"
                        height="100%"
                        width="100%"
                      />
                    </Button>
                  </Box>
                ))}
            </Box>
          )}
          <Modal
            open={openImageModal.open}
            onClose={() => setOpenImageModal({ open: false })}
          >
            <Box sx={modalStyle}>
              <img
                style={{ borderRadius: 'inherit', objectFit: 'cover' }}
                key={openImageModal.link}
                src={openImageModal.link}
                alt="food images"
                loading="lazy"
                height="100%"
                width="100%"
              />
            </Box>
          </Modal>
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

ReviewCardComponent.propTypes = {
  name: string,
  reviewText: string,
  rating: number,
  isPersonal: bool,
  restaurantName: string,
  restaurantId: number,
  images: arrayOf(string),
};

ReviewCardComponent.defaultProps = {
  name: 'anonymous person',
  reviewText: '',
  rating: 3,
  isPersonal: false,
  restaurantName: '',
  restaurantId: 0,
  images: [],
};

export default ReviewCardComponent;
