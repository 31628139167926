import {
  Button,
  Card, CardContent, CardMedia, Typography,
} from '@mui/material';
import { arrayOf, bool, string } from 'prop-types';
import React from 'react';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { devlog } from '../../utils/console';
import ColumnLayout from '../../layouts/column';
import RowLayout from '../../layouts/rows';
import OpenCloseTagComponent from '../tags/openCloseTag';
import { RestaurantPathBuilder } from '../../constants/routes.contants';
import SaveRestaurantNewComponent from '../save/saveRestaurantNew';

function ExploreRestaurantCardComponent({
  restaurantId,
  restaurantName,
  isSaved,
  restaurantAddress,
  isOpen,
  country,
  tags,
  images,
  image,
}) {
  devlog(
    restaurantId,
    restaurantName,
    isSaved,
    restaurantAddress,
    isOpen,
    country,
    tags,
    images,
    image,
  );

  const navigate = useNavigate();

  const moreInfo = () => {
    const restaurantPath = RestaurantPathBuilder(restaurantId);
    navigate(restaurantPath);
  };

  // return <Typography>ExploreCard</Typography>;
  return (
    <Card
      style={{
        position: 'relative',
        width: '100%',
        minHeight: '350px',
        borderColor: '#000000',
        aspectRatio: '9 / 16',
        display: 'flex',
        margin: '3px 0',
      }}
    >
      <CardMedia
        image={image}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: '100%',
        }}
      />
      <CardContent sx={{
        position: 'relative',
        background: 'linear-gradient(180deg, rgba(255,0,0,0), rgba(0,0,0,1))',
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        padding: '10px',
        '&:last-child': {
          paddingBottom: '10px',
        },
      }}
      >
        <ColumnLayout overflow="flex">
          <RowLayout
            justifyContent="space-between"
            alignItems="flex-start"
            overflow="flex"
          >
            <Typography
              component="div"
              fontWeight="bold"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
              color="white"
              textAlign="left"
              fontSize="18px"
            >
              {restaurantName}
            </Typography>
          </RowLayout>
          <RowLayout overflow="flex">
            <Typography
              color="white"
              fontSize="12px"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}
              fontWeight="bold"
              textAlign="left"
            >
              {restaurantAddress}
            </Typography>
          </RowLayout>
          <RowLayout overflow="flex">
            <OpenCloseTagComponent isOpen={isOpen} />
          </RowLayout>
          <Stack direction="row" overflow="flex" justifyContent="space-between">
            <Button size="small" variant="outlined" color="secondary" onClick={moreInfo}>
              More Info
            </Button>
            <SaveRestaurantNewComponent
              isSaved={isSaved}
              restaurantId={restaurantId}
              size="medium"
              color="secondary"
            />
          </Stack>
        </ColumnLayout>
      </CardContent>

    </Card>
  );
}

ExploreRestaurantCardComponent.propTypes = {
  restaurantId: string.isRequired,
  restaurantName: string.isRequired,
  isSaved: bool.isRequired,
  restaurantAddress: string.isRequired,
  isOpen: bool.isRequired,
  country: string.isRequired,
  tags: arrayOf(string),
  images: arrayOf(string),
  image: string,
};

ExploreRestaurantCardComponent.defaultProps = {
  tags: [],
  images: [],
  image: '',
};

export default ExploreRestaurantCardComponent;
