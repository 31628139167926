import React, {
  Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar,
} from '@mui/material';
import { node } from 'prop-types';
import { useNotifyError } from '../hooks/notifyError.hook';

function NotifyError({ children }) {
  const { notifyErrorState, handleCloseToast, handleCloseModal } = useNotifyError();

  return (
    <>
      {children}
      <Dialog
        open={notifyErrorState.modal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Oops...
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {notifyErrorState.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={notifyErrorState.toast} autoHideDuration={3000} onClose={handleCloseToast}>
        <Alert
          onClose={handleCloseToast}
          severity={notifyErrorState.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {notifyErrorState.message}
        </Alert>
      </Snackbar>
    </>
  );
}

NotifyError.propTypes = {
  children: node.isRequired,
};

export default NotifyError;
