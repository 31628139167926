/* eslint-disable no-nested-ternary */
import {
  Box, Button, CircularProgress, Divider, IconButton, InputAdornment, OutlinedInput, Typography,
} from '@mui/material';
import {
  func, number, oneOfType, shape, string,
} from 'prop-types';
import React, { useState } from 'react';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import searchService from '../../services/search/search';

function ReviewSearchRestaurantResult({ restaurantInfo, setSelectedRestaurant }) {
  const onClickSelect = () => {
    setSelectedRestaurant(restaurantInfo);
  };
  return (
    <Box display="flex" p="5px" flexDirection="row" columnGap="10px" alignItems="center">
      <Box display="flex" flexDirection="column" textAlign="left">
        <Typography fontWeight="bold">{restaurantInfo.name}</Typography>
        <Typography variant="caption" color="gray">{restaurantInfo.address}</Typography>
      </Box>
      <Box marginRight={0} marginLeft="auto">
        <Button variant="contained" size="small" onClick={onClickSelect}>select</Button>
      </Box>
    </Box>
  );
}

ReviewSearchRestaurantResult.propTypes = {
  restaurantInfo: shape({
    id: oneOfType([string, number]),
    name: string,
    address: string,
  }).isRequired,
  setSelectedRestaurant: func.isRequired,
};

function RestaurantSelectedView({ restInfo, onUnselectRestaurant }) {
  return (
    <Box display="flex" alignItems="center" flexDirection="column" p="10px">
      <Box width="100%" display="flex" justifyContent="right">
        <IconButton onClick={onUnselectRestaurant}>
          <Close />
        </IconButton>
      </Box>
      <Typography variant="h5">{restInfo.name}</Typography>
      <Typography>{restInfo.address}</Typography>
    </Box>
  );
}

RestaurantSelectedView.propTypes = {
  restInfo: shape().isRequired,
  onUnselectRestaurant: func.isRequired,
};

function ReviewRestaurantSearchComponent({ setSelectedRestaurantId }) {
  // Search restaurant component
  const [searchRestaurantValue, setSearchRestaurantValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedRestaurantInfo, setSelectedRestaurantInfo] = useState();

  const setSelectedRestaurantWrapper = (restInfo) => {
    setSelectedRestaurantId(restInfo.id);
    setSelectedRestaurantInfo(restInfo);
  };

  const onClickSearchRestaurants = async () => {
    // setIsLoading(true);
    setSelectedRestaurantId();
    setSelectedRestaurantInfo();
    setSearchResult([]);
    const { data } = await searchService(searchRestaurantValue);
    const { restaurantInfos } = data;

    setSearchResult(restaurantInfos);

    setIsLoading(false);
  };

  const onUnselectRestaurant = () => {
    setSelectedRestaurantId();
    setSelectedRestaurantInfo();
  };

  return (
    <>
      <OutlinedInput
        placeholder="restaurant name"
        id="search"
        type="text"
        value={searchRestaurantValue}
        onChange={(e) => {
          setSearchRestaurantValue(e.target.value);
        }}
        sx={{ height: '40px' }}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="search restaurant"
              onClick={onClickSearchRestaurants}
              edge="end"
            >
              <Search />
            </IconButton>
          </InputAdornment>
    )}
      />
      <Box
        maxHeight="200px"
        border="1px solid black"
        borderRadius="5px"
        overflow="scroll"
        display="flex"
        flexDirection="column"
      >
        {isLoading ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            my="20px"
          >
            <CircularProgress />
          </Box>
        ) : selectedRestaurantInfo ? (
          <RestaurantSelectedView
            restInfo={selectedRestaurantInfo}
            onUnselectRestaurant={onUnselectRestaurant}
          />
        )
          : searchResult.map((val) => (
            <>
              <ReviewSearchRestaurantResult
                key={val.id}
                restaurantInfo={val}
                setSelectedRestaurant={setSelectedRestaurantWrapper}
              />
              <Divider />
            </>
          ))}
      </Box>
    </>
  );
}

ReviewRestaurantSearchComponent.propTypes = {
  setSelectedRestaurantId: func.isRequired,
};

export default ReviewRestaurantSearchComponent;
