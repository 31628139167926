import { updateProfileApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const updateProfileService = async (name, bio, country, profileImage, tags) => {
  const response = await gateway.put(updateProfileApiDetails.path, {
    name, bio, profileImage, country, tags,
  });

  const { data, error } = response.data;

  return { data, error };
};

export default updateProfileService;
