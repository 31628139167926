import { Box, Button, Typography } from '@mui/material';
import {
  arrayOf, number, shape, string,
} from 'prop-types';
import React, { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TagComponent from '../tags/tag';
import ColumnLayout from '../../layouts/column';

function TagCompatibilityComponent({ id, value, name }) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <TagComponent label={name} id={id} />
      <Box width="70%" display="flex" flexDirection="row" height="10px" border="1px solid black" p={0} borderRadius="5px">
        <Box height="100%" width={`${value}%`} bgcolor="black" borderRadius="4px" />
        <Box height="100%" width={`${100 - value}%`} bgcolor="white" borderRadius="5px" />
      </Box>
    </Box>
  );
}

TagCompatibilityComponent.propTypes = {
  value: number.isRequired,
  id: number.isRequired,
  name: string.isRequired,
};

function LocationCompatibilityComponent({ id, value, name }) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <TagComponent label={name} id={id} type="secondary" />
      <Box width="70%" display="flex" flexDirection="row" height="10px" border="1px solid black" p={0} borderRadius="5px">
        <Box height="100%" width={`${value}%`} bgcolor="black" borderRadius="4px" />
        <Box height="100%" width={`${100 - value}%`} bgcolor="white" borderRadius="5px" />
      </Box>
    </Box>
  );
}

LocationCompatibilityComponent.propTypes = {
  value: number.isRequired,
  id: number.isRequired,
  name: string.isRequired,
};

// calculate similarity
// similar tags
// if a group have 5 people
// top 5 tags
// top few location
// similar tag count / num people
// sum all and divide by num tags
function VibesBarComponent({ value, tags, locations }) {
  const [showMore, setShowMore] = useState(false);
  const onClickShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <Typography variant="body1" fontWeight="bold" textAlign="left">overall</Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box width="85%" display="flex" flexDirection="row" height="10px" border="1px solid black" p={0} borderRadius="5px">
          <Box height="100%" width={`${value}%`} bgcolor="black" borderRadius="4px" />
          <Box height="100%" width={`${100 - value}%`} bgcolor="white" borderRadius="5px" />
        </Box>
        <Typography variant="body1">{`${value}%`}</Typography>
      </Box>
      {
        showMore && (
        <ColumnLayout overflow="flex">
          <Typography variant="body1" fontWeight="bold" textAlign="left">
            food types
            {tags.length > 1 && ` (top ${tags.length})`}
          </Typography>
          {tags.map((val) => (
            <TagCompatibilityComponent
              id={val.id}
              name={val.name}
              value={val.value}
            />
          ))}
          <Typography variant="body1" fontWeight="bold" textAlign="left">location</Typography>
          {locations.map((val) => (
            <LocationCompatibilityComponent
              id={val.id}
              name={val.name}
              value={val.value}
            />
          ))}
          <Typography variant="caption" textAlign="left">**please complete your profile to be part of the vibe check. also, singapore is very small...</Typography>
        </ColumnLayout>
        )
      }
      <Button size="small" onClick={onClickShowMore} endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}>show more</Button>
    </>
  );
}

VibesBarComponent.propTypes = {
  value: number.isRequired,
  tags: arrayOf(shape({
    id: number,
    name: string,
    value: number,
  })).isRequired,
  locations: arrayOf(shape({
    id: number,
    name: string,
    value: number,
  })).isRequired,
};

export default VibesBarComponent;
