import { moveListApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const moveListService = async (listItemId) => {
  const res = await gateway.post(moveListApiDetails.path, { listItemId });

  return res.data;
};

export default moveListService;
