import { Typography } from '@mui/material';
import React from 'react';

function EventPhotoShareComponent() {
  return (
    <>
      <Typography variant="h5" fontWeight="bold">photos</Typography>
      <Typography>
        photos are so expensiveeeeee....
        give me some time to figure how to do it correctly
      </Typography>
    </>
  );
}

export default EventPhotoShareComponent;
