import {
  Avatar, Card, CardContent, Typography,
} from '@mui/material';
import { string } from 'prop-types';
import React from 'react';
import ColumnLayout from '../../layouts/column';

function WorkerProfileCard({
  name, imgSrc, profileLink, position, quote,
  variant,
}) {
  if (variant === 'mini') {
    return (
      <Card style={{
        width: '140px',
        backgroundColor: '#000000',
        borderColor: '#FFFFFF',
        borderRadius: '5px',
        overflow: 'hidden',
      }}
      >
        <CardContent>
          <ColumnLayout alignItems="center" overflow="flex">
            <Avatar alt={name} src={imgSrc} sx={{ width: '100px', height: '100px' }} />
            <Typography
              href={profileLink}
              padding="5px 0px "
              variant="h6"
              component="a"
              fontWeight="bold"
              color="secondary"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                textDecoration: 'none',
              }}
            >
              {name}
            </Typography>
          </ColumnLayout>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card style={{
      maxWidth: '180px',
      backgroundColor: '#000000',
      borderColor: '#FFFFFF',
      borderRadius: '5px',
      height: '305px',
      overflow: 'hidden',
    }}
    >
      <CardContent>
        <ColumnLayout alignItems="center" overflow="flex">
          <Avatar alt={name} src={imgSrc} sx={{ width: '100px', height: '100px' }} />
          <Typography
            href={profileLink}
            padding="5px 0px "
            variant="h6"
            component="a"
            fontWeight="bold"
            color="secondary"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textDecoration: 'none',
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            align="center"
            fontWeight="bold"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            {position}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            align="center"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
            }}
          >
            &quot;
            {quote}
            &quot;
          </Typography>
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

WorkerProfileCard.propTypes = {
  name: string,
  imgSrc: string,
  profileLink: string,
  position: string,
  quote: string,
  variant: string,
};

WorkerProfileCard.defaultProps = {
  name: 'john doe',
  imgSrc: '',
  profileLink: 'https://www.linkedin.com/in/pinsernfang/',
  position: 'contributor',
  quote: 'food brings people together. if it does not, you probably dont have enough food',
  variant: '',
};

export default WorkerProfileCard;
