import { createEventApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const createBasicEventService = async (name, restaurantId, eventDate, groupId) => {
  const response = await gateway.post(createEventApiDetails.path, {
    name, restaurantId, groupId, eventDate, type: 1,
  });

  const { data, error } = response.data;

  return { data, error };
};

export default createBasicEventService;
