import { getRestaurantReviewsApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getRestaurantReviewsService = async (restaurantId, userId, limit, offset) => {
  const res = await gateway.get(getRestaurantReviewsApiDetails.path, {
    params: {
      restaurantId,
      userId,
      offset,
      limit,
    },
  });

  return res.data;
};

export default getRestaurantReviewsService;
