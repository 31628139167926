import React, { useEffect, useState } from 'react';
import {
  Box, Button, Card, Chip, Container, Divider, Grid, Skeleton, Stack, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import { useAuth } from '../../middlewares/hooks/auth.hook';
import ColumnLayout from '../../layouts/column';
import InfoBarComponent from '../../components/infobar/infoBar';
import SectionHeader from '../../components/typography/sectionHeader';
import HotPickSection from './hotpick.section';
import SearchBarComponent from '../../components/searchBar/searchBar';
import RecentSection from './recent.section';
import recommendRestaurantService from '../../services/restaurants/recommend';
import getRecentService from '../../services/restaurants/recent';
import HeroComponent from '../../components/hero/hero';
import UnauthedInfoBarComponent from '../../components/infobar/unauthedInfoBar';
import ExploreRestaurantCardComponent from '../../components/restaurantCard/exploreCard';
import { RegisterPath } from '../../constants/routes.contants';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'collaboration list',
    description:
        'share the restaurants you want to try with your friends and schedule a time to go try it together',
    imageLight: 'url("/static/images/colab.png")',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'honest reviews',
    description:
        'authentic local reviews that are honest and straight to the point. cut through the noise and prioritize the the reviews from people you trust',
    imageLight: 'url("/static/images/review.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'recommend me',
    description:
        'gone are the days of trying to figure out what to eat. free you mind from the tough choices and let our state of the art recommendation engine make the decision for you',
    imageLight: 'url("/static/images/recommend.png")',
  },
];

function FeaturesComponent() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: '10px', sm: '20px' } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              features
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              here is a brief overview of our key features.
              All geared toward connecting with friends offline and
              discovering your next favorite restaurant.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => {
              const val = `a${index}`;
              return (
                <Chip
                  key={val}
                  label={title}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    borderColor: (theme) => {
                      if (theme.palette.mode === 'light') {
                        return selectedItemIndex === index ? 'primary.light' : '';
                      }
                      return selectedItemIndex === index ? 'primary.light' : '';
                    },
                    '& .MuiChip-label': {
                      color: selectedItemIndex === index ? '#000000' : '',
                      fontWeight: selectedItemIndex === index ? 'bold' : '',
                    },
                  }}
                  size="medium"
                />
              );
            })}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: items[selectedItemIndex].imageLight,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                minHeight: 250,
                backgroundRepeat: 'no-repeat',
                boxShadow: 'none',
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              {/* <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
                href="https://google.com"
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link> */}
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                // key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                      selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    {/* <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      href="https://google.com"
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link> */}
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundImage: items[selectedItemIndex].imageLight,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

function AuthedHomePage() {
  const [hotpick, setHotPick] = useState(null);
  const [recent, setRecent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const recommend = await recommendRestaurantService(1, { page: 'home' }, {});
      const { data } = await getRecentService();

      setHotPick(recommend.data?.restaurantInfos[0]);
      setRecent(data.restaurantInfos);
      setIsLoading(false);
    };

    helper();
  }, []);

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout alignItems="flex-start" overflow="flex">
          <InfoBarComponent />
          <SectionHeader text="hot pick." />
          <HotPickSection hotpick={hotpick} isLoading={isLoading} />

          <SectionHeader text="look up." />
          <SearchBarComponent variant="single-line" />

          <SectionHeader text="explore." />
          <RecentSection recent={recent} isLoading={isLoading} />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

function UnauthedHomePage() {
  const navigate = useNavigate();
  const [hotpick, setHotPick] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const recommend = await recommendRestaurantService(5, { page: 'home' }, {});

      setHotPick(recommend.data?.restaurantInfos);
      setIsLoading(false);
    };

    helper();
  }, []);

  const onClickRegister = () => {
    navigate(RegisterPath);
  };
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout alignItems="center" overflow="flex" spacing="50px">
          <HeroComponent />
          <UnauthedInfoBarComponent />
          <FeaturesComponent />
          <Box
            display="flex"
            border={0}
            borderRadius="5px"
            width="100%"
            height="100px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            rowGap="5px"
          >
            <Typography variant="h5" mb="20px">what are you waiting for?</Typography>
            <Button sx={{ width: '50%' }} variant="contained" onClick={onClickRegister}>register now</Button>
          </Box>
          <Divider variant="fullWidth" width="100%" />
          <Box width="100%">
            <Typography
              component="h2"
              variant="h4"
              color="text.primary"
              fontWeight="bold"
              mb="20px"
            >
              explore.
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              overflow="scroll"
              columnGap="10px"
              width="100%"
            >
              {isLoading ? [1, 2, 3, 4].map((val) => (
                <Skeleton
                  key={val}
                  variant="rounded"
                  width="210px"
                  height="350px"
                  sx={{ marginBottom: '4px', flexShrink: 0 }}
                />
              )) : hotpick.map((val) => (
                <Box
                  key={val.id}
                  width="60%"
                >
                  <ExploreRestaurantCardComponent
                    restaurantId={val.id}
                    restaurantName={val.name}
                    isSaved={val.isSaved}
                    restaurantAddress={val.address}
                    isOpen={val.isOpen}
                    country={val.country}
                    tags={val.tags}
                    images={val.images}
                    image={val.headerImage}
                  />
                </Box>
              ))}
              <Box
                width="60%"
              >
                <Card
                  style={{
                    position: 'relative',
                    width: '100%',
                    minHeight: '350px',
                    borderColor: '#000000',
                    aspectRatio: '9 / 16',
                    display: 'flex',
                    margin: '3px 0',
                    border: '1px solid black',
                  }}
                >
                  <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Typography variant="h6" fontWeight="bold">want to explore more?</Typography>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate(RegisterPath);
                      }}
                    >
                      join now
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Box>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

function NewHomePage() {
  const { authed } = useAuth();
  return authed ? <AuthedHomePage /> : <UnauthedHomePage />;
}

export default NewHomePage;
