import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { node } from 'prop-types';
import { useCustomSnackbar } from '../hooks/snackbar.hook';

function GlobalSnackbar({ children }) {
  const { snackbarState, handleCloseSnackbar } = useCustomSnackbar();
  return (
    <>
      {children}
      <Snackbar open={snackbarState.open} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarState.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </>
  );
}

GlobalSnackbar.propTypes = {
  children: node.isRequired,
};

export default GlobalSnackbar;
