import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RecommendPath, RegisterPath } from '../../constants/routes.contants';

function UnauthedInfoBarComponent() {
  const navigate = useNavigate();
  const onClickRegister = () => {
    navigate(RegisterPath);
  };
  const onClickReco = () => {
    navigate(RecommendPath);
  };

  return (
    <Box display="flex" border={0} borderRadius="5px" width="100%" height="40px" justifyContent="space-between">

      <Button sx={{ width: '49%' }} variant="contained" onClick={onClickRegister}>register</Button>
      <Button sx={{ width: '49%' }} variant="outlined" onClick={onClickReco}>recommend me</Button>

    </Box>
  );
}

export default UnauthedInfoBarComponent;
