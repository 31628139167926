import { node } from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop({ children }) {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

ScrollToTop.propTypes = {
  children: node.isRequired,
};

export default ScrollToTop;
