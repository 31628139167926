import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography, Button, Box, Skeleton,
} from '@mui/material';
import { GroupDetailsPathBuilder, GroupKeyParam, GroupListPath } from '../../constants/routes.contants';
import ColumnLayout from '../../layouts/column';
import getJoinGroupKeyDetailsService from '../../services/groups/getJoinGroupKeyDetails';
import joinGroupService from '../../services/groups/joinGroup';

function JoinGroupConfirmationSkeleton() {
  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout overflow="flex" alignItems="center">
          <Typography variant="h5" color="gray">you are about to join:</Typography>
          <Skeleton variant="text" sx={{ fontSize: '56px' }} width="80%" />
          <Box
            width="80%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <Typography variant="h6">
              invited by:
            </Typography>
            <Skeleton variant="text" sx={{ fontSize: '48px' }} width="60%" />
          </Box>
          <Skeleton variant="rounded" width="50%" height="40px" />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

function JoinGroupConfirmationPage() {
  const navigate = useNavigate();
  const params = useParams();
  const groupKey = params[GroupKeyParam];

  const [isLoading, setIsLoading] = useState(false);
  const [keyData, setKeyData] = useState({});

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const { data } = await getJoinGroupKeyDetailsService(groupKey);

      if (!data) {
        navigate(GroupListPath);
      } else {
        setKeyData(data);
      }

      setIsLoading(false);
    };

    helper();
  }, []);

  // use group key to get confirmation from BE

  const onClickConfirm = async () => {
    const { data } = await joinGroupService(groupKey);

    const newGroupId = data?.groupInfo?.id;
    navigate(GroupDetailsPathBuilder(newGroupId));
  };
  return isLoading ? <JoinGroupConfirmationSkeleton /> : (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout overflow="flex" alignItems="center">
          <Typography variant="h5" color="gray">you are about to join:</Typography>
          <Typography variant="h5">{keyData?.groupName}</Typography>
          <Typography variant="h6">
            invited by:
            {` ${keyData?.referrer}`}
          </Typography>
          <Box width="50%" display="flex" justifyContent="center">
            <Button variant="contained" onClick={onClickConfirm} sx={{ width: '100%' }}>confirm</Button>
          </Box>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default JoinGroupConfirmationPage;
