import { devlog } from '../utils/console';

export const loginApiDetails = {
  path: '/api/v1/login',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "identifier": string,
        "password": string,
    }
    */

    const { identifier } = request;
    if (!identifier) {
      devlog('identifier not defined');
      return false;
    }

    const { password } = request;

    if (!password) {
      devlog('password not defined');
      return false;
    }

    return true;
  },
};

export const registerApiDetails = {
  path: '/api/v1/register',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "username": string,
        "email": string,
        "password": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const subscribeNewsletterApiDetails = {
  path: '/api/v1/newsletter/subscribe',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "email": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const saveRestaurantApiDetails = {
  path: '/api/v1/lists/save-restaurant',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "restaurantId": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const unsaveRestaurantApiDetails = {
  path: '/api/v1/lists/unsave-restaurant',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "restaurantId": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const searchTagsApiDetails = {
  path: '/api/v1/tags/search',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "query": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const searchApiDetails = {
  path: '/api/v1/search',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "query": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const getUploadLinkApiDetails = {
  path: '/api/v1/upload-link',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
    }
    */
    devlog(request);
    return true;
  },
};

export const recommendApiDetails = {
  path: '/api/v1/restaurants/recommend',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "count": string, limit to 10
        "referrer": string, key value referrer split by ;
    }

    referrer example:
    restaurantId=abc123;tagname=singapore;page=home
    */
    devlog(request);
    return true;
  },
};

export const getRecentApiDetails = {
  path: '/api/v1/restaurants/recent',
  method: 'get',
};

export const getRestaurantDetailsApiDetails = {
  path: '/api/v1/restaurants/p/',
  method: 'get',
  requestValidation: (request) => {
    /*
    {
        "restaurantId": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const getPublicProfileApiDetails = {
  path: '/api/v1/profile/p/',
  method: 'get',
  requestValidation: (request) => {
    /*
    {
        "username": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const getListInfoApiDetails = {
  path: '/api/v1/lists/details',
  method: 'get',
};

export const moveListApiDetails = {
  path: '/api/v1/lists/move',
  method: 'post',
  requestValidation: (request) => {
    /*
    we follow a pipeline pattern
    {
        "restaurantId": string,
    }
    */
    devlog(request);
    return true;
  },
};

export const postReviewApiDetails = {
  path: '/api/v1/reviews/c/restaurants',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "restaurantId": string,
        "rating": number,
        "description": string
    }
    */
    devlog(request);
    return true;
  },
};

export const getRestaurantReviewsApiDetails = {
  path: '/api/v1/reviews/r/restaurants',
  method: 'get',
};

export const getProfileInfoApiDetails = {
  path: '/api/v1/profile/personal',
  method: 'get',
};

export const createProfileApiDetails = {
  path: '/api/v1/profile/create',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "name": string,
        "profileImage": number,
        "country": string,
        "bio": string
    }
    */
    devlog(request);
    return true;
  },
};

export const updateProfileApiDetails = {
  path: '/api/v1/profile/update',
  method: 'put',
  requestValidation: (request) => {
    /*
    {
        "name": string,
        "profileImage": number,
        "country": string,
        "bio": string
        "tags": []number
    }
    */
    devlog(request);
    return true;
  },
};

export const getGroupsListApiDetails = {
  path: '/api/v1/groups/list',
  method: 'get',
};

export const getGroupDetailApiDetails = {
  path: '/api/v1/groups/d/',
  method: 'get',
};

export const createGroupApiDetails = {
  path: '/api/v1/groups/create',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "name": string
    }
    */
    devlog(request);
    return true;
  },
};

export const generateJoinGroupKeyApiDetails = {
  path: '/api/v1/groups/j/gen-key',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "groupId": int
    }
    */
    devlog(request);
    return true;
  },
};

export const getJoinGroupKeyDetailsApiDetails = {
  path: '/api/v1/groups/j/key-confirmation',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "key": string
    }
    */
    devlog(request);
    return true;
  },
};

export const joinGroupApiDetails = {
  path: '/api/v1/groups/join',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "key": string
    }
    */
    devlog(request);
    return true;
  },
};

export const leaveGroupApiDetails = {
  path: '/api/v1/groups/leave',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "groupId": number
    }
    */
    devlog(request);
    return true;
  },
};

export const createEventApiDetails = {
  path: '/api/v1/events/create',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "name": string,
        "type": number,
        "restaurantId": number,
        "eventDate": number,
        "groupId": number
    }
    */
    devlog(request);
    return true;
  },
};

export const getEventDetailsApiDetails = {
  path: '/api/v1/events/d/',
  method: 'get',
};

export const getEventsListApiDetails = {
  path: '/api/v1/events/list',
  method: 'get',
};

export const createContributeLinkApiDetails = {
  path: '/api/v1/contribute/link',
  method: 'post',
  requestValidation: (request) => {
    /*
    {
        "link": string,
    }
    */
    devlog(request);
    return true;
  },
};
