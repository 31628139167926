import { getUploadLinkApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getUploadLinkService = async () => {
  const response = await gateway.post(getUploadLinkApiDetails.path, {});

  const { data, error } = response.data;

  return { data, error };
};

export default getUploadLinkService;
