import { Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { string } from 'prop-types';
import React from 'react';

function FormErrorTextComponent({ text }) {
  return <Typography variant="body2" color={red[500]}>{text}</Typography>;
}

FormErrorTextComponent.propTypes = {
  text: string.isRequired,
};

export default FormErrorTextComponent;
