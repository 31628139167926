import { generateJoinGroupKeyApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const generateJoinGroupKeyService = async (groupId) => {
  const response = await gateway.post(generateJoinGroupKeyApiDetails.path, {
    groupId,
  });

  const { data, error } = response.data;

  return { data, error };
};

export default generateJoinGroupKeyService;
