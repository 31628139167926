import { recommendApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

// referrer is a key value object in params

function referrerBuilder(referrer) {
  if (!referrer || Object.keys(referrer) === 0) {
    return '';
  }

  const keys = Object.keys(referrer);
  const refConvert = keys.map((val) => `${val}=${referrer[val]}`);

  return `${refConvert.join(';')};`;
}

function paramsBuilder(params) {
  if (!params || Object.keys(params) === 0) {
    return '';
  }

  const keys = Object.keys(params);
  const refConvert = keys.map((val) => `${val}=${params[val]}`);

  return `${refConvert.join(';')};`;
}

const recommendRestaurantService = async (count, referrer, params) => {
  const response = await gateway.post(recommendApiDetails.path, {
    count,
    referrer: referrerBuilder(referrer),
    params: paramsBuilder(params),

  });

  return response.data;
};

export default recommendRestaurantService;
