import { Button, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React from 'react';
import { string } from 'prop-types';
import { GetUserId } from '../../utils/localStorage';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';
import { devlog } from '../../utils/console';

const titleTextFormatter = {
  restaurant: {
    titleFormatter: (text) => `${text}.`,
    textFormatter: () => 'I think you will really like this place!',
  },
};

function ShareLinkComponent({
  url, type, name, variant, size,
}) {
  const { notify } = useCustomSnackbar();
  const userId = GetUserId();
  let finalUrl = url;
  if (userId) {
    finalUrl = `${finalUrl}?u=${userId}`;
  }

  const onClickShare = async () => {
    const supportedType = titleTextFormatter[type];
    if (!supportedType || !navigator.share) {
      navigator.clipboard.writeText(finalUrl);
      notify('copied link to clipboard');
      return;
    }

    const shareData = {
      title: supportedType.titleFormatter(name),
      text: supportedType.textFormatter(),
      url: finalUrl,
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      devlog(err);
    }
  };

  if (variant === 'text') {
    return (
      <Button
        aria-label="share"
        variant="contained"
        onClick={onClickShare}
        size={size}
      >
        share
      </Button>
    );
  }

  return (
    <IconButton
      aria-label="share"
      color="primary"
      onClick={onClickShare}
      size={size}
    >
      <SendIcon fontSize="large" />
    </IconButton>
  );
}

ShareLinkComponent.propTypes = {
  url: string,
  type: string.isRequired,
  name: string,
  variant: string,
  size: string,
};

ShareLinkComponent.defaultProps = {
  url: process.env.REACT_APP_FE_URL,
  name: '',
  variant: 'default',
  size: 'large',
};

export default ShareLinkComponent;
