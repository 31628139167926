import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ColumnLayout from '../../layouts/column';
import SectionHeader from '../../components/typography/sectionHeader';
import { HomePath } from '../../constants/routes.contants';

function NotFoundPage() {
  const navigate = useNavigate();

  const onClickGoHome = () => {
    navigate(HomePath);
  };
  return (
    <Box justifyContent="center" display="flex" minHeight="400px" alignItems="center">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <SectionHeader text="page not found." />
        <ColumnLayout alignItems="center" overflow="flex">
          <Button variant="contained" onClick={onClickGoHome}>Go Home</Button>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default NotFoundPage;
