import {
  Box, Button, Grid, Skeleton, Tab, Tabs, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  shape, string, node, number,
  bool,
} from 'prop-types';
import SearchBarComponent from '../../components/searchBar/searchBar';
import RestaurantCardComponent from '../../components/restaurantCard/restaurantCard';
import ColumnLayout from '../../layouts/column';
import searchService from '../../services/search/search';
import WorkerProfileCard from '../../components/workerProfileCard/workerProfileCard';
import { ContributePath } from '../../constants/routes.contants';

function SearchPage() {
  const [tabsValue, setTabsValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');

  const [searchResultLoading, setSearchResultLoading] = useState(false);
  const [searchRestaurantResult, setSearchRestaurantResult] = useState([]);
  const [searchProfileResult, setSearchProfileResult] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);

  useEffect(() => {
    if (!query || query.length === 0) {
      return;
    }
    const helper = async () => {
      setSearchResultLoading(true);
      const { data } = await searchService(query);
      const { restaurantInfos, profileInfos } = data;

      if (restaurantInfos.length === 0) {
        setNoResultsFound(true);
      }

      setSearchRestaurantResult(restaurantInfos);
      setSearchProfileResult(profileInfos);
      setSearchResultLoading(false);
    };

    helper();
  }, [query]);

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout alignItems="flex-start" overflow="flex">
          <SearchBarComponent value={query || ''} variant="single-line" />
          <Typography>
            {(query && query.length !== 0) && `search result: ${query}`}
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabsValue}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="restaurants" />
                <Tab label="friends" />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabsValue} index={0}>
              <RestaurantsTabPanel
                restaurantInfos={searchRestaurantResult}
                isLoading={searchResultLoading}
                noResultsFound={noResultsFound}
                query={query}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabsValue} index={1}>
              <ProfileTabPanel
                profileInfos={searchProfileResult}
                isLoading={searchResultLoading}
                query={query}
              />
            </CustomTabPanel>
          </Box>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

function RestaurantsTabPanel({
  restaurantInfos, isLoading, noResultsFound, query,
}) {
  const navigate = useNavigate();
  if (noResultsFound) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          {`No result for ${query}`}
        </Typography>
        <Box
          minHeight="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt="20px"
          width="80%"
          rowGap="10px"
        >
          <Typography>
            cannot find what you are looking for? provide us the link and we will add it!
          </Typography>
          <Button variant="contained" onClick={() => { navigate(ContributePath); }}>contribute?</Button>
        </Box>
      </Box>
    );
  }
  return (
    <ColumnLayout overflow="flex" alignItems="center">
      {isLoading ? [0, 1, 2, 3, 4].map((val) => <Skeleton key={val} variant="rounded" width="100%" height="222px" />)
        : restaurantInfos.map((val) => (
          <RestaurantCardComponent
            key={val.id}
            restaurantName={val.name}
            restaurantId={val.id}
            restaurantAddress={val.address}
            isSaved={val.isSaved}
            isOpen={val.isOpen}
            tags={val.tags}
            country={val.country}
            images={val.images}
          />
        ))}
    </ColumnLayout>
  );
}

RestaurantsTabPanel.propTypes = {
  isLoading: bool,
  restaurantInfos: shape({ bio: string }).isRequired,
  query: string.isRequired,
  noResultsFound: bool,
};

RestaurantsTabPanel.defaultProps = {
  isLoading: false,
  noResultsFound: true,
};

function ProfileTabPanel({
  profileInfos, isLoading, query,
}) {
  if (!profileInfos || profileInfos.length === 0) {
    return (
      <Typography>
        {`No result for ${query}`}
      </Typography>
    );
  }
  return (
    <ColumnLayout overflow="flex" justifyContent="flex-start">
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          rowSpacing={1}
        >
          {isLoading ? [0, 1, 2, 3, 4].map((val) => (
            <Grid item key={val.name} width="49%">
              <Skeleton
                key={val}
                variant="rounded"
                width="100%"
                height="222px"
              />
            </Grid>
          ))
            : profileInfos.map((val) => (
              <Grid item key={val.name} width="49%">
                <WorkerProfileCard
                  key={val.id}
                  name={val.username}
                  imgSrc={val.profileImage}
                  profileLink={`/profile/${val.username}`}
                  position="foodie"
                  quote={val.bio}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </ColumnLayout>
  );
}

ProfileTabPanel.propTypes = {
  isLoading: bool,
  profileInfos: shape({ bio: string }).isRequired,
  query: string.isRequired,
};

ProfileTabPanel.defaultProps = {
  isLoading: false,
};

function CustomTabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ py: 1, px: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: number.isRequired,
};

export default SearchPage;
