import {
  Box, Tab, Tabs, Typography,
} from '@mui/material';
import React from 'react';
import { node, number } from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import ColumnLayout from '../../layouts/column';
import InterestedSection from './interested.section';
import DoneSection from './done.section';

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ py: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: number.isRequired,
};

function ListPage() {
  const [searchParams] = useSearchParams();
  const tab = parseInt(searchParams.get('tab'), 10);
  let defaultTab = 0;
  if (tab && tab < 2) {
    defaultTab = tab;
  }
  const [tabValue, setTabValue] = React.useState(defaultTab);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" minWidth="350px" maxWidth="550px">
        <ColumnLayout overflow="flex" alignItems="center">
          <Box
            width="100%"
            minHeight="150px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            border={1}
            borderRadius="5px"
          >
            <ColumnLayout>
              {
              tabValue === 0 ? (
                <>
                  <Typography variant="h5">What are you waiting for?</Typography>
                  <Typography variant="h5">Time to try something new</Typography>
                </>
              ) : (
                <>
                  <Typography variant="h5">Did you like it?</Typography>
                  <Typography variant="h5">Recommend to a friend</Typography>
                </>
              )
            }

            </ColumnLayout>
          </Box>
          <Box width="100%" minHeight="200px" display="flex" alignItems="left" flexDirection="column">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                <Tab label="interested" />
                <Tab label="done" />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <InterestedSection tabValue={tabValue} index={0} />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <DoneSection tabValue={tabValue} index={1} />
            </CustomTabPanel>
          </Box>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default ListPage;
