import React, { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { bool, number, string } from 'prop-types';
import {
  Alert, CircularProgress, Divider, IconButton, Modal, Snackbar, Typography,
} from '@mui/material';
import Bookmark from '@mui/icons-material/Bookmark';
import BookmarkBorder from '@mui/icons-material/BookmarkBorder';
import { Box } from '@mui/system';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import Add from '@mui/icons-material/Add';
import { useAuth } from '../../middlewares/hooks/auth.hook';
import { GroupDetailsPathBuilder, LoginPath } from '../../constants/routes.contants';
import RowLayout from '../../layouts/rows';
import SectionHeader from '../typography/sectionHeader';
import getGroupsListService from '../../services/groups/getGroupsList';
import saveRestaurantService from '../../services/restaurants/save';
import { devlog } from '../../utils/console';
import unsaveRestaurantService from '../../services/restaurants/unsave';

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  py: 1,
  px: 3,
  maxWidth: '500px',
};

function SaveRestaurantNewComponent({
  isSaved, restaurantId, size, color,
}) {
  const { authed } = useAuth();
  const navigate = useNavigate();
  const [saveState, setSaveState] = useState(isSaved);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: 'Saved',
    severity: 'success',
  });

  const [openSaveChoiceModal, setOpenSaveChoiceModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [saveGroup, setSaveGroup] = useState({});

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const onClickSave = async () => {
    if (!authed) {
      navigate(LoginPath);
    }

    setIsLoading(true);
    setOpenSaveChoiceModal(true);
    const { data } = await getGroupsListService(0, 100, restaurantId);

    setGroupList(data?.groupInfos || []);
    setSaveGroup(data?.groupRestaurantInfo || {});
    setIsLoading(false);
  };

  const handleCloseSaveChoiceModal = () => {
    setOpenSaveChoiceModal(false);
  };

  const isGroupIdSaved = (groupId) => saveGroup[groupId];

  const onClickAddGroup = (groupId) => async () => {
    let res;
    try {
      res = await saveRestaurantService(restaurantId, groupId);
    } catch (err) {
      devlog(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'failed to save',
        severity: 'error',
      });
      return;
    }

    setSnackbarState({
      ...snackbarState,
      open: true,
      message: res?.message || 'saved',
    });

    setSaveGroup({ ...saveGroup, [groupId]: true });
  };

  const onClickRemoveGroup = (groupId) => async () => {
    let res;
    try {
      res = await unsaveRestaurantService(restaurantId, groupId);
    } catch (err) {
      devlog(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'failed to unsave',
        severity: 'error',
      });
      return;
    }

    setSnackbarState({
      ...snackbarState,
      open: true,
      message: res?.message || 'unsaved',
    });

    setSaveGroup({ ...saveGroup, [groupId]: false });
  };

  const onClickAdd = async () => {
    if (!authed) {
      navigate(LoginPath);
    }

    if (saveState) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'already saved',
      });
      return;
    }

    let res;
    try {
      res = await saveRestaurantService(restaurantId);
    } catch (err) {
      devlog(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'failed to save',
        severity: 'error',
      });
      return;
    }

    setSnackbarState({
      ...snackbarState,
      open: true,
      message: res?.message || 'saved',
    });

    setSaveState(true);
  };

  const onClickRemove = async () => {
    if (!authed) {
      navigate(LoginPath);
    }

    if (!saveState) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'not saved',
      });
      return;
    }

    let res;
    try {
      res = await unsaveRestaurantService(restaurantId);
    } catch (err) {
      devlog(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'failed to unsave',
        severity: 'error',
      });
      return;
    }

    setSnackbarState({
      ...snackbarState,
      open: true,
      message: res?.message || 'unsaved',
    });

    setSaveState(false);
  };

  return (
    <>
      <IconButton
        aria-label="save"
        size={size}
        style={{ padding: '5px', margin: 0, borderRadius: '5px' }}
        onClick={onClickSave}
        color={color}
      >
        {saveState ? (
          <Bookmark
            fontSize={size}
            color={color}
          />
        ) : (
          <BookmarkBorder
            fontSize={size}
            color={color}
          />
        )}
      </IconButton>
      <Modal
        open={openSaveChoiceModal}
        onClose={handleCloseSaveChoiceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <RowLayout justifyContent="space-between">
            <SectionHeader text="save." />
            <IconButton onClick={handleCloseSaveChoiceModal}>
              <Close />
            </IconButton>
          </RowLayout>
          <Box my="20px">
            <Typography>save to where?</Typography>
          </Box>
          <Box
            display="flex"
            px="10px"
            flexDirection="row"
            columnGap="10px"
            border={1}
            borderRadius="5px"
          >
            <Box display="flex" flexDirection="column" textAlign="left" justifyContent="center">
              <Typography fontWeight="bold">personal list</Typography>
            </Box>
            <Box marginRight={0} marginLeft="auto">
              {saveState
                ? (
                  <IconButton onClick={onClickRemove}>
                    <Done />
                  </IconButton>
                )
                : (
                  <IconButton onClick={onClickAdd}>
                    <Add />
                  </IconButton>
                )}
            </Box>
          </Box>
          <Box my="20px">
            <Typography>group</Typography>
            <Box border={1} borderRadius="5px" minHeight="100px" maxHeight="200px">
              {isLoading ? (
                <Box
                  width="100%"
                  height="100px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              )
                : groupList?.length !== 0 && groupList.map((val) => (
                  <>
                    <Box
                      display="flex"
                      px="10px"
                      flexDirection="row"
                      columnGap="10px"
                      key={val.id}
                    >
                      <Box display="flex" flexDirection="column" textAlign="left" justifyContent="center">
                        <Typography
                          fontWeight="bold"
                          onClick={() => {
                            const p = createSearchParams({ t: 1 }).toString();
                            navigate(`${GroupDetailsPathBuilder(val.id)}?${p}`);
                          }}
                        >
                          {val.name}
                        </Typography>
                      </Box>
                      <Box marginRight={0} marginLeft="auto">
                        {isGroupIdSaved(val.id)
                          ? (
                            <IconButton onClick={onClickRemoveGroup(val.id)}>
                              <Done />
                            </IconButton>
                          )
                          : (
                            <IconButton onClick={onClickAddGroup(val.id)}>
                              <Add />
                            </IconButton>
                          )}
                      </Box>
                    </Box>
                    <Divider />
                  </>
                ))}
            </Box>
          </Box>
        </Box>
      </Modal>
      <Snackbar open={snackbarState.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbarState.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </>
  );
}

SaveRestaurantNewComponent.propTypes = {
  isSaved: bool.isRequired,
  restaurantId: number.isRequired,
  size: string,
  color: string,
};

SaveRestaurantNewComponent.defaultProps = {
  size: 'large',
  color: 'primary',
};

export default SaveRestaurantNewComponent;
