import React, { useState } from 'react';
import {
  Box, Button, IconButton, Modal,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import {
  bool, number, oneOfType, string,
} from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';
import RowLayout from '../../layouts/rows';
import SectionHeader from '../typography/sectionHeader';
import ReviewFormComponent from '../review/reviewForm';
import { useAuth } from '../../middlewares/hooks/auth.hook';
import { LoginPath } from '../../constants/routes.contants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  py: 1,
  px: 3,
  maxWidth: '500px',
};

function CompletedButtonComponent({ restaurantId, hasPersonalReview }) {
  const { notify } = useCustomSnackbar();
  const { authed } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const handleOpen = () => {
    if (hasPersonalReview) {
      notify('you already reviewed');
      return;
    }
    setOpenReviewModal(true);
  };
  const handleClose = () => {
    setOpenReviewModal(false);
  };

  const handleComplete = async () => {
    handleClose();
    // but should it be done?
    notify('thank you for your review');
  };

  const handleOpenModal = () => {
    if (!authed) {
      const pathName = location.pathname;
      const queries = location.search ? location.search : '';

      const fullPath = pathName + queries;
      navigate(LoginPath, { state: { path: fullPath } });
    }
    handleOpen();
  };

  return (
    <>
      <IconButton
        aria-label="completed"
        color="primary"
        size="large"
        onClick={handleOpenModal}
      >
        <DoneIcon fontSize="large" />
      </IconButton>
      <Modal
        open={openReviewModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <RowLayout justifyContent="space-between">
            <SectionHeader text="review." />
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </RowLayout>
          <ReviewFormComponent
            submitReviewHandler={handleComplete}
            restaurantId={restaurantId}
          />
          <RowLayout justifyContent="space-between">
            <Button size="small" onClick={handleClose}>cancel</Button>
          </RowLayout>
        </Box>
      </Modal>
    </>
  );
}

CompletedButtonComponent.propTypes = {
  restaurantId: oneOfType([number, string]).isRequired,
  hasPersonalReview: bool,
};

CompletedButtonComponent.defaultProps = {
  hasPersonalReview: false,
};

export default CompletedButtonComponent;
