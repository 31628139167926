import { node } from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const notifyErrorContext = createContext({
  toast: false,
  modal: false,
  message: '',
  severity: 'error',
});

const notifyErrorHookInit = () => {
  const [notifyErrorState, setNotifyErrorState] = useState({
    toast: false,
    modal: false,
    message: 'Error Occurred',
    severity: 'error',
  });

  const notifyModal = (message) => {
    setNotifyErrorState({
      ...notifyErrorState,
      modal: true,
      message,
    });
  };

  const notifyToast = (message) => {
    setNotifyErrorState({
      ...notifyErrorState,
      toast: true,
      message,
    });
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifyErrorState({ ...notifyErrorState, toast: false });
  };

  const handleCloseModal = () => {
    setNotifyErrorState({ ...notifyErrorState, modal: false });
  };

  return {
    notifyToast, notifyModal, handleCloseModal, handleCloseToast, notifyErrorState,
  };
};

function NotifyErrorProvider({ children }) {
  const snackbar = notifyErrorHookInit();
  return (
    <notifyErrorContext.Provider value={snackbar}>
      {children}
    </notifyErrorContext.Provider>
  );
}

NotifyErrorProvider.propTypes = {
  children: node.isRequired,
};

export default NotifyErrorProvider;

export function useNotifyError() {
  return useContext(notifyErrorContext);
}
