import React from 'react';

import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { node, string } from 'prop-types';
import TitleComponent from '../../components/typography/title';
import SectionHeader from '../../components/typography/sectionHeader';
import WorkerProfileCard from '../../components/workerProfileCard/workerProfileCard';
import ColumnLayout from '../../layouts/column';
import { CareersPath, FeaturesPath } from '../../constants/routes.contants';
import RowLayout from '../../layouts/rows';
import WorkerJoinCard from '../../components/workerProfileCard/workerJoinCard';

const team = [{
  name: 'johnny eye',
  imgSrc: '',
  profileLink: 'https://www.linkedin.com/in/pinsernfang/',
  position: 'design lead',
  quote: 'beauty is in the eye of the beholder. behold my eyes',
}, {
  name: 'steve j',
  imgSrc: '',
  profileLink: 'https://www.linkedin.com/in/pinsernfang/',
  position: 'dev lead',
  quote: 'code makes the world go round',
}, {
  name: 'jane tan',
  imgSrc: '',
  profileLink: 'https://www.linkedin.com/in/pinsernfang/',
  position: 'product manager',
  quote: 'the customer is always right. if they are wrong, they are probably not your customer',
}, {
  name: 'karyn tan',
  imgSrc: '',
  profileLink: 'https://www.linkedin.com/in/pinsernfang/',
  position: 'frontend dev',
  quote: 'got this job because i could center a div',
},
{
  name: 'jerry wong',
  imgSrc: '',
  profileLink: 'https://www.linkedin.com/in/pinsernfang/',
  position: 'backend dev',
  quote: 'reverse a binary tree and the turn in side ways',
}];

function PageSection({ children, minHeight }) {
  return (
    <Box
      maxWidth="300px"
      minHeight={minHeight}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {children}
    </Box>
  );
}

PageSection.propTypes = {
  children: node.isRequired,
  minHeight: string,
};

PageSection.defaultProps = {
  minHeight: '200px',
};

function AboutPage() {
  const navigate = useNavigate();
  return (
    <ColumnLayout overflow="flex" alignItems="center" justifyContent="center">
      <PageSection>
        <TitleComponent text="about." />
        <Typography variant="subtitle1">
          tell me something about yourself
        </Typography>
      </PageSection>
      <PageSection>
        <SectionHeader text="mission." />
        <Typography variant="body1">
          To organize the world&apos;s food information and make it easily accessible
        </Typography>
      </PageSection>

      <PageSection minHeight="300px">
        <ColumnLayout overflow="flex" spacing="12px">
          <SectionHeader text="features." />
          <Typography variant="body1">
            find out more about the latest features we are developing
          </Typography>
          <RowLayout overflow="flex" justifyContent="center">
            <Button variant="contained" size="large" onClick={() => { navigate(FeaturesPath); }}>learn more</Button>
          </RowLayout>
        </ColumnLayout>
      </PageSection>

      <SectionHeader text="team." />
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          {team.map((val) => (
            <Grid item key={val.name}>
              <WorkerProfileCard
                name={val.name}
                imgSrc={val.imgSrc}
                profileLink={val.profileLink}
                position={val.position}
                quote={val.quote}
              />
            </Grid>
          ))}
          <Grid item>
            <WorkerJoinCard />
          </Grid>
        </Grid>
      </Box>
      <PageSection>
        <ColumnLayout overflow="flex" spacing="12px">
          <SectionHeader text="work with us." />
          <Typography variant="body1">
            passionate about food and technology? We probably wont change the world though
          </Typography>
          <RowLayout overflow="flex" justifyContent="center">
            <Button variant="contained" size="large" onClick={() => { navigate(CareersPath); }}>careers</Button>
          </RowLayout>
        </ColumnLayout>
      </PageSection>

    </ColumnLayout>
  );
}

export default AboutPage;
