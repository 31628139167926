import React, { useState, useEffect } from 'react';
import { Box, Button, Skeleton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ColumnLayout from '../../layouts/column';
import TitleComponent from '../../components/typography/title';
import ExploreRestaurantCardComponent from '../../components/restaurantCard/exploreCard';
import recommendRestaurantService from '../../services/restaurants/recommend';

function ExplorePage() {
  // const restInfo = {
  //   id: 1,
  //   name: 'One Fattened Calf',
  //   isSaved: false,
  //   address: '1 Fusionopolis Pl, #01-31 Galaxis, Singapore 138522',
  //   isOpen: true,
  //   country: 'singapore',
  //   tags: [],
  //   image: '',
  //   headerImage: 'https://lh3.googleusercontent.com/p/AF1QipNNDwwRnd4EDiCZQUUVguh3LMnzVd8ynIuNvDQE=s1360-w1360-h1020',
  // };

  const [recommendInfo, setRecommendInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevIds, setPrevIds] = useState([]);

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const recommendResponse = await recommendRestaurantService(10, { page: 'explore' });
      setRecommendInfo(recommendResponse.data.restaurantInfos);
      const seen = recommendResponse.data.restaurantInfos.map((val) => val.id);
      setPrevIds([...prevIds, ...seen]);
      setIsLoading(false);
    };

    helper();
  }, []);

  const onRefresh = async () => {
    setIsLoading(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      window.scroll({ top: -1, behavior: 'smooth' });
    }, 10);
    const params = { previousIds: prevIds.join(',') };
    const recommendResponse = await recommendRestaurantService(10, { page: 'explore' }, params);
    const seen = recommendResponse.data.restaurantInfos.map((val) => val.id);
    setPrevIds([...prevIds, ...seen]);
    setRecommendInfo(recommendResponse.data.restaurantInfos);
    setIsLoading(false);
  };

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <TitleComponent text="explore." />
        <ColumnLayout alignItems="flex-start" overflow="flex">
          {isLoading ? (
            <Box
              flexWrap="wrap"
              width="100%"
              flexDirection="row"
              display="flex"
              justifyContent="space-between"
              columnGap="4px"
            >
              {[1, 2, 3, 4, 5, 6].map((val) => (
                <Skeleton
                  key={val}
                  variant="rounded"
                  width="49%"
                  height="290px"
                  sx={{ marginBottom: '4px' }}
                />
              ))}
            </Box>
          ) : (
            <Box
              flexWrap="wrap"
              width="100%"
              flexDirection="row"
              display="flex"
              justifyContent="space-between"
              columnGap="2px"
            >
              {recommendInfo.map((val) => (
                <Box key={val.id} width="49%">
                  <ExploreRestaurantCardComponent
                    restaurantId={val.id}
                    restaurantName={val.name}
                    isSaved={val.isSaved}
                    restaurantAddress={val.address}
                    isOpen={val.isOpen}
                    country={val.country}
                    tags={val.tags}
                    images={val.images}
                    image={val.headerImage}
                  />
                </Box>
              ))}
            </Box>
          )}
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="contained" onClick={onRefresh} endIcon={<RefreshIcon />}>refresh</Button>
          </Box>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default ExplorePage;
