import { number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Box, Button, Card, Divider, Skeleton,
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import getRestaurantReviewsService from '../../services/review/getRestaurantReviews';
import SectionHeader from '../../components/typography/sectionHeader';
import ColumnLayout from '../../layouts/column';
import ReviewCardComponent from '../../components/review/reviewCard';
import ReviewFormComponent from '../../components/review/reviewForm';

function ReviewsTabPanel({ userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [paginate, setPaginate] = useState({ limit: 5, offset: 0 });
  const [hideShowMore, setHideShowMore] = useState(false);
  const [showNewReviewCard, setShowNewReviewCard] = useState(false);

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);

      const reviewRes = await getRestaurantReviewsService(
        0,
        userId,
        paginate.limit,
        paginate.offset,
      );

      setPaginate({ ...paginate, offset: paginate.offset + paginate.limit });
      const reviewData = reviewRes?.data;

      if (!reviewData?.reviews?.length) {
        setHideShowMore(true);
        setIsLoading(false);
        return;
      }

      if (reviewData) {
        const { profileInfos, restaurantInfos } = reviewData;
        const formattedReview = reviewData.reviews.map((val) => ({
          id: val.id,
          username: profileInfos[val.userId]?.username,
          description: val.description,
          rating: val.rating,
          restaurantName: restaurantInfos[val.restaurantId].name,
          restaurantId: restaurantInfos[val.restaurantId].id,
          images: val.images,
        }));

        setReviews(formattedReview);
      }

      setIsLoading(false);
    };

    helper();
  }, [userId]);

  const clickShowMoreHandler = async () => {
    const reviewRes = await getRestaurantReviewsService(
      0,
      userId,
      paginate.limit,
      paginate.offset,
    );

    setPaginate({ ...paginate, offset: paginate.offset + paginate.limit });
    const reviewData = reviewRes?.data;
    if (!reviewData?.reviews?.length) {
      setHideShowMore(true);
      return;
    }

    if (reviewData) {
      const { profileInfos, restaurantInfos } = reviewData;
      const formattedReview = reviewData.reviews.map((val) => ({
        id: val.id,
        username: profileInfos[val.userId]?.username,
        description: val.description,
        rating: val.rating,
        restaurantName: restaurantInfos[val.restaurantId].name,
        restaurantId: restaurantInfos[val.restaurantId].id,
        images: val.images,
      }));

      setReviews([...reviews, ...formattedReview]);
    }
  };

  const onClickNewReview = () => {
    setShowNewReviewCard(!showNewReviewCard);
  };

  const onSubmitReview = () => {
    setShowNewReviewCard(false);
  };

  return (
    <>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <SectionHeader text="reviews." textAlign="left" />
        <Box height="100%">
          <Button
            variant="contained"
            endIcon={showNewReviewCard ? <Close /> : <Add />}
            onClick={onClickNewReview}
          >
            {showNewReviewCard ? 'close' : 'add review'}
          </Button>
        </Box>
      </Box>
      <ColumnLayout overflow="flex">
        {isLoading && [0, 1, 2, 3, 4].map((val) => (
          <Skeleton
            key={val}
            variant="rounded"
            width="100%"
            height="222px"
          />
        ))}
      </ColumnLayout>
      {showNewReviewCard && (
        <>
          <Card
            variant="outlined"
            style={{
              borderColor: '#000000',
              padding: '10px',
              marginBottom: '10px',
            }}
          >
            <ReviewFormComponent submitReviewHandler={onSubmitReview} />
          </Card>
          <Divider />
        </>
      )}
      {reviews?.length !== 0 && (
        <ColumnLayout overflow="flex">
          {reviews?.map((val) => (
            <ReviewCardComponent
              key={val.id}
              name={val.username}
              reviewText={val.description}
              rating={val.rating}
              restaurantId={val.restaurantId}
              restaurantName={val.restaurantName}
              images={val.images}
            />
          ))}
          {!hideShowMore && <Button onClick={clickShowMoreHandler}>show more</Button>}
        </ColumnLayout>
      )}
    </>
  );
}

ReviewsTabPanel.propTypes = {
  userId: number.isRequired,
};

export default ReviewsTabPanel;
