/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Alert,
  Box, Button, Container, IconButton, Link, Snackbar, Stack, TextField, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';

import LunchDiningIcon from '@mui/icons-material/LunchDining';
import GithubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RowLayout from '../../layouts/rows';
import subscribeNewsletterService from '../../services/newsletter/subscribe';
import { devlog } from '../../utils/console';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Powered by '}
      <Link href="https://mui.com/">CodingCrayons&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

// {'Copyright © '}

function FooterComponent() {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: 'Thanks for subscribing',
    severity: 'success',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const emailField = register('email', { required: true });

  const onSubmit = async (data) => {
    let responseData = {};
    setIsLoading(true);
    try {
      responseData = await subscribeNewsletterService(data.email);
    } catch (err) {
      devlog('failed', data);
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'failed to submit',
        severity: 'error',
      });
      reset();
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    if (!responseData.data) {
      // popup
      devlog('failed', data);
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: 'failed to submit',
        severity: 'error',
      });
      reset();
      return;
    }

    // pop up success
    setSnackbarState({
      ...snackbarState,
      open: true,
      message: responseData.data.message,
      severity: 'success',
    });
    reset();
    devlog('succeed', responseData.data.message);
  };
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ ml: '-15px' }}>
              <LunchDiningIcon color="primary" sx={{ width: '80px' }} />
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Newsletter
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Subscribe to our newsletter for weekly food news.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={1} useFlexGap>
                <TextField
                  id="outlined-basic"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  fullWidth
                  aria-label="Enter your email address"
                  placeholder="email"
                  inputProps={{
                    autoComplete: 'off',
                    'aria-label': 'Enter your email address',
                  }}
                  ref={emailField.ref}
                  name={emailField.name}
                  onChange={emailField.onChange}
                  onBlur={emailField.onBlur}
                />
                <Button type="submit" variant="contained" color="primary" sx={{ flexShrink: 0 }} disabled={isLoading}>
                  {isLoading ? 'loading...' : 'Subscribe'}
                </Button>
              </Stack>
            </form>

            <Snackbar open={snackbarState.open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={snackbarState.severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {snackbarState.message}
              </Alert>
            </Snackbar>

          </Box>
        </Box>
        <RowLayout overflow="flex">
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              Product
            </Typography>
            <Link color="text.secondary" href="https://blog.codingcrayons.com">
              blog
            </Link>
            <Link color="text.secondary" href="#">
              explore
            </Link>
            <Link color="text.secondary" href="#">
              search
            </Link>
            <Link color="text.secondary" href="#">
              faqs
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              Company
            </Typography>
            <Link color="text.secondary" href="#">
              about
            </Link>
            <Link color="text.secondary" href="#">
              careers
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              Legal
            </Typography>
            <Link color="text.secondary" href="#">
              terms
            </Link>
            <Link color="text.secondary" href="#">
              privacy
            </Link>
            <Link color="text.secondary" href="#">
              contact
            </Link>
          </Box>
        </RowLayout>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="#">
            privacy policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            terms of service
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="https://blog.codingcrayons.com">
            blog
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/fangpinsern"
            aria-label="GitHub"
            sx={{ alignSelf: 'center' }}
          >
            <GithubIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/in/pinsernfang/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}

export default FooterComponent;
