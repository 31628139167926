import {
  Alert,
  Autocomplete, Avatar, Box, Button, IconButton, TextField, Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { iso31661 } from 'iso-3166';
import Compressor from 'compressorjs';
import heic2any from 'heic2any';
import ColumnLayout from '../../layouts/column';
import SectionHeader from '../../components/typography/sectionHeader';
import FormErrorTextComponent from '../../components/typography/errorText';
import { devlog } from '../../utils/console';
import searchTagsService from '../../services/search/tags';
import { ProfilePath } from '../../constants/routes.contants';
import updateProfileService from '../../services/profile/update';
import getUploadLinkService from '../../services/upload/getUploadLink';
import sendFileToR2 from '../../services/upload/sendFile';

function UpdateProfilePage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [tagsOption, setTagsOption] = useState(state?.tags || []);
  const [submitError, setSubmitError] = useState({ status: false, message: '' });
  const [tagInputVal, setTagInputVal] = useState('');
  const [compressedFile, setCompressedFile] = useState(state?.profileImage);
  const [uploadKey, setUploadKey] = useState(state?.profileImageKey);

  // console.log(setSubmitError);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      name: state?.name,
      bio: state?.bio,
      country: Number(iso31661
        .find((option) => state?.country === option.name).numeric),
      tags: state?.tags,
      profileImage: state?.profileImage,
    },
  });

  const nameField = register('name', { required: true });
  const bioField = register('bio', { required: true });

  const onSubmit = async (data) => {
    const { error } = await updateProfileService(
      data.name,
      data.bio,
      data.country,
      uploadKey,
      data.tags.map((val) => val.id),
    );

    if (error) {
      devlog(error);
      setSubmitError({
        ...submitError,
        status: true,
        message: error.message,
      });
      return;
    }

    navigate(ProfilePath);
  };

  const onCancel = () => {
    navigate(ProfilePath);
  };

  const inputChangeHandler = async (event, value) => {
    setTagInputVal(value);
  };

  useEffect(() => {
    const helper = async () => {
      const { data } = await searchTagsService('');
      setTagsOption(data.tags);
    };

    helper();
  }, [setTagsOption]);

  useEffect(() => {
    const throttleTimer = setTimeout(async () => {
      const { data } = await searchTagsService(tagInputVal);
      setTagsOption(data.tags);
      devlog(data);
    }, 500);

    return () => {
      clearTimeout(throttleTimer);
    };
  }, [tagInputVal]);

  const handleCompressedUpload = (e) => {
    const image = e;
    const c = new Compressor(image, {
      maxWidth: 1000,
      convertSize: 500000,
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: async (compressedResult) => {
        const { data } = await getUploadLinkService();
        const putlink = data?.link?.putLink;
        await sendFileToR2(putlink, compressedResult);
        setCompressedFile(data?.link?.getLink);
        setUploadKey(data?.link?.key);
      },
    });

    setTimeout(() => {
      try {
        c.abort();
      } catch {
        devlog('compress might have been aborted');
      }
    }, 5000);
  };

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ColumnLayout overflow="flex" alignItems="flex-start">
            <SectionHeader text="personal." textAlign="left" />
            <Box width="100%" display="flex" justifyContent="center">
              <IconButton
                component="label"
                variant="contained"
                isLoad
              >
                <Avatar
                  src={compressedFile}
                  style={{
                    margin: '10px',
                    width: '220px',
                    height: '220px',
                  }}
                />
                <input
                  type="file"
                  hidden
                  accept="image/*,.heic"
                  onChange={async (e) => {
                  // upload the file
                    let file = e.target.files[0];
                    if (!file) {
                      return;
                    }
                    devlog(e.target.files[0].type);
                    if (e.target.files[0].type === 'image/heic') {
                      const res = await heic2any({
                        blob: e.target.files[0],
                        toType: 'image/jpeg',
                        quality: 0.1, // cuts the quality and size by half
                      });
                      // e.target.files[0] = res;
                      file = res;
                    }
                    handleCompressedUpload(file);
                  }}
                />
              </IconButton>
            </Box>
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">name</Typography>
              <Typography variant="caption">how do i address you?</Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="name"
              type="text"
              InputProps={{
                sx: { height: '40px' },
              }}
              ref={nameField.ref}
              name={nameField.name}
              onChange={nameField.onChange}
              onBlur={nameField.onBlur}
            />
            {errors.username && <FormErrorTextComponent text="name this is required" />}
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">country</Typography>
              <Typography variant="caption">where are you from?</Typography>
            </Box>
            <Controller
              name="country"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    sx={{ width: '100%', textAlign: 'left' }}
                    value={value ? iso31661.find((option) => value === Number(option.numeric))
                      : null}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newVal) => {
                      onChange(newVal ? Number(newVal.numeric) : null);
                    }}
                    options={iso31661}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="country"
                      />
                    )}
                  />
                );
              }}
            />
            {errors.country && <FormErrorTextComponent text="country is required" />}
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">bio</Typography>
              <Typography variant="caption">e.g. what is your favourite bubble tea order?</Typography>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="bio"
              type="text"
              ref={bioField.ref}
              name={bioField.name}
              onChange={bioField.onChange}
              onBlur={bioField.onBlur}
            />
            {errors.bio && <FormErrorTextComponent text="bio is required" />}
            <Box display="flex" textAlign="left" flexDirection="column" width="100%">
              <Typography variant="h6" fontWeight="bold">preferences</Typography>
              <Typography variant="caption">choose your favourite tags</Typography>
            </Box>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    multiple
                    value={value}
                    sx={{ width: '100%', textAlign: 'left' }}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, val) => option.id === val.id}
                    onChange={(event, newVal) => {
                      devlog(newVal);
                      onChange(newVal.map((val) => val));
                    }}
                    filterOptions={(x) => x}
                    onInputChange={inputChangeHandler}
                    options={tagsOption}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="preferences"
                      />
                    )}
                  />
                );
              }}
            />
            <Box width="100%">
              {submitError.status && (
              <Alert variant="filled" severity="error">
                {submitError.message || 'Submission Error'}
              </Alert>
              )}
            </Box>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button size="large" variant="outlined" onClick={onCancel}>cancel</Button>
              <Button size="large" variant="contained" type="submit">save</Button>
            </Box>
          </ColumnLayout>
        </form>
      </Box>
    </Box>
  );
}

export default UpdateProfilePage;
