import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { number } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ColumnLayout from '../../layouts/column';
import DoneCardComponent from '../../components/listCard/doneCard';
import getListInfoService from '../../services/lists/details';
import RowLayout from '../../layouts/rows';
import { RecommendPath } from '../../constants/routes.contants';

// const listName = 'done';
const listOrdering = 1;

function DoneSection({ tabValue }) {
  const navigate = useNavigate();
  const [listItems, setListItems] = useState([]);
  const [restaurants, setRestaurants] = useState({});

  useEffect(() => {
    const helper = async () => {
      const { data } = await getListInfoService(listOrdering);

      setListItems(data.listItemInfos);
      setRestaurants(data.restaurantInfos);
    };

    helper();
  }, [tabValue]);

  const onClickRecommend = () => {
    navigate(RecommendPath, { state: { list: 'done' } });
  };

  return (
    <Box border={1} borderRadius="5px" minHeight="400px" p={1}>
      <ColumnLayout overflow="flex" alignItems="center">
        {listItems.length !== 0 && (
          <>
            <Box width="100%">
              <RowLayout justifyContent="flex-start">
                <Button variant="contained" onClick={onClickRecommend}>Recommend</Button>
              </RowLayout>
            </Box>
            {' '}
            {listItems.map((val) => {
              const restId = val.restaurantId;
              const restaurantInfo = restaurants[restId];
              return (
                <DoneCardComponent
                  key={val.restaurantId}
                  restaurantName={restaurantInfo.name}
                  restaurantId={restaurantInfo.id}
                  restaurantAddress={restaurantInfo.address}
                  isSaved={restaurantInfo.isSaved}
                  isOpen={restaurantInfo.isOpen}
                  tags={restaurantInfo.tags}
                  country={restaurantInfo.country}
                  images={restaurantInfo.images}
                  review={restaurantInfo.personalReview}
                />
              );
            })}
          </>
        )}
      </ColumnLayout>
    </Box>
  );
}

DoneSection.propTypes = {
  tabValue: number.isRequired,
};

export default DoneSection;
