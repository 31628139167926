import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { node } from 'prop-types';
import { useAuth } from '../hooks/auth.hook';
import { LoginPath } from '../../constants/routes.contants';

function RequireAuth({ children }) {
  const { authed } = useAuth();
  const location = useLocation();

  const pathName = location.pathname;
  const queries = location.search ? location.search : '';

  const fullPath = pathName + queries;

  return authed ? (
    children
  ) : (
    <Navigate to={LoginPath} replace state={{ path: fullPath }} />
  );
}

RequireAuth.propTypes = {
  children: node.isRequired,
};

export default RequireAuth;
