import { joinGroupApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const joinGroupService = async (key) => {
  const response = await gateway.post(joinGroupApiDetails.path, {
    key,
  });

  const { data, error } = response.data;

  return { data, error };
};

export default joinGroupService;
