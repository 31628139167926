import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RowLayout from '../../layouts/rows';
import { GroupListPath, ListPath, NotiPath } from '../../constants/routes.contants';

function InfoBarComponent() {
  const navigate = useNavigate();
  const onClickSaved = () => {
    navigate(ListPath);
  };
  const onClickReco = () => {
    navigate(GroupListPath);
  };

  const onClickNoti = () => {
    navigate(NotiPath);
  };
  return (
    <Box border={1} borderRadius="5px" width="100%" height="40px">
      <RowLayout overflow="flex" alignItems="center" justifyContent="space-around">
        <Button variant="text" onClick={onClickSaved}>saved</Button>
        <Divider orientation="vertical" flexItem />
        <Button onClick={onClickReco}>groups</Button>
        <Divider orientation="vertical" flexItem />
        <Button onClick={onClickNoti}>noti</Button>
      </RowLayout>
    </Box>
  );
}

export default InfoBarComponent;
