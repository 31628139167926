import { saveRestaurantApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const saveRestaurantService = async (restaurantId, groupId) => {
  const response = await gateway.post(saveRestaurantApiDetails.path, {
    restaurantId,
    groupId,
  });

  return response.data;
};

export default saveRestaurantService;
