import { getProfileInfoApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getProfileInfoService = async () => {
  const response = await gateway.get(getProfileInfoApiDetails.path);

  const { data, error } = response.data;
  return { data, error };
};

export default getProfileInfoService;
