import { getRecentApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const getRecentService = async () => {
  const response = await gateway.get(getRecentApiDetails.path);

  const { data, error } = response.data;

  return { data, error };
};

export default getRecentService;
