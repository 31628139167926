import React, { useState } from 'react';
import {
  Box, Button, IconButton,
  Rating, TextField, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  func, string,
} from 'prop-types';
import Add from '@mui/icons-material/Add';
import Compressor from 'compressorjs';
import Close from '@mui/icons-material/Close';
import FormErrorTextComponent from '../typography/errorText';
import ColumnLayout from '../../layouts/column';
import reviewRestaurantService from '../../services/review/restaurant';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';
import { devlog } from '../../utils/console';
import getUploadLinkService from '../../services/upload/getUploadLink';
import sendFileToR2 from '../../services/upload/sendFile';
import ReviewRestaurantSearchComponent from './reviewRestaurantSearch';

function FormInputLabel({ label, helperText }) {
  return (
    <Box display="flex" textAlign="left" flexDirection="column" width="100%">
      <Typography variant="h6" fontWeight="bold">{label}</Typography>
      {helperText.length >= 0 && (
        <Typography variant="caption">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

FormInputLabel.propTypes = {
  label: string.isRequired,
  helperText: string,
};

FormInputLabel.defaultProps = {
  helperText: '',
};

function ReviewFormComponent({ submitReviewHandler, restaurantId }) {
  const [ratingVal, setRatingVal] = useState(3);
  const [images, setImages] = useState([]);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(restaurantId);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { notify } = useCustomSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      description: '',
      rating: ratingVal,
    },
  });

  const handleCompressedUploads = (e) => {
    const uploadedImages = e;
    const castedImages = Array.from(uploadedImages);

    castedImages.forEach((val) => {
      const c = new Compressor(val, {
        maxWidth: 1000,
        convertSize: 500000,
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: async (compressedResult) => {
          const { data } = await getUploadLinkService();
          const putlink = data?.link?.putLink;
          await sendFileToR2(putlink, compressedResult).then(() => {
            if (data?.link?.getLink) {
              images.push({
                fileLink: data?.link?.getLink,
                key: data?.link?.key,
              });
              setImages([...images]);
            }
          });
        },
      });

      setTimeout(() => {
        try {
          c.abort();
        } catch {
          devlog('compress might have been aborted');
        }
      }, 5000);
    });
  };

  const onSubmit = async (data) => {
    setDisableSubmit(true);
    if (!selectedRestaurantId) {
      setErrorMessage('no restaurant selected. restaurant is required, if not review what');
      setDisableSubmit(false);
      return;
    }
    const imageKeys = images.map((val) => val.key);
    const reviewRes = await reviewRestaurantService(
      selectedRestaurantId,
      data.rating,
      data.description,
      imageKeys,
    );
    if (reviewRes.error) {
      devlog('something went wrong');
      return;
      // something went wrong.
    }
    notify(reviewRes?.data?.message);
    submitReviewHandler();
    setDisableSubmit(false);
  };
  const descriptionField = register('description', {
    validate: {
      notNone: (v) => {
        const rating = getValues('rating');
        if (rating < 3) {
          return v.length > 0 || 'please let them know how they can do better';
        }
        return true;
      },
    },
  });
  register('rating', { required: true, min: 1 });

  const removeImage = (key) => () => {
    const filteredImage = images.filter((val) => val.key !== key);
    setImages(filteredImage);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ColumnLayout>
        {!restaurantId && (
          <>
            <FormInputLabel label="restaurant" />
            <ReviewRestaurantSearchComponent setSelectedRestaurantId={setSelectedRestaurantId} />
          </>
        )}

        <FormInputLabel label="rating" helperText="out of 5, what would you give?" />
        <Rating
          name="ratings"
          value={ratingVal ?? 0}
          onChange={(event, newValue) => {
            setValue('rating', newValue);
            setRatingVal(Number(newValue));
          }}
          size="large"
        />
        <FormInputLabel label="description" helperText="tell us your thoughts" />
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          placeholder="description"
          type="text"
          ref={descriptionField.ref}
          name={descriptionField.name}
          onChange={descriptionField.onChange}
          onBlur={descriptionField.onBlur}
        />
        {errors.description && (
          <FormErrorTextComponent
            text={errors?.description?.message || 'description this is required'}
          />
        )}
        <FormInputLabel label="images" helperText="pictures speak a thousand words" />
        <Box
          // border="1px solid #000000"
          // borderRadius="5px"
          height="150px"
          display="flex"
          alignItems="center"
          // padding="10px"
          overflow="scroll"
          columnGap="5px"
        >
          <Box
            height="90%"
            border="1px solid gray"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="5px"
            sx={{ aspectRatio: '1 / 1' }}
          >
            <IconButton
              component="label"
              aria-label="add"
              size="large"
            >
              <Add />
              <input
                type="file"
                hidden
                multiple
                accept="image/*,.heic"
                onChange={async (e) => {
                  // upload the file
                  const { files } = e.target;
                  if (!files || files.length === 0) {
                    return;
                  }

                  // Array.from(files).forEach((val) => {
                  //   console.log(val.type);
                  // });

                  // console.log(handleCompressedUpload, heic2any);
                  // if (e.target.files[0].type === 'image/heic') {
                  //   const res = await heic2any({
                  //     blob: e.target.files[0],
                  //     toType: 'image/jpeg',
                  //     quality: 0.1,
                  //   });
                  //   file = res;
                  // }
                  handleCompressedUploads(files);
                }}
              />
            </IconButton>
          </Box>
          {images.map((val) => (
            <Box
              height="90%"
              // border="1px solid gray"
              display="flex"
              position="relative"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
              sx={{ aspectRatio: '1 / 1' }}
              key={val.key}
            >
              <Box position="absolute" right={0} top={0} borderRadius="inherit" sx={{ background: 'rgba(128, 128, 128, .5)' }}>
                <IconButton size="small" onClick={removeImage(val.key)}>
                  <Close color="primary" fontSize="12px" />
                </IconButton>
              </Box>
              <img
                style={{ borderRadius: 'inherit', objectFit: 'cover' }}
                srcSet={`${val.fileLink}`}
                src={`${val.fileLink}`}
                alt={val.fileLink}
                loading="lazy"
                height="100%"
                width="100%"
              />
            </Box>
          ))}
        </Box>
        {errorMessage.length > 0 && (
        <Typography color="red" align="left">
          {errorMessage}
        </Typography>
        )}
        <Box display="flex" justifyContent="right" width="100%">
          <Button variant="contained" type="submit" disabled={disableSubmit}>submit</Button>
        </Box>
      </ColumnLayout>
    </form>
  );
}

ReviewFormComponent.propTypes = {
  submitReviewHandler: func.isRequired,
  restaurantId: string.isRequired,
};

export default ReviewFormComponent;
