import React from 'react';
import {
  arrayOf, bool, shape, string,
} from 'prop-types';
import { Skeleton } from '@mui/material';
import RestaurantCardComponent from '../../components/restaurantCard/restaurantCard';
import EmptyRestaurantCardComponent from '../../components/restaurantCard/emptyRestaurantCard';

function HotPickSection({ hotpick, isLoading }) {
  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="222px" />;
  }

  return (
    hotpick ? (
      <RestaurantCardComponent
        restaurantName={hotpick.name}
        restaurantId={hotpick.id}
        restaurantAddress={hotpick.address}
        isSaved={hotpick.isSaved}
        isOpen={hotpick.isOpen}
        tags={hotpick.tags}
        country={hotpick.country}
        images={hotpick.images}
      />
    ) : <EmptyRestaurantCardComponent />

  );
}

HotPickSection.propTypes = {
  hotpick: shape({
    restaurantId: string,
    restaurantName: string,
    restaurantAddress: string,
    isSaved: bool,
    isOpen: bool,
    tags: arrayOf(string),
    country: string,
    images: arrayOf(string),
  }).isRequired,
  isLoading: bool,
};

HotPickSection.defaultProps = {
  isLoading: false,
};

export default HotPickSection;
