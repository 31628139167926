import { node } from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotifyError } from './notifyError.hook';
import { ejectInterceptors, registerRequestInterceptor, registerResponseInterceptor } from '../axios/gateway';
import { useAuth } from './auth.hook';

// credits https://dev.to/arianhamdi/react-hooks-in-axios-interceptors-3e1h

// MOVE AXIOS INSTANCE CREATION OUT

function AxiosHook({ children }) {
  const navigate = useNavigate();

  const auth = useAuth();

  const location = useLocation();

  const pathName = location.pathname;
  const queries = location.search ? location.search : '';
  const fullPath = pathName + queries;

  const [isSet, setIsSet] = useState(false);
  // const { getToken } = useAuth();
  const notifyError = useNotifyError();

  useEffect(() => {
    const reqInterceptor = registerRequestInterceptor();

    const resInterceptor = registerResponseInterceptor(notifyError, navigate, auth, fullPath);

    setIsSet(true);

    return () => {
      ejectInterceptors(reqInterceptor, resInterceptor);
    };
  }, [notifyError]);

  return isSet && children;
}

AxiosHook.propTypes = {
  children: node.isRequired,
};

export default AxiosHook;

export { AxiosHook };
