import React from 'react';
import { Chip } from '@mui/material';
import { string } from 'prop-types';

function TagComponent({ label, type }) {
  let variant = 'filled';
  if (type === 'secondary') {
    variant = 'outlined';
  }
  return (
    <Chip
      label={label}
      color="primary"
      variant={variant}
      style={{ fontWeight: 'bold' }}
      size="small"
    />
  );
}

TagComponent.propTypes = {
  label: string.isRequired,
  type: string,
};

TagComponent.defaultProps = {
  type: 'primary',
};

export default TagComponent;
