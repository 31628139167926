import { Typography } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';

// sx={{
//   display: '-webkit-box',
//   overflow: 'hidden',
//   WebkitBoxOrient: 'vertical',
//   WebkitLineClamp: 3,
// }}

function TitleComponent({ text, textAlign }) {
  return (
    <Typography
      padding="5px 0px"
      variant="h3"
      component="div"
      fontWeight="bold"
      textAlign={textAlign}
    >
      {text}
    </Typography>
  );
}

TitleComponent.propTypes = {
  text: string.isRequired,
  textAlign: string,
};

TitleComponent.defaultProps = {
  textAlign: '',
};

export default TitleComponent;
