import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
// import DoneIcon from '@mui/icons-material/Done';
import { shape } from 'prop-types';
import { EventIdParam, GroupDetailsPathBuilder, GroupListPath } from '../../constants/routes.contants';
import ColumnLayout from '../../layouts/column';
import SectionHeader from '../../components/typography/sectionHeader';
import EventHeaderComponent from '../../components/events/details/header';
import MeetingLocationComponent from '../../components/events/details/meetingplace';
import GoogleMapsComponent from '../../components/googleMapsFrame/googleMaps';
import EventPhotoShareComponent from '../../components/events/details/photoShare';
import getEventDetailsService from '../../services/events/getEventDetails';
import EventDetailsSkeleton from './eventDetailsSkeleton';

// function TimelineComponent() {
//   return (
//     <Box display="flex" flexDirection="row" alignItems="center" width="100%">
//       <Box
//         display="flex"
//         border="1px solid black"
//         borderRadius="50%"
//         sx={{ aspectRatio: '1 / 1' }}
//         width="20%"
//         alignItems="center"
//         justifyContent="center"
//         textAlign="center"
//       >
//         <DoneIcon />
//       </Box>
//       <Box height={0} width="26%" border="1px solid black" />
//       <Box
//         display="flex"
//         border="1px solid black"
//         borderRadius="50%"
//         sx={{ aspectRatio: '1 / 1' }}
//         width="20%"
//         alignItems="center"
//         justifyContent="center"
//         textAlign="center"
//       >
//         <Typography>2</Typography>
//       </Box>
//       <Box height={0} width="26%" border="1px solid black" />
//       <Box
//         display="flex"
//         border="1px solid black"
//         borderRadius="50%"
//         sx={{ aspectRatio: '1 / 1' }}
//         width="20%"
//         alignItems="center"
//         justifyContent="center"
//         textAlign="center"
//       >
//         <Typography>3</Typography>
//       </Box>
//       <Box height={0} width="26%" border="1px solid black" />
//       <Box
//         display="flex"
//         border="1px solid black"
//         borderRadius="50%"
//         sx={{ aspectRatio: '1 / 1' }}
//         width="20%"
//         left={0}
//         alignItems="center"
//         justifyContent="center"
//         textAlign="center"
//       >
//         <Typography>4</Typography>
//       </Box>
//     </Box>
//   );
// }

/*
there are a few states
1. newly created - once everyone free on the group
2. survey started - can add your own option or we can choose for
you based on everyones preferences and list
3. restaurant chosen state - chosen
4. post meetup state - reviews, split bill, post photos?
*/

function BasicView({ eventInfo, restaurantInfos }) {
  const unixNow = Date.now();
  const eventUnix = eventInfo.eventDate * 1000;
  const isPassed = unixNow > eventUnix;
  // const isPassed = true;

  const restaurantId = eventInfo?.restaurantId;
  const restaurantInfo = restaurantInfos[restaurantId];
  return (
    <Box width="100%" textAlign="left">
      <ColumnLayout overflow="flex">
        <EventHeaderComponent name={eventInfo?.name} date={eventInfo?.eventDate} />
        <MeetingLocationComponent
          isPassed={isPassed}
          eventDate={eventInfo.eventDate}
          restaurantInfo={restaurantInfo}
        />
        {isPassed && <EventPhotoShareComponent eventId={eventInfo.id} />}
        <Typography variant="h5" fontWeight="bold">location</Typography>
        <GoogleMapsComponent query={`${restaurantInfo.name}${restaurantInfo.address}`} />
      </ColumnLayout>
    </Box>
  );
}

BasicView.propTypes = {
  eventInfo: shape().isRequired,
  restaurantInfos: shape().isRequired,
};

function EventDetailsPage() {
  const params = useParams();
  const eventId = params[EventIdParam];
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [eventData, setEventData] = useState({});

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const { data } = await getEventDetailsService(eventId);

      if (!data?.eventInfo) {
        navigate(GroupListPath);
        setIsLoading(false);
        return;
      }

      setEventData(data);

      setIsLoading(false);
    };

    helper();
  }, []);

  const onClickBack = () => {
    navigate(GroupDetailsPathBuilder(state?.groupId));
  };

  // if (isLoading) {
  //   return <Typography>iamloading</Typography>;
  // }
  return isLoading ? <EventDetailsSkeleton /> : (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="50vw" minWidth="350px" maxWidth="550px">
        <ColumnLayout alignItems="center" overflow="flex">
          <Box width="100%" position="relative" alignItems="center" justifyContent="center" display="flex">
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                textAlign: 'left',
              }}
            >
              <IconButton onClick={onClickBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <SectionHeader text="details" />
          </Box>
          <BasicView eventInfo={eventData.eventInfo} restaurantInfos={eventData.restaurantInfos} />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default EventDetailsPage;
