const TokenKey = 'token';
const UsernameKey = 'username';
const UserIdKey = 'userId';
const DisplayImageKey = 'display_image';
const TopAlertCloseTimeKey = 'top_alert_close_time';

export function GetToken() {
  return localStorage.getItem(TokenKey);
}

export function SetToken(token) {
  localStorage.setItem(TokenKey, token);
}

export function RemoveToken() {
  localStorage.removeItem(TokenKey);
}

export function GetUsername() {
  return localStorage.getItem(UsernameKey);
}

export function SetUsername(username) {
  localStorage.setItem(UsernameKey, username);
}

export function RemoveUsername() {
  localStorage.removeItem(UsernameKey);
}

export function GetUserId() {
  return localStorage.getItem(UserIdKey);
}

export function SetUserId(userId) {
  localStorage.setItem(UserIdKey, userId);
}

export function RemoveUserId() {
  localStorage.removeItem(UserIdKey);
}

export function GetDisplayImage() {
  return localStorage.getItem(DisplayImageKey);
}

export function SetDisplayImage(displayImage) {
  localStorage.setItem(DisplayImageKey, displayImage);
}

export function RemoveDisplayImage() {
  localStorage.removeItem(DisplayImageKey);
}

export function GetTopAlertCloseTime() {
  return Number(localStorage.getItem(TopAlertCloseTimeKey));
}

export function SetTopAlertCloseTime(topAlertCloseTime) {
  localStorage.setItem(TopAlertCloseTimeKey, topAlertCloseTime);
}

export function RemoveTopAlertCloseTime() {
  localStorage.removeItem(TopAlertCloseTimeKey);
}
