import React from 'react';

import {
  Button,
  Stack,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { arrayOf, bool, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import TagComponent from '../tags/tag';
import OpenCloseTagComponent from '../tags/openCloseTag';
import RowLayout from '../../layouts/rows';
import ColumnLayout from '../../layouts/column';
import { RestaurantPathBuilder } from '../../constants/routes.contants';
import SaveRestaurantNewComponent from '../save/saveRestaurantNew';

function RestaurantCardComponent({
  restaurantId,
  restaurantName,
  isSaved,
  restaurantAddress,
  isOpen,
  country,
  tags,
  images,
}) {
  const navigate = useNavigate();

  const moreInfo = () => {
    const restaurantPath = RestaurantPathBuilder(restaurantId);
    navigate(restaurantPath);
  };
  return (
    <Card
      variant="outlined"
      style={{ width: '100%', minWidth: '353px', borderColor: '#000000' }}
    >
      <CardContent style={{ padding: '10px' }}>
        <ColumnLayout overflow="flex">
          <RowLayout
            justifyContent="space-between"
            alignItems="flex-start"
            overflow="flex"
          >
            <Typography
              padding="5px 0px "
              variant="h5"
              component="div"
              fontWeight="bold"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
              textAlign="left"
            >
              {restaurantName}
            </Typography>
            <SaveRestaurantNewComponent
              isSaved={isSaved}
              restaurantId={restaurantId}
            />
          </RowLayout>
          <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
            <TagComponent label={country} type="secondary" />
            {(tags || []).length !== 0
              && tags.map((val) => <TagComponent key={val.id} label={val.name} />)}
          </Stack>
          <RowLayout overflow="flex">
            <Typography>
              Address:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}
              fontWeight="bold"
              textAlign="left"
            >
              {restaurantAddress}
            </Typography>
          </RowLayout>
          <RowLayout overflow="flex">
            <OpenCloseTagComponent isOpen={isOpen} />
          </RowLayout>
          <RowLayout overflow="auto">
            {images
              && images.map((item) => (
                <img
                  key={item.img}
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                  style={{ borderRadius: '5px' }}
                />
              ))}
          </RowLayout>
          <Stack direction="row">
            <Button variant="contained" onClick={moreInfo}>
              More Info
            </Button>
          </Stack>
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

RestaurantCardComponent.propTypes = {
  restaurantId: string.isRequired,
  restaurantName: string.isRequired,
  isSaved: bool.isRequired,
  restaurantAddress: string.isRequired,
  isOpen: bool.isRequired,
  country: string.isRequired,
  tags: arrayOf(string),
  images: arrayOf(string),
};

RestaurantCardComponent.defaultProps = {
  tags: [],
  images: [],
};

export default RestaurantCardComponent;
