import React from 'react';
import { Chip } from '@mui/material';
import { bool } from 'prop-types';

function OpenCloseTagComponent({ isOpen }) {
  let color = '#9DFFAD';
  if (!isOpen) {
    color = '#FAA9A9';
  }
  return (
    <Chip
      label={isOpen ? 'open' : 'closed'}
      variant="filled"
      style={{ fontWeight: 'bold', backgroundColor: color }}
      size="small"
    />
  );
}

OpenCloseTagComponent.propTypes = {
  isOpen: bool,
};

OpenCloseTagComponent.defaultProps = {
  isOpen: true,
};

export default OpenCloseTagComponent;
