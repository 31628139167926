import { createGroupApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const createGroupService = async (name) => {
  const response = await gateway.post(createGroupApiDetails.path, {
    name,
  });

  const { data, error } = response.data;

  return { data, error };
};

export default createGroupService;
