import React from 'react';

import AppBar from '@mui/material/AppBar';
import {
  Avatar,
  Box, Button, Container, Divider, Drawer, Icon, IconButton, MenuItem, Toolbar, Typography,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/zeroIconTrans.svg';
// import FullLogo from '../../assets/fullLogo.svg';
import {
  AboutPath, ContributePath, ExplorePath, GroupListPath, HomePath,
  ListPath, LoginPath, ProfilePath,
  RecommendPath, RegisterPath, SearchPath,
} from '../../constants/routes.contants';
import { useAuth } from '../../middlewares/hooks/auth.hook';
import AvatarMenu from './avatarMenu';
import { GetDisplayImage } from '../../utils/localStorage';

// const scrollToSection = (sectionId) => {
//     const sectionElement = document.getElementById(sectionId);
//     const offset = 128;
//     if (sectionElement) {
//       const targetScroll = sectionElement.offsetTop - offset;
//       sectionElement.scrollIntoView({ behavior: 'smooth' });
//       window.scrollTo({
//         top: targetScroll,
//         behavior: 'smooth',
//       });
//       setOpen(false);
//     }
//   };

// const logoStyle = {
//   width: '140px',
//   height: 'auto',
//   cursor: 'pointer',
//   justifyContent: 'center',
// };

function NavBarComponent() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const pathName = location.pathname;
  const queries = location.search ? location.search : '';

  const fullPath = pathName + queries;

  const { authed, logout } = useAuth();

  const goHome = (e) => {
    e.preventDefault();
    navigate(HomePath);
  };

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const toggleAvatarMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            bgcolor:
              theme.palette.mode === 'light'
                ? 'rgba(255, 255, 255, 0.4)'
                : 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(24px)',
            maxHeight: 40,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow:
              theme.palette.mode === 'light'
                ? '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)'
                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: '-18px',
              px: 0,
            }}
          >
            <Icon
              sx={{
                height: '32px', width: 'auto', textAlign: 'center', ml: '10px',
              }}
              onClick={goHome}
            >
              <img src={Logo} alt="logo" height="100%" style={{ cursor: 'pointer' }} />
            </Icon>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <MenuItem
                sx={{ py: '6px', px: '12px' }}
                onClick={() => {
                  navigate(ExplorePath);
                }}
              >
                <Typography variant="body2" color="text.primary">
                  Explore
                </Typography>
              </MenuItem>
              <MenuItem
                sx={{ py: '6px', px: '12px' }}
                onClick={() => {
                  navigate(SearchPath);
                }}
              >
                <Typography variant="body2" color="text.primary">
                  Search
                </Typography>
              </MenuItem>
              <MenuItem
                sx={{ py: '6px', px: '12px' }}
                onClick={() => {
                  navigate(AboutPath);
                }}
              >
                <Typography variant="body2" color="text.primary">
                  About
                </Typography>
              </MenuItem>
              {authed && (
                <MenuItem
                  sx={{ py: '6px', px: '12px' }}
                  onClick={() => {
                    navigate(ListPath);
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                    my list
                  </Typography>
                </MenuItem>
              )}
              {authed && (
                <MenuItem
                  sx={{ py: '6px', px: '12px' }}
                  onClick={() => {
                    navigate(GroupListPath);
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                    my groups
                  </Typography>
                </MenuItem>
              )}
              <MenuItem
                sx={{ py: '6px', px: '12px' }}
                onClick={() => {
                  navigate(RecommendPath);
                }}
              >
                <Typography variant="body2" color="text.primary">
                  reco
                </Typography>
              </MenuItem>
              <MenuItem
                sx={{ py: '6px', px: '12px' }}
                onClick={() => {
                  navigate(ContributePath);
                }}
              >
                <Typography variant="body2" color="text.primary">
                  contribute
                </Typography>
              </MenuItem>
              <MenuItem
                sx={{ py: '6px', px: '12px' }}
                component="a"
                href="https://blog.codingcrayons.com"
              >
                <Typography variant="body2" color="text.primary">
                  blog
                </Typography>
              </MenuItem>
            </Box>
          </Box>
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            {authed ? (
              <>
                <IconButton onClick={toggleAvatarMenu}>
                  <Avatar />
                </IconButton>
                <AvatarMenu anchorEl={anchorEl} handleClose={handleClose} />
              </>
            ) : (
              <>

                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  component="a"
                  href="/login"
                >
                  Sign in
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component="a"
                  href="/register"
                >
                  Sign up
                </Button>
              </>
            )}

          </Box>
          <Box sx={{ display: { sm: '', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    flexGrow: 1,
                  }}
                />
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false)();
                    navigate(ExplorePath);
                  }}
                >
                  Explore
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false)();
                    navigate(SearchPath);
                  }}
                >
                  Search
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false)();
                    navigate(AboutPath);
                  }}
                >
                  About
                </MenuItem>
                {
                  authed && (
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)();
                        navigate(ListPath);
                      }}
                    >
                      my list
                    </MenuItem>
                  )
                }
                {
                  authed && (
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)();
                        navigate(GroupListPath);
                      }}
                    >
                      my groups
                    </MenuItem>
                  )
                }
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false)();
                    navigate(RecommendPath);
                  }}
                >
                  Reco
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false)();
                    navigate(ContributePath);
                  }}
                >
                  contribute
                </MenuItem>
                <MenuItem
                  component="a"
                  href="https://blog.codingcrayons.com"
                >
                  blog
                </MenuItem>
                <Divider />
                {authed ? (
                  <>
                    <MenuItem
                      sx={{
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                      }}
                      onClick={() => {
                        toggleDrawer(false)();
                        navigate(ProfilePath);
                      }}
                    >
                      <Avatar src={GetDisplayImage()} />
                      Profile
                    </MenuItem>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          logout();
                          toggleDrawer(false)();
                          navigate(HomePath);
                        }}
                        sx={{ width: '100%' }}
                      >
                        logout
                      </Button>
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          toggleDrawer(false)();
                          navigate(RegisterPath, { state: { path: fullPath } });
                        }}
                        sx={{ width: '100%' }}
                      >
                        Register
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          toggleDrawer(false)();
                          navigate(LoginPath, { state: { path: fullPath } });
                        }}
                        sx={{ width: '100%' }}
                      >
                        Login
                      </Button>
                    </MenuItem>
                  </>
                )}
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBarComponent;
