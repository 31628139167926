// import axios from 'axios';
// import { getMockedResponse } from './mocking';
// import { useCustomSnackbar } from '../hooks/snackbar.hook';

import axios, { isCancel } from 'axios';
import { GetToken } from '../../utils/localStorage';
import { getMockedResponse } from './mocking';
import { LoginPath } from '../../constants/routes.contants';
import { devlog } from '../../utils/console';

// baseURL: 'https://google.com',
// const instance = axios.create({
//   baseURL: 'http://localhost:8080',
// });

// if service is in mock env
// we will cancel the request and return a mocked response

// instance.interceptors.request.use((config) => {
//   const env = process.env.REACT_APP_ENV;
//   if (env === 'MOCK') {
//     const controller = new AbortController();
//     controller.abort();
//     return { ...config, signal: controller.signal };
//   }

//   return config;
// });

// instance.interceptors.response.use((response) => {
//   console.log(response.data);

//   const { notify } = useCustomSnackbar();
//   notify('helloworld');

//   return response;
// }, (error) => {
//   const env = process.env.REACT_APP_ENV;
//   if (isCancel(error) && env === 'MOCK') {
//     const response = getMockedResponse(error.config.url, error.config.method);

//     if (!response || Object.keys(response).length === 0) {
//       console.log('response do not exist in mock data');
//       return Promise.reject(error);
//     }
//     return response;
//   }

//   return Promise.reject(error);
// });

const instance = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
});

export function registerRequestInterceptor() {
  return instance.interceptors.request.use((config) => {
    const env = process.env.REACT_APP_ENV;
    if (env === 'MOCK') {
      devlog('MOCK ENV');
      const controller = new AbortController();
      controller.abort();
      return { ...config, signal: controller.signal };
    }

    const token = GetToken();

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });
}

export function registerResponseInterceptor(notifyError, navigate, auth, fullPath) {
  return instance.interceptors.response.use((response) => {
    const { data } = response;

    const err = data?.error;

    if (err) {
      const { modal, toast, message } = err;

      if (modal) {
        notifyError.notifyModal(message);
      } else if (toast) {
        notifyError.notifyToast(message);
      }
    }

    return response;
  }, (error) => {
    const env = process.env.REACT_APP_ENV;
    if (isCancel(error) && env === 'MOCK') {
      const response = getMockedResponse(error.config.url, error.config.method);

      if (!response || Object.keys(response).length === 0) {
        // console.log('response do not exist in mock data');
        return Promise.reject(error);
      }
      return response;
    }

    if (error.response.status === 401) {
      auth.logout();
      navigate(LoginPath, {
        state: { path: fullPath },
      });
      return {
        data: {},
        error: {
          toast: true,
          message: 'unauthorized',
        },
      };
    }

    return Promise.reject(error);
  });
}

export function ejectInterceptors(reqInterceptor, resInterceptor) {
  instance.interceptors.response.eject(resInterceptor);
  instance.interceptors.response.eject(reqInterceptor);
}

const gateway = instance;

export default gateway;
